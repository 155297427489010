import { api } from '../lib/axios'

export interface UpdateCategoryBody {
  category: {
    id: string
  }
  name: string
  sla: string
  team: {
    name: string
    id: string
  }
  status: {
    name: string
    id: string
  }
  description?: string | undefined
  fillLocation: boolean
}

export async function updateCategory({
  category,
  name,
  sla,
  team,
  status,
  description,
  fillLocation,
}: UpdateCategoryBody) {
  await api.patch('/categories', {
    category,
    name,
    sla,
    team,
    status,
    description,
    fillLocation,
  })
}
