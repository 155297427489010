import { api } from '../lib/axios'

interface UploadFileQuery {
  id: string
  model: 'tickets' | 'users'
  form: FormData
}

export async function uploadFile({ id, model, form }: UploadFileQuery) {
  await api.post(`/uploads/${model}`, form, {
    params: {
      id,
    },
  })
}
