import { api } from '../lib/axios'

export interface GetTicketStatusQuery {
  pageIndex?: number | null
  perPage?: number | null
  type?: string | null
}

export interface GetTicketStatusResponse {
  status: {
    id: string
    name: string
    type: 'wait' | 'analysis' | 'finished'
    createdById: string
    team: {
      id: string
      name: string
      color: 'yellow' | 'green' | 'purple' | 'blue' | 'red' | 'gray'
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getTicketStatus({
  pageIndex,
  perPage,
  type,
}: GetTicketStatusQuery) {
  const response = await api.get<GetTicketStatusResponse>('/tickets/status', {
    params: {
      pageIndex,
      perPage,
      type,
    },
  })

  return response.data
}
