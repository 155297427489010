export const colors = {
  'gray-25': '#F5F5F6',
  'gray-50': '#F9FAFB',
  'gray-60': '#F0F1F1',
  'gray-75': '#ECECED',
  'gray-80': '#F2F4F7',
  'gray-90': '#FCFCFD',
  'gray-100': '#EAECF0',
  'gray-125': '#CECFD2',
  'gray-150': '#94969C',
  'gray-200': '#D0D5DD',
  'gray-300': '#667085',
  'gray-325': '#61646C',
  'gray-350': '#85888E',
  'gray-375': '#94969C',
  'gray-400': '#98A2B3',
  'gray-600': '#475467',
  'gray-700': '#344054',
  'gray-725': '#333741',
  'gray-750': '#1F242F',
  'gray-775': '#202228',
  'gray-800': '#182230',
  'gray-850': '#161B26',
  'gray-900': '#101828',
  'gray-950': '#0C111D',
  'purple-50': '#F9F5FF',
  'purple-100': '#F4EBFF',
  'purple-200': '#E9D7FE',
  'purple-300': '#D6BBFB',
  'purple-400': '#B692F6',
  'purple-500': '#9E77ED',
  'purple-600': '#7F56D9',
  'purple-700': '#6941C6',
  'purple-800': '#53389E',
  'purple-900': '#2C1C5f',
  'green-50': '#ECFDF3',
  'green-200': '#ABEFC6',
  'green-300': '#75E0A7',
  'green-400': '#085D3A',
  'green-500': '#17B26A',
  'green-600': '#079455',
  'green-700': '#067647',
  'green-900': '#053321',
  'blue-50': '#EFF8FF',
  'blue-200': '#B2DDFF',
  'blue-300': '#84CAFF',
  'blue-500': '#1849A9',
  'blue-700': '#175CD3',
  'blue-900': '#102A56',
  'red-50': '#FEF3F2',
  'red-100': '#FEE4E2',
  'red-200': '#FECDCA',
  'red-300': '#FDA29B',
  'red-500': '#F04438',
  'red-600': '#D92D20',
  'red-800': '#B42318',
  'red-900': '#912018',
  'yellow-50': '#FFFAEB',
  'yellow-200': '#FEDF89',
  'yellow-300': '#FEC84B',
  'yellow-500': '#93370D',
  'yellow-700': '#B54708',
  'yellow-900': '#4E1D09',
}
