

import * as Select from '@radix-ui/react-select'
import { ComponentProps } from 'react'

export type ValueProps = ComponentProps<typeof Select.Value>

export function Value({ children, ...props }: ValueProps) {
  if (!children) {
    return <Select.Value {...props} />
  }

  return (
    <Select.Value {...props}>
      <span className="text-gray-900 dark:text-gray-25">{children}</span>
    </Select.Value>
  )
}
