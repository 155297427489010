import * as Dialog from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { Shield, X } from 'lucide-react'
import { useState } from 'react'
import { toast } from 'sonner'

import { transferResponsabilityTicket } from '../../../../api/transfer-responsability-ticket'
import { Button } from '../../../../components/Button'
import { Toast } from '../../../../components/Toast'
import { queryClient } from '../../../../lib/react-query'

interface BecomeResponsableModalProps {
  user: {
    id: string
    name: string
  }
  responsable: {
    id: string
    name: string
  }
  ticket: {
    id: string
    title: string
  }
}

export function BecomeResponsableModal({
  ticket,
  responsable,
  user,
}: BecomeResponsableModalProps) {
  const [open, setOpen] = useState<boolean>()

  const { mutateAsync: transferResponsabilityTicketFn } = useMutation({
    mutationKey: ['transfer-responsability-ticket'],
    mutationFn: transferResponsabilityTicket,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['ticket-participants'] })
      queryClient.refetchQueries({ queryKey: ['ticket', ticket.id] })
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Responsabilidade transferida com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar transferir a responsabilidade para este membro, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleBecomeResponsableOfTeam() {
    transferResponsabilityTicketFn({
      id: ticket.id,
      member: user,
      responsable,
    })
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg p-0 font-semibold text-purple-700 dark:text-gray-125"
        >
          Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-full bg-purple-100 p-2.5 dark:bg-purple-600">
              <Shield className="h-6 w-6 text-purple-600 dark:text-purple-200" />
            </div>
            <Dialog.Close className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Close>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Transferir responsibilidade do chamado {ticket.title}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Após a confirmação, a responsabilidade será transferida de{' '}
            <span className="font-medium text-purple-700 dark:font-normal dark:text-gray-25">
              {responsable.name}
            </span>{' '}
            para{' '}
            <span className="font-medium text-purple-700 dark:font-normal dark:text-gray-25">
              {user.name}
            </span>
            .
          </Dialog.Description>
          <div className="mt-8 flex flex-col-reverse gap-3 md:flex-row">
            <Dialog.Close asChild>
              <Button className="flex-1" variant="outline">
                Cancelar
              </Button>
            </Dialog.Close>
            <Button onClick={handleBecomeResponsableOfTeam} className="flex-1">
              Confirmar
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
