import { statusAPI } from '../lib/axios'

export interface GetServicesCountResponse {
  services: number
  alerts: number
  status: number
}

export async function getServicesCount() {
  const response =
    await statusAPI.get<GetServicesCountResponse>('/metrics/services')

  return response.data
}
