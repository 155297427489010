import { useEffect, useState } from 'react'

import { GetDevicesResponse } from '../../../api/get-devices'
import { Checkbox } from '../../../components/Form/Checkbox'
import { TableCell, TableRow } from '../../../components/Table'
import { Tag } from '../../../components/Tag'

type Device = GetDevicesResponse['devices'][number]

interface DeviceProps extends Device {
  status?: {
    name: string
    type: 'progress' | 'error' | 'success'
  }
}

interface SelectableDeviceRowProps {
  device: DeviceProps
  allChecked: boolean
  setDevices: React.Dispatch<React.SetStateAction<string[]>>
}

export function SelectableDeviceRow({
  device,
  allChecked,
  setDevices,
}: SelectableDeviceRowProps) {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(allChecked)
  }, [allChecked])

  return (
    <TableRow key={device.id}>
      <TableCell data-state={checked}>
        <div className="flex items-center gap-3">
          <Checkbox
            id={device.id}
            checked={checked}
            onCheckedChange={(checked: boolean) => {
              if (checked) {
                setDevices((state) => [...state, device.id])
              } else {
                setDevices((state) =>
                  state.filter((item) => item !== device.id),
                )
              }
              setChecked(checked)
            }}
          />
          <span>{device.id}</span>
        </div>
      </TableCell>
      <TableCell data-state={checked}>{device.transmissor}</TableCell>
      <TableCell data-state={checked}>{device.slot}</TableCell>
      <TableCell data-state={checked}>{device.pon}</TableCell>
      <TableCell data-state={checked}>{device.name}</TableCell>
      <TableCell data-state={checked}>{device.mac}</TableCell>
      <TableCell data-state={checked}>{device.createdBy.name}</TableCell>
      <TableCell data-state={checked}>
        {device.status && (
          <Tag
            className="flex items-center"
            variant={
              device.status.type === 'progress'
                ? 'yellow'
                : device.status.type === 'success'
                  ? 'green'
                  : device.status.type === 'error'
                    ? 'red'
                    : 'purple'
            }
          >
            {device.status.name}
          </Tag>
        )}
      </TableCell>
    </TableRow>
  )
}
