import { api } from '../lib/axios'

export interface SignInBody {
  email: string
  password: string
  keepLoggedIn: boolean
}

export async function signIn({ email, password, keepLoggedIn }: SignInBody) {
  await api.post('/sign-in', { email, password, keepLoggedIn })
}
