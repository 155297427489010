import { api } from '../lib/axios'

export interface updateIncidentBody {
  incident: {
    id: number
  }
  title: string
  city: string
  status: {
    id: string
  }
  category: {
    id: string
  }
  term?: string | null
  modelId?: string | null
  script: string
  level: string
}

export async function updateIncident({
  incident,
  title,
  city,
  category,
  status,
  term,
  modelId,
  script,
  level,
}: updateIncidentBody) {
  await api.patch('/incidents', {
    incident,
    title,
    city,
    category,
    status,
    term,
    modelId,
    script,
    level,
  })
}
