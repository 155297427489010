import { statusAPI } from '../lib/axios'

export interface RegisterAlertStatusBody {
  name: string
  type: 'pending' | 'finished'
}

export async function registerAlertStatus({
  name,
  type,
}: RegisterAlertStatusBody) {
  await statusAPI.post('/alerts/status', {
    name,
    type,
  })
}
