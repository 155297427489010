import { SVGProps } from 'react'

export function IXC(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={14}
      viewBox="0 0 26 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_21500_20771)" className="fill-current">
        <path d="M17.151 1.324c1.98-1.038 4.01-1.129 6.034-.32 1.707.681 2.517 2.087 2.727 3.87.024.204.032.41.044.615.001.021-.021.044-.045.089-.72 0-1.452.029-2.18-.01-.517-.028-.913-.326-1.2-.759-.74-1.121-1.509-1.416-2.815-1.084-.667.17-1.187.551-1.438 1.185-.57 1.438-.61 2.902.063 4.313.593 1.246 2.464 1.64 3.569.805.439-.332.625-.82.725-1.328.066-.332.213-.399.52-.392.914.019 1.83.006 2.821.006-.033.336-.045.652-.097.96-.417 2.438-2.004 3.93-4.464 4.207-.954.107-1.906.1-2.84-.144-1.863-.484-3.102-1.661-3.658-3.486a9.427 9.427 0 01.077-5.855c.382-1.14 1.101-2.029 2.157-2.672z" />
        <path d="M14.238 3.676c-.644.999-1.265 1.978-1.905 2.946-.15.227-.142.391 0 .62 1.157 1.861 2.3 3.731 3.445 5.599.073.118.14.24.234.403-.133.015-.227.034-.32.034-1.071.003-2.141-.006-3.211.008-.242.003-.368-.088-.485-.287-.552-.946-1.117-1.885-1.68-2.825-.063-.107-.133-.21-.23-.36-.49.832-1.025 1.587-1.396 2.414-.389.868-.942 1.17-1.874 1.07-.854-.089-1.724-.018-2.66-.018.087-.155.144-.263.207-.366 1.158-1.89 2.312-3.782 3.483-5.665.154-.247.142-.414-.013-.652-1.154-1.771-2.293-3.552-3.435-5.33-.08-.125-.154-.255-.27-.448h1.334c.76 0 1.522.008 2.282-.005.223-.004.348.07.458.263.549.963 1.112 1.918 1.671 2.875.063.107.132.21.225.359.5-.86.991-1.661 1.44-2.486.39-.714.97-1.024 1.769-1.01.887.016 1.776.004 2.764.004l-1.834 2.857zM.063 10.87V2.75C.063 1.491.738.82 2 .82h1.382v12.432H.062v-2.383z" />
      </g>
      <defs>
        <clipPath id="clip0_21500_20771">
          <path
            fill="#fff"
            transform="translate(0 .423)"
            d="M0 0H26V13.1548H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
