import { useQuery } from '@tanstack/react-query'
import { CheckCircle, SignalMedium, Unplug } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getAlertStatus } from '../../../api/get-alert-status'
import { getServiceStatus } from '../../../api/get-services-status'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { AlertStatusTableSkeleton } from './alert-status-table-skeleton'
import { CreateStatusModal } from './create-status-modal'
import { ServiceStatusSkeleton } from './service-status-skeleton'

export function Status() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '6')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: result, isLoading } = useQuery({
    queryKey: ['alert-status', pageIndex, perPage],
    queryFn: () =>
      getAlertStatus({
        pageIndex,
        perPage,
      }),
  })

  const { data: serviceStatusResult, isLoading: isLoadingServiceStatus } =
    useQuery({
      queryKey: ['services-status'],
      queryFn: getServiceStatus,
    })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <>
      <div className="mx-4 mb-6 mt-8 border-b border-gray-100 pb-5 dark:border-gray-750 md:mx-0 md:flex">
        <div>
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Status
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie os status utilizados na página de status.
          </p>
        </div>
        <div className="ml-auto mt-4 md:mt-0">
          <CreateStatusModal />
        </div>
      </div>
      <div className="mx-4 gap-8 md:mx-0 md:mb-6 md:grid md:grid-cols-2 md:border-b md:border-gray-100 md:pb-6 dark:md:border-gray-750">
        <div className="mt-3 md:mt-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Status de serviço
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Existem três status vinculados ao serviço:
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-3 md:mt-0">
          {serviceStatusResult &&
            serviceStatusResult.status.map((status) => (
              <div
                key={status.id}
                className="flex flex-1 items-center gap-3 rounded-lg border border-gray-100 bg-gray-50 p-4 data-[state=checked]:border-green-600 data-[state=checked]:bg-green-50 focus:shadow-none dark:border-gray-750 dark:bg-gray-850 dark:data-[state=checked]:border-green-300 dark:data-[state=checked]:bg-green-900"
              >
                {status.name === 'Operacional' ? (
                  <div className="flex items-center justify-center rounded-full bg-green-100 p-2 dark:bg-green-900">
                    <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-300" />
                  </div>
                ) : status.name === 'Indisponibilidade Parcial' ? (
                  <div className="flex items-center justify-center rounded-full bg-yellow-100 p-2 dark:bg-yellow-900">
                    <SignalMedium className="h-4 w-4 text-yellow-600 dark:text-yellow-300" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center rounded-full bg-red-100 p-2 dark:bg-red-900">
                    <Unplug className="h-4 w-4 text-red-600 dark:text-red-300" />
                  </div>
                )}
                <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  {status.name}
                </p>
              </div>
            ))}
          {isLoadingServiceStatus && <ServiceStatusSkeleton />}
        </div>
      </div>
      <div className="mb-6 gap-16 pt-8 md:mx-0 md:grid md:grid-cols-status md:pt-0">
        <div className="px-4 md:px-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Status de alerta
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie os status utilizados para indicar a situação atual do
            alerta.
          </p>
        </div>
        <div>
          <Table className="mt-4 table-fixed md:mt-0">
            <TableHeader>
              <TableHead>Nome</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead />
            </TableHeader>
            <TableBody>
              {result?.status.map((status) => {
                return (
                  <TableRow key={status.id}>
                    <TableCell>{status.name}</TableCell>
                    <TableCell>
                      <Tag
                        variant={
                          status.type === 'pending'
                            ? 'yellow'
                            : status.type === 'finished'
                              ? 'green'
                              : 'purple'
                        }
                      >
                        {status.type === 'pending'
                          ? 'em andamento'
                          : 'finalizado'}
                      </Tag>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
              })}
              {isLoading && <AlertStatusTableSkeleton perPage={perPage} />}
            </TableBody>
          </Table>
          {result && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={result.meta.pageIndex}
              perPage={result.meta.perPage}
              totalCount={result.meta.totalCount}
              totalInPage={result.status.length}
            />
          )}
        </div>
      </div>
    </>
  )
}
