

import {
  createContext,
  Dispatch,
  HTMLAttributes,
  RefObject,
  SetStateAction,
  useContext,
  useId,
  useRef,
} from 'react'
import { twMerge } from 'tailwind-merge'

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
  multiple?: boolean
  files: File[]
  setFiles: Dispatch<SetStateAction<File[]>>
}

interface FileInputContextType {
  id: string
  files: File[]
  multiple: boolean
  setFiles: Dispatch<SetStateAction<File[]>>
  fileInputRef: RefObject<HTMLInputElement>
}

const FileInputContext = createContext({} as FileInputContextType)

export function Root({
  multiple = false,
  id,
  files,
  setFiles,
  ...props
}: RootProps) {
  const customId = useId()
  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <FileInputContext.Provider
      value={{ id: id ?? customId, files, multiple, setFiles, fileInputRef }}
    >
      <div {...props} className={twMerge('group w-full', props.className)} />
    </FileInputContext.Provider>
  )
}

export const useFileInput = () => useContext(FileInputContext)
