import { Combobox } from '@headlessui/react'
import {
  ComponentProps,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

interface ComboboxProps extends ComponentProps<typeof Combobox> {}

interface ComboboxContextType {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  multiple?: boolean
}

const ComboboxContext = createContext({} as ComboboxContextType)

export function Root({ ...props }: ComboboxProps) {
  const [query, setQuery] = useState('')

  return (
    <ComboboxContext.Provider
      value={{ query, setQuery, multiple: props.multiple }}
    >
      <Combobox immediate {...props} as="div" />
    </ComboboxContext.Provider>
  )
}

export const useCombobox = () => useContext(ComboboxContext)
