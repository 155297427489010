import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AlertCircle, CheckCircle, Clock, Search, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getTeams } from '../../../api/get-teams'
import { updateStatus } from '../../../api/update-status'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

interface EditStatusModalProps {
  status: {
    id: string
    name: string
    type: 'wait' | 'analysis' | 'finished'
    createdById: string
    team: {
      id: string
      name: string
      color: 'yellow' | 'green' | 'purple' | 'blue' | 'red' | 'gray'
    }
  }
}

export const editStatusSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  type: z.enum(['wait', 'analysis', 'finished'], {
    required_error: 'Tipo é obrigatório',
  }),
  team: z
    .object({
      id: z.string().uuid(),
      name: z.string(),
    })
    .optional(),
})

export type EditStatusProps = z.infer<typeof editStatusSchema>

export function EditStatusModal({ status }: EditStatusModalProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<EditStatusProps>({
    resolver: zodResolver(editStatusSchema),
    defaultValues: {
      name: status.name,
      type: status.type,
      ...(status.team && { team: status.team }),
    },
  })

  const { data: result } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
  })

  const { mutateAsync: updateStatusFn } = useMutation({
    mutationKey: ['update-status'],
    mutationFn: updateStatus,
    onSuccess: (_, variables) => {
      queryClient.refetchQueries({ queryKey: ['ticket-status'] })

      setOpen(false)

      reset({
        name: variables.name,
        type: variables.type,
        ...(variables.team && { team: variables.team }),
      })

      toast.custom((t) => (
        <Toast t={t} title="Status atualizado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar o status, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  const [open, setOpen] = useState<boolean>()

  async function handleEditStatus(data: EditStatusProps) {
    const { name, type, team } = data

    updateStatusFn({
      status: {
        id: status.id,
      },
      name,
      type,
      team,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        reset({
          name: status.name,
          type: status.type,
          ...(status.team && { team: status.team }),
        })
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg p-0 font-semibold text-purple-700 dark:text-gray-125"
        >
          Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <AlertCircle className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Editar {status.name}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Atualize as informações do status
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleEditStatus)}>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Nome
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Tipo*
              </label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="Selecione o tipo" />
                    </Select.Trigger>

                    <Select.Content>
                      <Select.Item value="wait">
                        <Select.ItemText>
                          <Clock className="ml-auto h-4 w-4" />
                          Aguardando
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="analysis">
                        <Select.ItemText>
                          <Search className="ml-auto h-4 w-4" />
                          Em análise
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="finished">
                        <Select.ItemText>
                          <CheckCircle className="ml-auto h-4 w-4" />
                          Finalizado
                        </Select.ItemText>
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Equipe
              </label>
              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Combobox.Root {...field}>
                    <Combobox.Trigger placeholder="Selecione a equipe" />

                    <Combobox.Content>
                      {result &&
                        result.teams.map((team) => (
                          <Combobox.Option key={team.id} value={team}>
                            {team.name}
                          </Combobox.Option>
                        ))}
                    </Combobox.Content>
                  </Combobox.Root>
                )}
              />
            </div>

            <div className="mt-8 flex gap-3">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isDirty}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
