import { Skeleton } from '../../../components/Skeleton'
import { TableCell, TableRow } from '../../../components/Table'

interface TicketsTableSkeletonProps {
  perPage: number
}

export function TicketsTableSkeleton({ perPage }: TicketsTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[52px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[164px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[70px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[120px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[64px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[46px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
