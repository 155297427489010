export function PDF() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M7.75 4A3.25 3.25 0 0 1 11 .75h16c.121 0 .238.048.323.134l10.793 10.793a.457.457 0 0 1 .134.323v24A3.25 3.25 0 0 1 35 39.25H11A3.25 3.25 0 0 1 7.75 36V4Z"
        className="dark:stroke-gray-725"
      />
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M27 .5V8a4 4 0 0 0 4 4h7.5"
        className="dark:stroke-gray-725"
      />
      <rect width="26" height="16" x="1" y="18" fill="#D92D20" rx="2" />
      <path
        fill="#fff"
        d="M4.832 30v-7.273h2.87c.551 0 1.021.106 1.41.316.388.209.684.499.887.87.206.37.31.796.31 1.279 0 .483-.105.909-.313 1.278-.209.37-.51.657-.906.863-.393.206-.869.309-1.427.309h-1.83V26.41h1.581c.296 0 .54-.051.732-.153.194-.104.338-.247.433-.43.097-.184.145-.396.145-.635 0-.242-.048-.452-.145-.632a.972.972 0 0 0-.433-.423c-.195-.102-.44-.153-.74-.153H6.37V30H4.832Zm9.053 0h-2.578v-7.273h2.6c.73 0 1.36.146 1.889.437.527.289.934.704 1.218 1.246.286.543.43 1.191.43 1.947 0 .757-.144 1.408-.43 1.953a2.953 2.953 0 0 1-1.226 1.253c-.53.291-1.164.437-1.903.437Zm-1.04-1.317h.976c.455 0 .837-.081 1.147-.242.313-.163.547-.415.703-.756.159-.344.238-.786.238-1.328 0-.538-.08-.977-.238-1.318a1.541 1.541 0 0 0-.7-.753c-.31-.16-.692-.241-1.146-.241h-.98v4.637ZM18.581 30v-7.273h4.816v1.268H20.12v1.733h2.958v1.268H20.12V30h-1.538Z"
      />
    </svg>
  )
}
