import { Skeleton } from '../../../../components/Skeleton'
import { TableCell, TableRow } from '../../../../components/Table'

interface ParticipantsTableSkeletonProps {
  perPage: number
}

export function ParticipantsTableSkeleton({
  perPage,
}: ParticipantsTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[154px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[82px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[92px] rounded-md" />
          </TableCell>
          <TableCell className="flex justify-end gap-3 group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[50px] rounded-md" />
            <Skeleton className="my-1 h-4 w-[50px] rounded-md" />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
