import { api } from '../lib/axios'

export interface RegisterErrorBody {
  id: string
  message: string
}

export async function registerError({ id, message }: RegisterErrorBody) {
  await api.post('/errors', {
    id,
    message,
  })
}
