import * as Dialog from '@radix-ui/react-dialog'
import * as Popover from '@radix-ui/react-popover'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useMediaQuery } from 'react-responsive'

interface RootProps {
  multiple?: boolean
  children: ReactNode
}

interface DatePickerContextProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  multiple: boolean
}

const DatePickerContext = createContext({} as DatePickerContextProps)

export function Root({ multiple = false, children }: RootProps) {
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <DatePickerContext.Provider value={{ open, setOpen, multiple }}>
      {isMobile ? (
        <Dialog.Root
          open={open}
          onOpenChange={(state) => {
            setOpen(state)
          }}
        >
          {children}
        </Dialog.Root>
      ) : (
        <Popover.Root
          open={open}
          onOpenChange={(state) => {
            setOpen(state)
          }}
        >
          {children}
        </Popover.Root>
      )}
    </DatePickerContext.Provider>
  )
}

export const useDatePicker = () => useContext(DatePickerContext)
