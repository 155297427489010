import { api } from '../lib/axios'

export interface SynchronizeDeviceBody {
  deviceId: string
}

export async function synchronizeDevice({ deviceId }: SynchronizeDeviceBody) {
  const response = await api.post('/devices/synchronize', {
    deviceId,
  })

  return response.data
}
