export const vlans = [
  {
    transmissorId: '2',
    vlan: '1006',
  },
  {
    transmissorId: '1',
    vlan: '1008',
  },
  {
    transmissorId: '214',
    vlan: '1008',
  },
  {
    transmissorId: '9',
    vlan: '1029',
  },
  {
    transmissorId: '5',
    vlan: '1051',
  },
  {
    transmissorId: '7',
    vlan: '1007',
  },
  {
    transmissorId: '245',
    vlan: '',
  },
  {
    transmissorId: '244',
    vlan: '1015',
  },
  {
    transmissorId: '8',
    vlan: '101',
  },
  {
    transmissorId: '6',
    vlan: '1028',
  },
  {
    transmissorId: '14',
    vlan: '1011',
  },
  {
    transmissorId: '12',
    vlan: '1012',
  },
  {
    transmissorId: '13',
    vlan: '1009',
  },
  {
    transmissorId: '10',
    vlan: '1003',
  },
  {
    transmissorId: '4',
    vlan: '1016',
  },
  {
    transmissorId: '11',
    vlan: '1031',
  },
]
