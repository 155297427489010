import { Skeleton } from '../../../../components/Skeleton'
import { TableCell, TableRow } from '../../../../components/Table'

interface FilesTableSkeletonProps {
  perPage: number
}

export function FilesTableSkeleton({ perPage }: FilesTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <div className="flex gap-3">
              <Skeleton className="my-1 h-10 w-10 rounded-lg" />
              <div>
                <Skeleton className="my-1 h-4 w-64 rounded-lg" />
                <Skeleton className="my-1 h-4 w-14 rounded-lg" />
              </div>
            </div>
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[64px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[92px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <div className="flex gap-3">
              <Skeleton className="my-1 h-10 w-10 rounded-full" />
              <div>
                <Skeleton className="my-1 h-4 w-[72px] rounded-lg" />
                <Skeleton className="my-1 h-4 w-[148px] rounded-lg" />
              </div>
            </div>
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent" />
        </TableRow>
      ))}
    </>
  )
}
