

import * as Select from '@radix-ui/react-select'
import { ComponentProps } from 'react'

export type ItemProps = ComponentProps<typeof Select.Item>

export function Item({ children, ...props }: ItemProps) {
  return (
    <Select.Item
      className="flex items-center gap-2 rounded-lg px-3 py-2.5 data-[highlighted]:bg-gray-50 focus:shadow-none dark:data-[highlighted]:bg-gray-750"
      {...props}
    >
      {children}
    </Select.Item>
  )
}
