import { api } from '../lib/axios'

export interface UpdateTeamBody {
  team: {
    id: string
  }
  name: string
  active: boolean
  connect: string[]
  disconnect: string[]
}

export async function updateTeam({
  team,
  name,
  active,
  connect,
  disconnect,
}: UpdateTeamBody) {
  await api.patch('/teams', {
    team,
    name,
    active,
    connect,
    disconnect,
  })
}
