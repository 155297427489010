export function JPG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M7.75 4A3.25 3.25 0 0 1 11 .75h16c.121 0 .238.048.323.134l10.793 10.793a.457.457 0 0 1 .134.323v24A3.25 3.25 0 0 1 35 39.25H11A3.25 3.25 0 0 1 7.75 36V4Z"
        className="dark:stroke-gray-725"
      />
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M27 .5V8a4 4 0 0 0 4 4h7.5"
        className="dark:stroke-gray-725"
      />
      <rect width="26" height="16" x="1" y="18" fill="#7F56D9" rx="2" />
      <path
        fill="#fff"
        d="M7.651 22.727h1.52v5.071c0 .469-.105.876-.316 1.222a2.089 2.089 0 0 1-.87.799c-.371.187-.803.28-1.296.28-.438 0-.836-.076-1.193-.23a1.907 1.907 0 0 1-.845-.71c-.209-.32-.312-.721-.31-1.204h1.531c.005.191.044.356.118.493a.8.8 0 0 0 .308.313.979.979 0 0 0 .47.106c.189 0 .348-.04.479-.12a.776.776 0 0 0 .301-.363c.07-.158.103-.354.103-.586v-5.07ZM10.443 30v-7.273h2.869c.552 0 1.022.106 1.41.316.388.209.684.499.888.87.206.37.309.796.309 1.279 0 .483-.105.909-.313 1.278-.208.37-.51.657-.905.863-.394.206-.87.309-1.428.309h-1.829V26.41h1.58c.296 0 .54-.051.732-.153.194-.104.338-.247.433-.43.097-.184.146-.396.146-.635 0-.242-.049-.452-.146-.632a.972.972 0 0 0-.433-.423c-.194-.102-.44-.153-.739-.153H11.98V30h-1.537Zm11.297-4.922a1.596 1.596 0 0 0-.21-.458 1.415 1.415 0 0 0-.77-.558 1.862 1.862 0 0 0-.544-.074c-.371 0-.698.092-.98.277-.28.185-.497.453-.653.806-.156.35-.235.779-.235 1.285 0 .507.077.938.231 1.293.154.355.372.626.654.813.281.185.614.277.997.277.348 0 .646-.061.892-.184.248-.126.438-.302.568-.53.133-.227.199-.495.199-.805l.312.046h-1.875v-1.158h3.044v.916c0 .64-.135 1.189-.405 1.648-.27.457-.642.81-1.115 1.058-.474.246-1.016.37-1.627.37-.681 0-1.28-.15-1.796-.452a3.13 3.13 0 0 1-1.208-1.289c-.286-.558-.43-1.221-.43-1.988 0-.59.086-1.115.256-1.577.173-.464.414-.857.724-1.179.31-.322.672-.567 1.084-.735a3.51 3.51 0 0 1 1.338-.252c.412 0 .796.06 1.151.18s.67.287.945.505c.277.218.503.477.678.778.175.298.288.627.337.987H21.74Z"
      />
    </svg>
  )
}
