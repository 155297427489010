import { api } from '../lib/axios'

export interface GetTeamCategoriesResponse {
  categories: {
    id: string
    name: string
    permissions: {
      name: string
    }[]
  }[]
}

interface GetTeamCategoriesQuery {
  teamId: string
}

export async function getTeamCategories({ teamId }: GetTeamCategoriesQuery) {
  const response = await api.get<GetTeamCategoriesResponse>(
    `/teams/categories/${teamId}`,
  )

  return response.data
}
