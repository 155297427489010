import { api } from '../lib/axios'

export interface GetTeamsResponse {
  teams: {
    id: string
    name: string
    active: boolean
  }[]
}

export async function getTeams() {
  const response = await api.get<GetTeamsResponse>('/teams')

  return response.data
}
