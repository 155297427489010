import { statusAPI } from '../lib/axios'

export interface RegisterGroupBody {
  name: string
}

export async function registerGroup({ name }: RegisterGroupBody) {
  await statusAPI.post('/groups', {
    name,
  })
}
