import { api } from '../lib/axios'

interface DownloadFileQuery {
  file: {
    id: string
    filename: string
  }
}

export async function downloadFile({ file }: DownloadFileQuery) {
  const response = await api.get('/uploads', {
    params: {
      id: file.id,
    },
    responseType: 'arraybuffer',
  })

  return response.data
}
