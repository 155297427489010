import { Skeleton } from '../../../components/Skeleton'

export function TopThreeTeamsSkeleton() {
  return (
    <>
      <Skeleton className="h-[66px] rounded-lg" />
      <Skeleton className="h-[66px] rounded-lg" />
      <Skeleton className="h-[66px] rounded-lg" />
    </>
  )
}
