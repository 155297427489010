import { api } from '../lib/axios'

export interface GetTeamsMostCategoriesResponse {
  teams: {
    id: string
    name: string
  }[]
}

export async function getTeamsMostCategories() {
  const response =
    await api.get<GetTeamsMostCategoriesResponse>('/categories/teams')

  return response.data
}
