import { api } from '../lib/axios'

export interface GetUsersTicketFormResponse {
  users: {
    id: string
    name: string
  }[]
}

export async function getUsersTicketForm() {
  const response = await api.get<GetUsersTicketFormResponse>(
    '/users/enabled-ticket-form',
  )

  return response.data
}
