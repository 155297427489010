import { api } from '../lib/axios'

interface DeleteFileQuery {
  id: string
}

export async function deleteFile({ id }: DeleteFileQuery) {
  await api.delete('/uploads', {
    params: {
      id,
    },
  })
}
