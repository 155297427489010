import { zodResolver } from '@hookform/resolvers/zod'
import * as Tabs from '@radix-ui/react-tabs'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { getSettingsLayout } from '../../api/get-settings-layout-count'
import * as Select from '../../components/Form/Select/index'
import { TabItem } from '../../components/TabItem'
import { ThemeToggle } from '../../components/ThemeToggle'

const settingsTabSchema = z.object({
  tab: z.enum(['teams', 'users']),
})

type SettingsTabSchema = z.infer<typeof settingsTabSchema>

export default function SettingsLayout() {
  const location = useLocation()
  const navigate = useNavigate()

  const page = settingsTabSchema.parse({
    tab: location.pathname.split('/settings/')[1],
  }).tab

  const { control, watch } = useForm<SettingsTabSchema>({
    defaultValues: {
      tab: page,
    },
    resolver: zodResolver(settingsTabSchema),
  })

  const { data: result } = useQuery({
    queryKey: ['layout-settings-count'],
    queryFn: getSettingsLayout,
  })

  const tab = watch('tab')

  const tabDisplay = [
    {
      value: 'teams',
      title: 'Equipes',
      isSelected: tab === 'teams',
      layoutId: 'settings',
      count: result?.teams,
    },
    {
      value: 'users',
      title: 'Usuários',
      isSelected: tab === 'users',
      layoutId: 'settings',
      count: result?.users,
    },
  ]

  const isMobile = useMediaQuery({ maxWidth: 768 })

  useEffect(() => {
    if (tab === page) return

    navigate(`/settings/${tab}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <>
      <Helmet>
        <title>HUB | Configurações</title>
      </Helmet>
      <div className="flex w-full flex-col justify-between px-4 md:flex-row md:px-0">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
          Configurações
        </h1>
        <div className="ml-auto">
          <ThemeToggle />
        </div>
      </div>
      <div className="px-4 md:px-0">
        {isMobile ? (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Select.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
              >
                <Select.Trigger className="mb-8 mt-6">
                  <Select.Value />
                </Select.Trigger>

                <Select.Content>
                  {tabDisplay.map((item) => (
                    <Select.Item key={item.value} value={item.value}>
                      <Select.ItemText>{item.title}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            )}
          />
        ) : (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Tabs.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
                className="mb-8 hidden border-b border-gray-100 dark:border-gray-750 md:block"
              >
                <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
                  {tabDisplay.map((item) => (
                    <TabItem
                      key={item.value}
                      value={item.value}
                      title={item.title}
                      isSelected={item.isSelected}
                      layoutId={item.layoutId}
                      count={item.count}
                    />
                  ))}
                </Tabs.List>
              </Tabs.Root>
            )}
          />
        )}
      </div>
      <Outlet />
    </>
  )
}
