import * as Dialog from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { Trash2, X } from 'lucide-react'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'

import { removeMemberOfTeam } from '../../../api/remove-member-of-team'
import { Button } from '../../../components/Button'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

interface RemoveMemberModalProps {
  user: {
    id: string
    name: string
  }
}

export function RemoveMemberModal({ user }: RemoveMemberModalProps) {
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState<boolean>()

  const id = searchParams.get('id')

  const { mutateAsync: removeMemberOfTeamFn } = useMutation({
    mutationKey: ['remove-member-of-team'],
    mutationFn: removeMemberOfTeam,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['users-on-team', id] })
      setOpen(false)
      toast.custom((t) => (
        <Toast t={t} title="Membro removido com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar remover o membro, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleRemoveMemberOfTeam() {
    removeMemberOfTeamFn({
      id,
      member: {
        id: user.id,
      },
    })
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg p-0 text-sm font-semibold text-gray-600 dark:text-gray-150"
        >
          Remover
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-full bg-red-100 p-2.5 dark:bg-red-600">
              <Trash2 className="h-6 w-6 text-red-600 dark:text-red-200" />
            </div>
            <Dialog.Close className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Close>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Remover {user.name}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Tem certeza de que deseja remover este usuário? Essa ação não pode
            ser desfeita.
          </Dialog.Description>
          <div className="mt-8 flex flex-col-reverse gap-3 md:flex-row">
            <Dialog.Close asChild>
              <Button className="flex-1" variant="outline">
                Cancelar
              </Button>
            </Dialog.Close>
            <Button
              onClick={handleRemoveMemberOfTeam}
              className="flex-1"
              variant="danger"
            >
              Remover
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
