import { DOCX } from './Icons/DOCX'
import { File } from './Icons/File'
import { JPEG } from './Icons/JPEG'
import { JPG } from './Icons/JPG'
import { MP3 } from './Icons/MP3'
import { MP4 } from './Icons/MP4'
import { PDF } from './Icons/PDF'
import { PNG } from './Icons/PNG'
import { PPTX } from './Icons/PPTX'
import { RAR } from './Icons/RAR'
import { TXT } from './Icons/TXT'
import { XLSX } from './Icons/XLSX'
import { ZIP } from './Icons/ZIP'

interface IconFileProps {
  filename: string
}

export function IconFile({ filename }: IconFileProps) {
  const filenameSplit = filename.split('.')
  const extension = filenameSplit[filenameSplit.length - 1]

  const files = [
    { extension: 'pdf', icon: <PDF /> },
    { extension: 'docx', icon: <DOCX /> },
    { extension: 'jpeg', icon: <JPEG /> },
    { extension: 'jpg', icon: <JPG /> },
    { extension: 'mp3', icon: <MP3 /> },
    { extension: 'png', icon: <PNG /> },
    { extension: 'mp4', icon: <MP4 /> },
    { extension: 'pptx', icon: <PPTX /> },
    { extension: 'txt', icon: <TXT /> },
    { extension: 'xlsx', icon: <XLSX /> },
    { extension: 'zip', icon: <ZIP /> },
    { extension: 'rar', icon: <RAR /> },
  ]

  return files.find((file) => file.extension === extension)?.icon || <File />
}
