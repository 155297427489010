import { api } from '../lib/axios'

export interface RemoveMemberOfTeamQuery {
  id: string | null
  member: {
    id: string
  }
}

export async function removeMemberOfTeam({
  id,
  member,
}: RemoveMemberOfTeamQuery) {
  const response = await api.delete(`/teams/${id}/remove`, {
    data: {
      member,
    },
  })

  return response.data
}
