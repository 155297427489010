import { api } from '../lib/axios'

export interface RegisterDevicesBody {
  devices: string[]
}

export interface RegisterDevicesResponse {
  success: {
    mac: string
  }[]
  errors: {
    mac: string
    message: string
  }[]
}

export async function registerDevices({ devices }: RegisterDevicesBody) {
  const response = await api.post<RegisterDevicesResponse>('/devices', {
    devices,
  })

  return response.data
}
