import * as Dialog from '@radix-ui/react-dialog'
import * as Popover from '@radix-ui/react-popover'
import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

interface PortalProps {
  children: ReactNode
}

export function Portal({ children }: PortalProps) {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  if (isMobile) {
    return (
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        {children}
      </Dialog.Portal>
    )
  }

  return (
    <>
      <Popover.Anchor />
      <Popover.Portal>{children}</Popover.Portal>
    </>
  )
}
