import * as Tabs from '@radix-ui/react-tabs'
import { useMutation, useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { downloadFile } from '../../../../api/download-file'
import { getAuthenticatedUser } from '../../../../api/get-authenticated-user'
import {
  getTicketFiles,
  GetTicketFilesResponse,
} from '../../../../api/get-ticket-files'
import { uploadFile } from '../../../../api/upload-file'
import { Avatar } from '../../../../components/Avatar'
import { Button } from '../../../../components/Button'
import * as FileInput from '../../../../components/Form/FileInput'
import * as Input from '../../../../components/Form/Input'
import { IconFile } from '../../../../components/IconFile'
import { Pagination } from '../../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../components/Table'
import { Toast } from '../../../../components/Toast'
import { convertBytes } from '../../../../lib/convertBytes'
import { queryClient } from '../../../../lib/react-query'
import { DeleteFileModal } from './delete-file-modal'
import { FilesTableSkeleton } from './files-table-skeleton'
import { ViewFileModal } from './view-file-modal'

export function Assets() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { ticketId } = useParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '6')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const type = searchParams.get('type') ?? 'all'

  const filename = searchParams.get('filename')

  const [toUploadFiles, setToUploadFiles] = useState<File[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  const { mutateAsync: uploadFileFn } = useMutation({
    mutationKey: ['upload-file'],
    mutationFn: uploadFile,
    onSuccess: (_, variables) => {
      const file = variables.form.get('file') as File

      queryClient.refetchQueries({ queryKey: ['ticket-files'] })
      setUploadedFiles((state) => [...state, file])
      toast.custom((t) => (
        <Toast
          t={t}
          title={`Upload do arquivo: ${file.name}, realizado com sucesso!`}
          variant="success"
        />
      ))
    },
    onError: (_, variables) => {
      const file = variables.form.get('file') as File

      toast.custom((t) => (
        <Toast
          t={t}
          title={`Ocorreu um erro ao tentar realizar o upload do arquivo: ${file.name}, tente novamente mais tarde.`}
          variant="error"
        />
      ))
    },
  })

  const { mutateAsync: downloadFileFn } = useMutation({
    mutationKey: ['download-file'],
    mutationFn: downloadFile,
    onSuccess: (data, variables) => {
      const fileStream = data

      const blob = new Blob([fileStream], { type: 'application/octet-stream' })

      const dataUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = dataUrl
      link.download = variables.file.filename

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(dataUrl)
    },
  })

  const { data: result, isLoading } = useQuery({
    queryKey: ['ticket-files', pageIndex, perPage, type, filename, ticketId],
    queryFn: () =>
      getTicketFiles({
        id: ticketId ?? '',
        pageIndex,
        perPage,
        type,
        filename,
      }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  function handleFilter(uploadedBy: string) {
    setSearchParams((state) => {
      state.set('type', uploadedBy)

      return state
    })
  }

  function handleQuery(query: string) {
    setSearchParams((state) => {
      if (query) {
        state.set('filename', query)
      } else {
        state.delete('filename')
      }

      return state
    })
  }

  function handleDownloadFile(file: GetTicketFilesResponse['files'][number]) {
    downloadFileFn({
      file,
    })
  }

  useEffect(() => {
    for (const file of toUploadFiles) {
      if (uploadedFiles.some((uploadedFile) => uploadedFile === file)) continue

      const form = new FormData()

      form.append('file', file)

      uploadFileFn({
        id: ticketId ?? '',
        model: 'tickets',
        form,
      })
    }
  }, [toUploadFiles])

  return (
    <div>
      <div className="px-4 md:px-0">
        <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
          Arquivos
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-150">
          Gerencie os arquivos e documentos referentes a esse chamado.
        </p>
      </div>
      <div className="mt-8 px-4 md:px-0">
        <FileInput.Root
          id="files"
          files={toUploadFiles}
          setFiles={setToUploadFiles}
          multiple
        >
          <FileInput.Trigger />
          <FileInput.Control />
        </FileInput.Root>
        <div className="mt-4 flex max-h-[260px] flex-col gap-3 overflow-y-auto">
          {toUploadFiles.map((file) => (
            <div
              key={file.name}
              className="flex cursor-pointer gap-3 rounded-lg border border-gray-100 bg-white p-4 transition-colors hover:bg-gray-50 dark:border-gray-750 dark:bg-gray-950 dark:hover:bg-gray-750"
              onClick={() => {
                setToUploadFiles((state) =>
                  state.filter((item) => item.name !== file.name),
                )
              }}
            >
              <IconFile filename={file.name} />
              <div>
                <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  {file.name}
                </p>
                <span className="text-sm text-gray-600 dark:text-gray-150">
                  {convertBytes(file.size)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 dark:border-gray-750 md:rounded-t-lg md:border md:border-b-0 md:border-gray-100">
        <div className="px-4 md:px-6 md:pt-5">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
            Arquivos anexados
          </h1>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-150">
            Arquivos que foram anexados a este chamado.
          </p>
        </div>
        <div className="mt-6 flex flex-col-reverse gap-3 px-4 py-3 dark:border-gray-750 md:flex-row md:border-t md:border-gray-100 md:px-6">
          <Tabs.Root value={type} onValueChange={handleFilter}>
            <Tabs.List className="flex max-w-xs self-start md:max-w-none">
              <Tabs.Trigger
                value="all"
                className="cursor-pointer truncate rounded-l-lg border border-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 transition-colors data-[state=active]:bg-gray-50 focus:shadow-none dark:border-gray-750 dark:text-gray-125 dark:data-[state=active]:bg-gray-750"
              >
                Ver todos
              </Tabs.Trigger>
              <Tabs.Trigger
                value="my"
                className="cursor-pointer truncate border border-l-0 border-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 transition-colors data-[state=active]:bg-gray-50 focus:shadow-none dark:border-gray-750 dark:text-gray-125 dark:data-[state=active]:bg-gray-750"
              >
                Seus arquivos
              </Tabs.Trigger>
              <Tabs.Trigger
                value="shared"
                className="cursor-pointer truncate rounded-r-lg border border-l-0 border-gray-200 px-4 py-2 text-sm font-semibold text-gray-700 transition-colors data-[state=active]:bg-gray-50 focus:shadow-none dark:border-gray-750 dark:text-gray-125 dark:data-[state=active]:bg-gray-750"
              >
                Arquivos compartilhados
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
          <div className="flex gap-3 md:ml-auto">
            <Input.Root className="w-full">
              <Input.Prefix>
                <Search className="h-5 w-5 text-gray-300 dark:text-gray-375" />
              </Input.Prefix>
              <Input.Control
                placeholder="Buscar"
                onBlur={(e) => handleQuery(e.currentTarget.value)}
              />
            </Input.Root>
          </div>
        </div>
      </div>
      <Table className="mt-6 md:mt-0">
        <TableHeader>
          <TableHead className="md:first:!rounded-tl-none">Nome</TableHead>
          <TableHead>Tamanho</TableHead>
          <TableHead>Data de envio</TableHead>
          <TableHead>Enviado por</TableHead>
          <TableHead className="md:last:!rounded-tr-none" />
        </TableHeader>
        <TableBody>
          {result &&
            result.files.map((file) => {
              const canDelete = file.createdBy.id === authenticatedUser?.user.id

              const isImage = ['jpg', 'png', 'jpeg'].includes(
                file.filename.split('.')[1],
              )

              return (
                <TableRow key={file.id}>
                  <TableCell>
                    {isImage ? (
                      <ViewFileModal file={file}>
                        <div className="flex cursor-pointer items-center gap-3">
                          <IconFile filename={file.filename} />
                          <div>
                            <p className="text-sm font-medium text-gray-700 dark:text-gray-50">
                              {file.filename}
                            </p>
                            <span className="text-sm text-gray-600 dark:text-gray-150">
                              {convertBytes(file.size)}
                            </span>
                          </div>
                        </div>
                      </ViewFileModal>
                    ) : (
                      <div
                        onClick={() => handleDownloadFile(file)}
                        className="flex cursor-pointer items-center gap-3"
                      >
                        <IconFile filename={file.filename} />
                        <div>
                          <p className="text-sm font-medium text-gray-700 dark:text-gray-50">
                            {file.filename}
                          </p>
                          <span className="text-sm text-gray-600 dark:text-gray-150">
                            {convertBytes(file.size)}
                          </span>
                        </div>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{convertBytes(file.size)}</TableCell>
                  <TableCell>
                    {format(new Date(file.created_at), "MMM d',' u", {
                      locale: ptBR,
                    })}
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-3">
                      <Avatar
                        name={file.createdBy.name}
                        avatarUrl={file.createdBy.avatarUrl}
                      />
                      <div className="flex flex-col">
                        <h1 className="text-gray-900 dark:text-gray-25">
                          {file.createdBy.name}
                        </h1>
                        <p className="w-48 truncate text-gray-600 dark:text-gray-350">
                          {file.createdBy.email}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-end gap-3">
                      {canDelete && <DeleteFileModal file={file} />}
                      <Button
                        variant="ghost"
                        onClick={() => handleDownloadFile(file)}
                        className="rounded-lg p-0 text-sm font-semibold text-purple-700 dark:text-gray-125"
                      >
                        Download
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          {isLoading && <FilesTableSkeleton perPage={perPage} />}
        </TableBody>
      </Table>
      {!result?.files.length && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum arquivo encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Sua pesquisa não encontrou nenhum arquivo ou documento. <br />
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.files.length}
          perPage={result.meta.perPage}
        />
      )}
    </div>
  )
}
