import { forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export interface ControlProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Control = forwardRef<HTMLInputElement, ControlProps>(
  (props, ref) => {
    return (
      <input
        ref={ref}
        {...props}
        className={twMerge(
          'w-full border-0 bg-transparent p-0 text-gray-900 placeholder-gray-300 outline-none focus:shadow-none disabled:text-gray-300 dark:text-gray-25 dark:placeholder-gray-350 dark:disabled:text-gray-350',
          props.className,
        )}
      />
    )
  },
)

Control.displayName = 'Control'
