import { statusAPI } from '../lib/axios'

export interface GetAlertMessagesQuery {
  id: string
}

export interface GetAlertMessagesResponse {
  messages: {
    id: string
    description: string
    alertId: string
    createdById: string
    created_at: string
    updated_at: string
  }[]
}

export async function getAlertMessages({ id }: GetAlertMessagesQuery) {
  const response = await statusAPI.get<GetAlertMessagesResponse>(
    '/alerts/messages',
    {
      params: {
        id,
      },
    },
  )

  return response.data
}
