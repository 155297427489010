import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ShieldAlert } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getAuthenticatedUser } from '../../api/get-authenticated-user'
import { userFirstAccess } from '../../api/user-first-access'
import { Button } from '../../components/Button'
import * as Input from '../../components/Form/Input'
import { Toast } from '../../components/Toast'
import { queryClient } from '../../lib/react-query'

const updateUserSchema = z
  .object({
    password: z
      .string()
      .min(1, 'Senha é obrigatória')
      .min(8, 'A senha deve ter pelo menos 8 caracteres'),
    confirmPassword: z
      .string()
      .min(1, 'Senha de confirmação é obrigatória')
      .min(8, 'A senha de confirmação deve ter pelo menos 8 caracteres'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Senhas são diferentes',
  })

type UpdateUserProps = z.infer<typeof updateUserSchema>

export function ChangePasswordModal() {
  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  const [open] = useState<boolean>(authenticatedUser?.user.firstAccess || false)

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateUserProps>({
    resolver: zodResolver(updateUserSchema),
  })

  const { mutateAsync: userFirstAccessFn } = useMutation({
    mutationKey: ['user-first-acess'],
    mutationFn: userFirstAccess,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['authenticated-user'] })
    },
    onError: () => {
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar sua senha, tente novamente mais tarde ou contate o suporte."
          variant="error"
        />
      ))
    },
  })

  async function handleChangePassword(data: UpdateUserProps) {
    if (!authenticatedUser) return

    const { password } = data

    userFirstAccessFn({
      user: {
        id: authenticatedUser?.user.id,
      },
      password,
    })
  }

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex w-full flex-col items-center justify-between">
            <div className="rounded-full bg-purple-100 p-3 dark:bg-purple-900">
              <ShieldAlert className="h-6 w-6 text-purple-600 dark:text-purple-300" />
            </div>
            <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
              Primeiro acesso
            </Dialog.Title>
            <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
              Por favor crie uma nova senha
            </Dialog.Description>
          </div>
          <form className="mt-5" onSubmit={handleSubmit(handleChangePassword)}>
            <div className="flex flex-col gap-1.5">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700 dark:text-gray-375"
              >
                Senha
              </label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      type="password"
                      placeholder="••••••••"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="confirmPassword"
                className="text-sm font-medium text-gray-700 dark:text-gray-375"
              >
                Confirmar senha
              </label>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      placeholder="••••••••"
                      type="password"
                    />
                  </Input.Root>
                )}
              />
            </div>

            <div className="mt-8 flex gap-3">
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
