export function JPEG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M7.75 4A3.25 3.25 0 0 1 11 .75h16c.121 0 .238.048.323.134l10.793 10.793a.457.457 0 0 1 .134.323v24A3.25 3.25 0 0 1 35 39.25H11A3.25 3.25 0 0 1 7.75 36V4Z"
        className="dark:stroke-gray-725"
      />
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M27 .5V8a4 4 0 0 0 4 4h7.5"
        className="dark:stroke-gray-725"
      />
      <rect width="32" height="16" x="1" y="18" fill="#7F56D9" rx="2" />
      <path
        fill="#fff"
        d="M7.59 22.727h1.52v5.071c0 .469-.106.876-.316 1.222a2.089 2.089 0 0 1-.87.799c-.372.187-.804.28-1.296.28-.438 0-.836-.076-1.194-.23a1.907 1.907 0 0 1-.845-.71c-.208-.32-.311-.721-.309-1.204h1.53c.006.191.045.356.118.493a.8.8 0 0 0 .309.313.979.979 0 0 0 .469.106c.19 0 .349-.04.48-.12a.776.776 0 0 0 .301-.363c.069-.158.103-.354.103-.586v-5.07ZM10.38 30v-7.273h2.87c.551 0 1.021.106 1.41.316.388.209.683.499.887.87.206.37.309.796.309 1.279 0 .483-.104.909-.313 1.278-.208.37-.51.657-.905.863-.393.206-.869.309-1.428.309h-1.829V26.41h1.58c.297 0 .54-.051.732-.153.194-.104.339-.247.434-.43.097-.184.145-.396.145-.635 0-.242-.048-.452-.145-.632a.972.972 0 0 0-.434-.423c-.194-.102-.44-.153-.738-.153h-1.037V30H10.38Zm6.475 0v-7.273h4.9v1.268h-3.363v1.733h3.111v1.268h-3.11v1.736h3.377V30h-4.915ZM27.8 25.078a1.594 1.594 0 0 0-.21-.458 1.417 1.417 0 0 0-.77-.558 1.861 1.861 0 0 0-.543-.074c-.372 0-.699.092-.98.277-.28.185-.497.453-.654.806-.156.35-.234.779-.234 1.285 0 .507.077.938.23 1.293.155.355.372.626.654.813.282.185.614.277.998.277.348 0 .645-.061.891-.184.249-.126.438-.302.569-.53.132-.227.198-.495.198-.805l.313.046h-1.875v-1.158h3.043v.916c0 .64-.135 1.189-.405 1.648a2.76 2.76 0 0 1-1.115 1.058c-.473.246-1.015.37-1.626.37-.682 0-1.28-.15-1.797-.452a3.13 3.13 0 0 1-1.207-1.289c-.287-.558-.43-1.221-.43-1.988 0-.59.085-1.115.256-1.577.172-.464.414-.857.724-1.179a3.13 3.13 0 0 1 1.083-.735 3.509 3.509 0 0 1 1.339-.252c.412 0 .795.06 1.15.18.356.12.67.287.945.505.277.218.503.477.678.778.176.298.288.627.338.987H27.8Z"
      />
    </svg>
  )
}
