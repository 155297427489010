import { Skeleton } from '../../../components/Skeleton'

export function GroupsSkeleton() {
  return (
    <>
      <Skeleton className="h-[66px] rounded-lg" />
      <Skeleton className="h-[66px] rounded-lg" />
    </>
  )
}
