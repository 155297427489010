import { api } from '../lib/axios'

export interface RegisterStatusBody {
  name: string
  type: 'wait' | 'analysis' | 'finished'
  team?: {
    id: string
  }
}

export async function registerStatus({ name, type, team }: RegisterStatusBody) {
  await api.post('/status', {
    name,
    type,
    team,
  })
}
