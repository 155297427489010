/* eslint-disable no-template-curly-in-string */
import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react'

import { GetIncidentsResponse } from '../../api/get-incidents'
import { Button } from '../../components/Button'

export function DetailsIncidentModal({
  incident,
}: {
  incident: GetIncidentsResponse['incidents'][number]
}) {
  const [open, setOpen] = useState<boolean>()

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg !bg-transparent p-0 text-sm font-semibold text-purple-700 dark:text-gray-125"
        >
          Detalhes
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <Dialog.Title className="select-none text-lg font-semibold text-gray-900 dark:text-gray-25">
            {incident.title}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            {incident.script ? (
              incident.script.split('\n').map((item) => {
                if (item.includes('${prazo}') && incident.term) {
                  return (
                    <p key={item}>{item.replace('${prazo}', incident.term)}</p>
                  )
                }

                if (item === '') {
                  return <br key={item} />
                }

                return <p key={item}>{item}</p>
              })
            ) : (
              <>
                Entre em contato com{' '}
                <span className="font-medium text-purple-600 dark:text-gray-25">
                  Infraestrutura
                </span>{' '}
                para mais informações.
              </>
            )}
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
