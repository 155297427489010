import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Copy, Save } from 'lucide-react'
import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getIncidentModels } from '../../../api/get-incident-models'
import { updateModel } from '../../../api/update-model'
import { Button } from '../../../components/Button'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { ThemeToggle } from '../../../components/ThemeToggle'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'
import { CreateModelModal } from './create-model-modal'
import { ModelsTableSkeleton } from './models-table-skeleton'

const editScriptSchema = z.object({
  description: z.string(),
})

type EditScriptSchema = z.infer<typeof editScriptSchema>

export function Settings() {
  const [modelId, setModelId] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<EditScriptSchema>({
    resolver: zodResolver(editScriptSchema),
  })

  const description = watch('description')

  const { data: modelsResult, isLoading } = useQuery({
    queryKey: ['incident-models'],
    queryFn: () =>
      getIncidentModels({
        pageIndex,
        perPage,
      }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  const { mutateAsync: updateModelFn } = useMutation({
    mutationKey: ['update-model'],
    mutationFn: updateModel,
    onSuccess(_, variables) {
      queryClient.refetchQueries({ queryKey: ['incident-models'] })
      reset({
        description: variables.description,
      })
      toast.custom((t) => (
        <Toast t={t} title="Modelo atualizado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar este modelo, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  function handleEditModal(data: EditScriptSchema) {
    const { description } = data

    updateModelFn({
      model: {
        id: modelId,
      },
      description,
    })
  }

  const selectedModel =
    modelsResult && modelsResult.models.find((model) => model.id === modelId)

  useEffect(() => {
    if (!selectedModel) return
    reset({
      description: selectedModel.description,
    })
  }, [reset, selectedModel])

  useEffect(() => {
    if (!modelsResult) return

    if (!modelId) {
      setModelId(modelsResult.models[0].id)
    }
  }, [modelsResult])

  return (
    <>
      <div className="flex w-full flex-col justify-between px-4 md:flex-row md:px-0">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
          Configurações
        </h1>
        <div className="mt-4 flex items-center gap-3 md:ml-auto md:mt-0">
          <ThemeToggle />
          <CreateModelModal />
        </div>
      </div>

      <div className="mt-6 h-[calc(100vh-150px)] md:mt-0 md:grid md:grid-cols-2">
        <article className="border-r border-gray-100 dark:border-gray-750 md:py-6 md:pr-8">
          <div className="px-4 md:px-0">
            <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-25">
              Modelos
            </h2>
          </div>
          <Table className="mt-6">
            <TableHeader>
              <TableHead>Título</TableHead>
              <TableHead>Criado em</TableHead>
            </TableHeader>
            <TableBody>
              {modelsResult &&
                modelsResult.models.map((model) => (
                  <TableRow
                    key={model.id}
                    className={clsx('cursor-pointer', {
                      'bg-gray-50 dark:bg-gray-750': modelId === model.id,
                    })}
                    onClick={() => setModelId(model.id)}
                  >
                    <TableCell className="font-medium text-gray-900 dark:font-normal dark:text-gray-25">
                      {model.name}
                    </TableCell>
                    <TableCell>
                      {format(parseISO(model.created_at), 'MMM dd, yyyy', {
                        locale: ptBR,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              {isLoading && <ModelsTableSkeleton perPage={8} />}
            </TableBody>
          </Table>
          {modelsResult && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={modelsResult.meta.pageIndex}
              perPage={modelsResult.meta.perPage}
              totalCount={modelsResult.meta.totalCount}
              totalInPage={modelsResult.models.length}
            />
          )}
        </article>
        <article
          className={clsx('mt-6 md:mt-0 md:py-6 md:pl-8', {
            hidden: !selectedModel,
          })}
        >
          <form
            onSubmit={handleSubmit(handleEditModal)}
            className="flex flex-col"
          >
            <div className="px-4 md:px-0">
              <h2 className="text-lg font-semibold text-gray-700 dark:text-gray-25">
                Script
              </h2>
            </div>
            <div className="mt-6">
              <Controller
                name="description"
                control={control}
                render={({ field }) => <Textarea {...field} />}
              />
            </div>
            <div className="ml-auto mt-6 flex items-center gap-3 px-4 md:px-0">
              <Button
                type="button"
                onClick={() => {
                  window.navigator.clipboard.writeText(description)
                  toast.custom((t) => (
                    <Toast
                      title="Texto copiado para a área de transferência!"
                      t={t}
                      variant="success"
                    />
                  ))
                }}
                variant="outline"
                className="flex items-center gap-1.5"
              >
                <Copy className="h-5 w-5" /> Copiar
              </Button>
              {isDirty && (
                <Button type="submit" className="flex items-center gap-1.5">
                  <Save /> Salvar
                </Button>
              )}
            </div>
          </form>
        </article>
      </div>
    </>
  )
}

interface TextareaProps extends ComponentProps<'textarea'> {
  numOfLines?: number
}

function Textarea({ numOfLines = 10, ...rest }: TextareaProps) {
  const lineCount = useMemo(
    () => String(rest.value).split('\n').length,
    [rest.value],
  )
  const linesArr = useMemo(
    () =>
      Array.from({ length: Math.max(numOfLines, lineCount) }, (_, i) => i + 1),
    [lineCount, numOfLines],
  )

  const lineCounterRef = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleTextareaScroll = () => {
    if (lineCounterRef.current && textareaRef.current) {
      lineCounterRef.current.scrollTop = textareaRef.current.scrollTop
    }
  }

  return (
    <>
      <div className="flex h-6 w-full border border-b-0 border-gray-100 dark:border-gray-750 md:rounded-t-lg">
        <div className="w-[68px] border-r border-gray-100 bg-gray-50 dark:border-gray-750 dark:bg-gray-850"></div>
        <div className="flex-1"></div>
      </div>
      <div className="flex border border-y-0 border-gray-100 dark:border-gray-750">
        <div
          className="w-[68px] border-r border-gray-100 bg-gray-50 px-6 dark:border-gray-750 dark:bg-gray-850"
          ref={lineCounterRef}
        >
          {linesArr.map((count) => (
            <div
              className='!font-["Roboto_Mono",_monospace] font-bold text-gray-300 dark:text-gray-375'
              key={count}
            >
              {count}
            </div>
          ))}
        </div>
        <textarea
          {...rest}
          onScroll={handleTextareaScroll}
          ref={textareaRef}
          wrap="off"
          className="ml-4 mr-6 flex-1 resize-none bg-transparent focus:shadow-none dark:text-gray-50"
        />
      </div>
      <div className="flex h-8 w-full border border-t-0 border-gray-100 dark:border-gray-750 md:rounded-b-lg">
        <div className="w-[68px] border-r border-gray-100 bg-gray-50 dark:border-gray-750 dark:bg-gray-850"></div>
        <div className="flex-1"></div>
      </div>
    </>
  )
}
