import { api } from '../lib/axios'

export interface GetUsersAddMemberFormQuery {
  id: string
}

export interface GetUsersAddMemberFormResponse {
  users: {
    id: string
    name: string
    email: string
  }[]
}

export async function getUsersAddMemberForm({
  id,
}: GetUsersAddMemberFormQuery) {
  const response = await api.get<GetUsersAddMemberFormResponse>(
    `/users/${id}/enabled-add-member-form`,
  )

  return response.data
}
