import { api } from '../lib/axios'

export interface RegisterTicketBody {
  title: string
  location?: string
  priority: 'low' | 'medium' | 'high'
  team: {
    id: string
  }
  responsable: {
    id: string
  }
  members: {
    id: string
  }[]
  category: {
    id: string
    status: {
      id: string
    }
  }
  description: string
  coordinates?: string
}

export async function registerTicket({
  title,
  location,
  priority,
  team,
  responsable,
  members,
  category,
  description,
  coordinates,
}: RegisterTicketBody) {
  await api.post('/tickets', {
    title,
    location,
    priority,
    team,
    responsable,
    members,
    category,
    description,
    coordinates,
  })
}
