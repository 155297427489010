/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'

import { abbreviateName } from '../lib/abbreviate'
import { api } from '../lib/axios'
import { Skeleton } from './Skeleton'

interface AvatarProps {
  name: string
  avatarUrl?: string
  className?: string
}

export const Avatar = memo(
  ({ name, avatarUrl, className }: AvatarProps) => {
    const [imageData, setImageData] = useState<string | null>(null)

    async function fetchImage() {
      try {
        const response = await api({
          method: 'GET',
          url: '/files/' + avatarUrl,
          responseType: 'arraybuffer',
        })

        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        )

        setImageData(
          `data:${response.headers['content-type']};base64,${base64Image}`,
        )
      } catch (error) {
        console.error('Error fetching image:', error)
      }
    }

    useEffect(() => {
      if (!avatarUrl) return
      fetchImage()
    }, [avatarUrl])

    if (avatarUrl) {
      if (imageData) {
        return (
          <img
            src={imageData}
            className={clsx('h-10 w-10 rounded-full object-cover', className)}
            alt=""
          />
        )
      } else {
        return <Skeleton className="h-10 w-10 rounded-full" />
      }
    }

    if (name) {
      return (
        <div className="flex h-10 w-10 select-none items-center justify-center rounded-full bg-purple-600 text-sm font-semibold text-white">
          {abbreviateName(name)}
        </div>
      )
    } else {
      return <Skeleton className="h-10 w-10 rounded-full" />
    }
  },
  (prevProps, nextProps) => {
    return prevProps.avatarUrl === nextProps.avatarUrl
  },
)

Avatar.displayName = 'Avatar'
