import { statusAPI } from '../lib/axios'

export interface RegisterAlertMessageBody {
  alertId: string
  createdById: string
  description: string
}

export async function registerAlertMessage({
  alertId,
  createdById,
  description,
}: RegisterAlertMessageBody) {
  await statusAPI.post('/messages', {
    alertId,
    createdById,
    description,
  })
}
