import { api } from '../lib/axios'

export interface GetAuthenticatedUserResponse {
  user: {
    id: string
    name: string
    email: string
    avatarUrl: string
    active: boolean
    firstAccess: boolean
  }
}

export async function getAuthenticatedUser() {
  const response = await api.get<GetAuthenticatedUserResponse>('/users/me')

  return response.data
}
