import { api } from '../lib/axios'

export interface GetIncidentModelsQuery {
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetIncidentModelsResponse {
  models: {
    id: string
    name: string
    description: string
    active: string
    created_at: string
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getIncidentModels({
  pageIndex,
  perPage,
}: GetIncidentModelsQuery) {
  const response = await api.get<GetIncidentModelsResponse>(
    '/incidents/models',
    {
      params: {
        pageIndex,
        perPage,
      },
    },
  )

  return response.data
}
