import { statusAPI } from '../lib/axios'

export interface RegisterServiceBody {
  name: string
  groupId: string
}

export async function registerServices({ name, groupId }: RegisterServiceBody) {
  await statusAPI.post('/services', {
    name,
    groupId,
  })
}
