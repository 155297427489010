

import clsx from 'clsx'
import { UploadCloud } from 'lucide-react'
import { DragEventHandler, useState } from 'react'

import { useFileInput } from './Root'

export function Trigger() {
  const { fileInputRef, multiple, setFiles } = useFileInput()

  const [hover, setHover] = useState(false)

  const preventDefaults = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = () => setHover(true)
  const handleDragLeave = () => setHover(false)
  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    preventDefaults(e)

    if (e.dataTransfer) {
      const files = Array.from(e.dataTransfer.files)

      if (multiple) {
        setFiles((state) => [...state, ...files])
      } else {
        setFiles([files[0]])
      }
    }

    setHover(false)
  }

  const handleOpen = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragOver={preventDefaults}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleOpen}
      className={clsx(
        'flex flex-col items-center justify-center gap-3 rounded-xl border border-gray-100 bg-white px-6 py-4 shadow-xs hover:cursor-pointer hover:border-2 hover:border-purple-600 dark:border-gray-750 dark:bg-gray-950 hover:dark:border-purple-600',
        {
          'border-2 border-purple-600 dark:border-purple-600': hover,
        },
      )}
    >
      <div className="pointer-events-none flex h-10 w-10 items-center justify-center rounded-lg border border-gray-100 dark:border-gray-725">
        <UploadCloud className="h-5 w-5 text-gray-700 dark:text-gray-125" />
      </div>

      <div className="pointer-events-none flex flex-col items-center gap-1 text-gray-600 dark:text-gray-150">
        <span className="text-sm">
          <span className="font-semibold text-purple-700 dark:text-gray-125">
            Clique para enviar
          </span>{' '}
          ou arraste o arquivo
        </span>
      </div>
    </div>
  )
}
