import { Skeleton } from '../../components/Skeleton'

export function IncidentsPerLevelSkeleton() {
  return (
    <div className="flex justify-between">
      <Skeleton className="mt-8 flex h-[180px] w-[180px] items-center justify-center rounded-full">
        <div className="h-[90px] w-[90px] rounded-full bg-white dark:bg-gray-950" />
      </Skeleton>
      <div className="mt-1 flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <Skeleton className="h-3 w-3 rounded-full" />
          <Skeleton className="h-3 w-[64px] rounded-md" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="h-3 w-3 rounded-full" />
          <Skeleton className="h-3 w-[64px] rounded-md" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="h-3 w-3 rounded-full" />
          <Skeleton className="h-3 w-[64px] rounded-md" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="h-3 w-3 rounded-full" />
          <Skeleton className="h-3 w-[64px] rounded-md" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="h-3 w-3 rounded-full" />
          <Skeleton className="h-3 w-[64px] rounded-md" />
        </div>
      </div>
    </div>
  )
}
