

import * as Select from '@radix-ui/react-select'
import clsx from 'clsx'
import { ComponentProps, ReactNode } from 'react'

export interface ItemTextProps extends ComponentProps<typeof Select.ItemText> {
  children: ReactNode
}

export function ItemText({ children, ...rest }: ItemTextProps) {
  return (
    <Select.ItemText asChild>
      <span
        className={clsx(
          'flex items-center gap-2 text-gray-900 group-data-[disabled]:text-gray-300 dark:text-gray-25 group-data-[disabled]:dark:text-gray-350',
          rest.className,
        )}
      >
        {children}
      </span>
    </Select.ItemText>
  )
}
