import { api } from '../lib/axios'

export interface TurnMemberIntoLeaderQuery {
  id: string | null
  member: {
    id: string
  }
  leader: boolean
}

export async function turnMemberIntoLeader({
  id,
  member,
  leader,
}: TurnMemberIntoLeaderQuery) {
  const response = await api.put(`/teams/${id}/turn-into-leader`, {
    member,
    leader,
  })

  return response.data
}
