import { api } from '../lib/axios'

export interface GetIncidentsPerLevelQuery {
  from?: Date | null
  to?: Date | null
}

export interface GetIncidentsPerLevelMetricResponse {
  incidentsPerLevel: {
    level: number
    count: number
  }[]
}

export async function getIncidentsPerLevelMetric({
  from,
  to,
}: GetIncidentsPerLevelQuery) {
  const response = await api.get<GetIncidentsPerLevelMetricResponse>(
    '/metrics/incidents-per-level',
    {
      params: {
        from,
        to,
      },
    },
  )

  return response.data
}
