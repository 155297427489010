import { useQuery } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { isEmpty } from 'lodash'
import { Search } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getAlerts } from '../../../api/get-alerts'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { AlertsTableSkeleton } from './alerts-table-skeleton'
import { CreateAlertModal } from './create-alert-modal'
import { DeleteAlertModal } from './delete-alert-modal'
import { EditAlertModal } from './edit-alert-modal'
import { MessagesModal } from './message-modal'

export function Alerts() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const {
    data: result,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: ['alerts', pageIndex, perPage],
    queryFn: () =>
      getAlerts({
        pageIndex,
        perPage,
      }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <div>
      <div className="mx-4 mt-8 md:mx-0 md:mt-0 md:flex">
        <div className="flex flex-1 flex-col md:flex-row">
          <div>
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Incidentes
            </h1>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
              Gerencie e crie alertas públicos.
            </p>
          </div>
          <div className="mt-4 flex items-start gap-3 md:ml-auto md:mt-0">
            <CreateAlertModal />
          </div>
        </div>
      </div>
      <Table className="mt-6">
        <TableHeader>
          <TableHead>Título</TableHead>
          <TableHead>Serviços</TableHead>
          <TableHead>Hora</TableHead>
          <TableHead>Status</TableHead>
          <TableHead />
        </TableHeader>
        <TableBody>
          {result &&
            result.alerts.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell className="font-medium text-gray-900 dark:font-normal dark:text-white">
                  {alert.title}
                </TableCell>
                <TableCell>
                  <div className="flex gap-1.5">
                    {alert.services.map((service) => (
                      <Tag key={service.id}>{service.name}</Tag>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  {format(parseISO(alert.created_at), 'HH:mm:ss dd-MM-yyyy')}
                </TableCell>
                <TableCell>
                  <Tag
                    variant={
                      alert.status.type === 'pending'
                        ? 'yellow'
                        : alert.status.type === 'finished'
                          ? 'green'
                          : 'purple'
                    }
                  >
                    {alert.status.name}
                  </Tag>
                </TableCell>
                <TableCell>
                  <div className="flex justify-end gap-3">
                    <EditAlertModal alert={alert} />
                    <DeleteAlertModal alert={alert} />
                    <MessagesModal alert={alert} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          {isLoading && <AlertsTableSkeleton perPage={perPage} />}
        </TableBody>
      </Table>
      {isEmpty(result?.alerts) && isFetched && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum alerta encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Tente novamente ou crie um novo alerta.
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.alerts.length}
          perPage={result.meta.perPage}
        />
      )}
    </div>
  )
}
