import { api } from '../lib/axios'

export interface UpdateModelBody {
  model: {
    id: string
  }
  description: string
}

export async function updateModel({ model, description }: UpdateModelBody) {
  await api.patch('/models', {
    model,
    name,
    description,
  })
}
