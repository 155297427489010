import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Send, X } from 'lucide-react'
import { FormEvent, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  getAlertMessages,
  GetAlertMessagesResponse,
} from '../../../api/get-alert-messages'
import { getAuthenticatedUser } from '../../../api/get-authenticated-user'
import { getUsers } from '../../../api/get-users'
import { registerAlertMessage } from '../../../api/register-alert-message'
import { Button } from '../../../components/Button'
import { Textarea } from '../../../components/Form/Textarea'
import { queryClient } from '../../../lib/react-query'

const messageSchema = z.object({
  description: z.string(),
})

type MessageProps = z.infer<typeof messageSchema>

interface MessageModalProps {
  alert: {
    id: string
  }
}

export function MessagesModal({ alert }: MessageModalProps) {
  const { reset, control, handleSubmit } = useForm<MessageProps>({
    resolver: zodResolver(messageSchema),
  })

  const { data: result } = useQuery({
    queryKey: ['alert-messages', alert.id],
    queryFn: () =>
      getAlertMessages({
        id: alert.id ?? '',
      }),
  })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  const { mutateAsync: registerAlertMessageFn } = useMutation({
    mutationKey: ['register-alert-message'],
    mutationFn: registerAlertMessage,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['alert-messages'] })

      reset({
        description: '',
      })
    },
  })

  async function handleSendMessage(data: MessageProps) {
    const { description } = data

    registerAlertMessageFn({
      alertId: alert.id,
      createdById: authenticatedUser!.user.id,
      description,
    })
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg !bg-transparent p-0 text-sm font-semibold text-purple-700 dark:text-gray-125"
        >
          Detalhes
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed right-0 top-0 z-10 flex min-h-screen w-full max-w-md flex-col bg-white scrollbar-none data-[state=closed]:animate-slideLeftAndFade data-[state=open]:animate-slideRightAndFade dark:bg-gray-950 md:max-w-xl md:border-l md:border-gray-100 md:dark:border-gray-750"
          asChild
        >
          <div className="flex max-h-full flex-col">
            <Dialog.Title className="sr-only">Mensagens</Dialog.Title>
            <div className="flex-shrink-0">
              <div className="flex items-center justify-between border-b border-gray-100 px-6 py-5 dark:border-gray-750 dark:bg-gray-950">
                <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                  Mensagens
                </h1>
                <Dialog.Close asChild>
                  <Button className="p-2.5" variant="ghost">
                    <X className="h-5 w-5 text-gray-600 dark:text-gray-375" />
                  </Button>
                </Dialog.Close>
              </div>
            </div>
            {result && <Messages messages={result.messages} />}

            <div className="flex-shrink-0">
              <form
                onSubmit={handleSubmit(handleSendMessage)}
                className="mt-auto flex gap-3 border-t border-gray-100 px-6 py-5 dark:border-gray-750 dark:bg-gray-950"
              >
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Textarea
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className="!max-h-[166px]"
                      placeholder="Mensagem"
                      onInput={(event: FormEvent<HTMLTextAreaElement>) => {
                        event.currentTarget.style.height = 'auto'
                        event.currentTarget.style.height =
                          event.currentTarget.scrollHeight + 'px'
                      }}
                    />
                  )}
                />
                <Button type="submit" className="ml-auto self-start p-3">
                  <Send className="h-5 w-5" />
                </Button>
              </form>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function Messages({ messages }: GetAlertMessagesResponse) {
  const divRef = useRef<HTMLDivElement>(null)

  const { data: usersResult } = useQuery({
    queryKey: ['get-users'],
    queryFn: () =>
      getUsers({
        pageIndex: 0,
        perPage: 9999,
      }),
  })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  useEffect(() => {
    const scrollToBottom = () => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight
      }
    }

    scrollToBottom()
  }, [])

  return (
    <div
      ref={divRef}
      className="flex-grow space-y-4 overflow-y-auto px-6 py-6 scrollbar-none dark:bg-gray-950"
    >
      {messages.map((message) => {
        const author = usersResult?.users.find(
          (user) => user.id === message.createdById,
        )

        const isAuthor = authenticatedUser?.user.id === author?.id

        if (isAuthor) {
          return (
            <div key={message.id} className="ml-auto w-full max-w-[17rem]">
              <div className="flex items-center justify-between gap-2">
                <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Você
                </p>
                <span className="text-xs text-gray-600 dark:text-gray-375">
                  {format(new Date(message.created_at), 'eeee HH:mm', {
                    locale: ptBR,
                  })}
                </span>
              </div>
              <div
                key={message.id}
                className="mt-1.5 break-words rounded-lg rounded-tr-none bg-purple-600 px-3.5 py-2.5 text-white"
              >
                {message.description.split('\n').map((item) => {
                  if (item === '') {
                    return <br key={item} />
                  }

                  if (item.startsWith('http')) {
                    return (
                      <a
                        key={item}
                        href={item}
                        target="_blank"
                        className="text-white underline hover:text-gray-100 focus:shadow-none"
                        rel="noreferrer"
                      >
                        {item}
                      </a>
                    )
                  }

                  return <p key={item}>{item}</p>
                })}
              </div>
            </div>
          )
        }

        return (
          <div key={message.id} className="w-full max-w-[17rem]">
            <div className="flex items-center justify-between gap-2">
              <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                {author?.name}
              </p>
              <span className="text-xs text-gray-600 dark:text-gray-375">
                {format(new Date(message.created_at), 'eeee HH:mm', {
                  locale: ptBR,
                })}
              </span>
            </div>
            <div
              key={message.id}
              className="mt-1.5 break-words rounded-lg rounded-tl-none border border-gray-100 bg-gray-50 px-3.5 py-2.5 text-gray-900 dark:border-gray-750 dark:bg-gray-850 dark:text-white"
            >
              {message.description.split('\n').map((item) => {
                if (item === '') {
                  return <br key={item} />
                }

                if (item.startsWith('http')) {
                  return (
                    <a
                      key={item}
                      href={item}
                      target="_blank"
                      className="text-purple-500 underline hover:text-purple-600 focus:shadow-none"
                      rel="noreferrer"
                    >
                      {item}
                    </a>
                  )
                }

                return <p key={item}>{item}</p>
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
