import { api } from '../lib/axios'

export interface RegisterViewBody {
  ticket: {
    id: string
  }
}

export async function registerView({ ticket }: RegisterViewBody) {
  await api.post('/views', {
    ticket,
  })
}
