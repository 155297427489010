export function File() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M4.75 4A3.25 3.25 0 0 1 8 .75h16c.121 0 .238.048.323.134l10.793 10.793a.457.457 0 0 1 .134.323v24A3.25 3.25 0 0 1 32 39.25H8A3.25 3.25 0 0 1 4.75 36V4Z"
        className="dark:stroke-gray-725"
      />
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M24 .5V8a4 4 0 0 0 4 4h7.5"
        className="dark:stroke-gray-725"
      />
    </svg>
  )
}
