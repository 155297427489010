import { api } from '../lib/axios'

export interface ResetPasswordBody {
  resetPassword: {
    id: string
  }
  password: string
}

export async function resetPassword({
  resetPassword,
  password,
}: ResetPasswordBody) {
  await api.patch('/reset-password', { resetPassword, password })
}
