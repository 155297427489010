import { api } from '../lib/axios'

export interface GetTasksQuery {
  id?: string
}

interface GetTasksResponse {
  tasks: {
    id: string
    description: string
  }[]
}

export async function getTasks({ id }: GetTasksQuery) {
  const response = await api.get<GetTasksResponse>('/tasks', {
    params: {
      orderId: id,
    },
  })

  return response.data
}
