import { TableHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export function TableRow({
  children,
  ...props
}: TableHTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr
      {...props}
      className={twMerge(
        'group/tableRow text-left transition-colors',
        props.className,
      )}
    >
      {children}
    </tr>
  )
}
