import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { isBefore, parseISO } from 'date-fns'
import {
  ArrowLeft,
  Check,
  CheckCircle,
  LockKeyhole,
  Unlink,
} from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getResetPassword } from '../../api/get-reset-password'
import { resetPassword } from '../../api/reset-password'
import { signIn } from '../../api/sign-in'
import { Button } from '../../components/Button'
import * as Input from '../../components/Form/Input'

const resetPasswordFormSchema = z
  .object({
    password: z
      .string()
      .min(1, 'Senha é obrigatória')
      .min(8, 'A senha deve ter pelo menos 8 caracteres'),
    confirmPassword: z
      .string()
      .min(1, 'Senha de confirmação é obrigatória')
      .min(8, 'A senha de confirmação deve ter pelo menos 8 caracteres'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Senhas são diferentes',
  })

type ResetPasswordFormProps = z.infer<typeof resetPasswordFormSchema>

export function ResetPassword() {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<ResetPasswordFormProps>({
    resolver: zodResolver(resetPasswordFormSchema),
  })

  const [password, confirmPassword] = watch(['password', 'confirmPassword'])

  const { data: result } = useQuery({
    queryKey: ['get-reset-password'],
    queryFn: () =>
      getResetPassword({
        id,
      }),
  })

  const { mutateAsync: resetPasswordFn, isSuccess } = useMutation({
    mutationKey: ['reset-password'],
    mutationFn: resetPassword,
  })

  async function handleResetPassword(data: ResetPasswordFormProps) {
    if (!id) return

    const { password } = data

    resetPasswordFn({
      password,
      resetPassword: {
        id,
      },
    })
  }

  const { mutateAsync: authenticate } = useMutation({
    mutationFn: signIn,
  })

  async function handleSignIn() {
    if (!result) return

    authenticate({
      email: result.reset.user.email,
      keepLoggedIn: true,
      password,
    })
  }

  if (!result) {
    return null
  }

  const expired = isBefore(parseISO(result.reset.expires), new Date())

  if (expired) {
    return (
      <div className="min-h-screen w-full">
        <div className="mx-auto w-full max-w-md p-4 pt-12">
          <div className="mx-auto w-fit rounded-xl border border-gray-100 p-3.5 shadow-xs dark:border-gray-725">
            <Unlink className="h-6 w-6 text-gray-700 dark:text-gray-125" />
          </div>
          <div className="mt-6 text-center">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
              Link expirou
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-150">
              Desculpe, mas parece que o link expirou. Solicite uma nova
              redefinição de senha.
            </p>
          </div>
        </div>
      </div>
    )
  }
  if (isSuccess) {
    return (
      <div className="min-h-screen w-full">
        <div className="mx-auto w-full max-w-md p-4 pt-12">
          <div className="mx-auto w-fit rounded-xl border border-gray-100 p-3.5 shadow-xs dark:border-gray-725">
            <CheckCircle className="h-6 w-6 text-gray-700 dark:text-gray-125" />
          </div>
          <div className="mt-6 text-center">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
              Senha redefinida
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-150">
              Sua senha foi redefinida com sucesso. Clique abaixo para fazer
              login magicamente.
            </p>
          </div>
          <div className="flex flex-col">
            <Button onClick={handleSignIn} className="mt-8 w-full text-base">
              Continuar
            </Button>
            <a
              href="/sign-in"
              className="mt-8 flex items-center gap-1.5 self-center rounded-lg text-sm font-semibold text-gray-600 hover:text-gray-700 focus:shadow-none dark:text-gray-150 hover:dark:text-gray-75"
            >
              <ArrowLeft className="h-5 w-5" /> Voltar para o Login
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen w-full">
      <div className="mx-auto w-full max-w-md p-4 pt-12">
        <div className="mx-auto w-fit rounded-xl border border-gray-100 p-3.5 shadow-xs dark:border-gray-725">
          <LockKeyhole className="h-6 w-6 text-gray-700 dark:text-gray-125" />
        </div>
        <div className="mt-6 text-center">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
            Definir nova senha
          </h1>
          <p className="mt-2 text-gray-600 dark:text-gray-150">
            Sua nova senha deve ser diferente das senhas usadas anteriormente.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(handleResetPassword)}
          className="mt-8 flex flex-1 flex-col gap-6"
        >
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
              Senha
            </label>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Root>
                  <Input.Control
                    {...field}
                    type="password"
                    placeholder="••••••••"
                  />
                </Input.Root>
              )}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
              Confirmar senha
            </label>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Input.Root>
                  <Input.Control
                    {...field}
                    type="password"
                    placeholder="••••••••"
                  />
                </Input.Root>
              )}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-150">
              <div
                className={clsx(
                  'flex h-5 w-5 items-center justify-center rounded-full',
                  {
                    'bg-gray-200 dark:bg-gray-325':
                      !password || password === '' || password.length < 8,
                    'bg-purple-600': password && password.length >= 8,
                  },
                )}
              >
                <Check className="h-3 w-3 text-white" strokeWidth={3} />
              </div>
              Deve ter pelo menos 8 caracteres
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-150">
              <div
                className={clsx(
                  'flex h-5 w-5 items-center justify-center rounded-full',
                  {
                    'bg-gray-200 dark:bg-gray-325':
                      !password ||
                      password === '' ||
                      password !== confirmPassword,
                    'bg-purple-600': password && password === confirmPassword,
                  },
                )}
              >
                <Check className="h-3 w-3 text-white" strokeWidth={3} />
              </div>
              Senhas coincidem
            </div>
          </div>
          <Button className="w-full text-base" disabled={!isValid}>
            Resetar senha
          </Button>
          <a
            href="/sign-in"
            className="mt-3 flex items-center gap-1.5 self-center rounded-lg text-sm font-semibold text-gray-600 hover:text-gray-700 focus:shadow-none dark:text-gray-150 hover:dark:text-gray-75"
          >
            <ArrowLeft className="h-5 w-5" /> Voltar para o Login
          </a>
        </form>
      </div>
    </div>
  )
}
