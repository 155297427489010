import { api } from '../lib/axios'

export interface GetRolesResponse {
  isAdmin: boolean
  leaderOnTeams: {
    id: string
  }[]
  teams: {
    id: string
    permissions: {
      name: string
    }[]
  }[]
}

export async function getRoles() {
  const response = await api.get<GetRolesResponse>('/roles')

  return response.data
}
