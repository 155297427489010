import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { Cog, X } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { turnMemberIntoLeader } from '../../../api/turn-member-into-leader'
import { Button } from '../../../components/Button'
import { Switch } from '../../../components/Switch'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

const editMemberSchema = z.object({
  leader: z.boolean(),
})

type EditMemberProps = z.infer<typeof editMemberSchema>

interface EditUserModalProps {
  user: {
    id: string
    name: string
    isLeader: boolean
  }
}

export function EditMemberModal({ user }: EditUserModalProps) {
  const [open, setOpen] = useState<boolean>()
  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<EditMemberProps>({
    defaultValues: {
      leader: user.isLeader,
    },
    resolver: zodResolver(editMemberSchema),
  })

  const { mutateAsync: turnMemberIntoLeaderFn } = useMutation({
    mutationKey: ['turn-member-into-leader'],
    mutationFn: turnMemberIntoLeader,
    onSuccess: (_, variables) => {
      queryClient.refetchQueries({ queryKey: ['users-on-team', id] })
      queryClient.refetchQueries({ queryKey: ['roles'] })
      setOpen(false)
      reset({
        leader: variables.leader,
      })
      toast.custom((t) => (
        <Toast
          t={t}
          title="Permissões do membro foram atualizadas com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar as permissões deste membro, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleEditTeamMember(data: EditMemberProps) {
    const { leader } = data

    turnMemberIntoLeaderFn({
      id,
      member: {
        id: user.id,
      },
      leader,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        reset()
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg p-0 text-sm font-semibold text-purple-700 dark:text-gray-125"
        >
          Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <Cog className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Close
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Close>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Editar {user.name}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Atualize as permissões do usuário
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleEditTeamMember)}>
            <div className="mt-6 flex items-center gap-2">
              <Switch id="leader" name="leader" control={control} />

              <label
                htmlFor="leader"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Líder
              </label>
            </div>

            <div className="mt-8 flex gap-3">
              <Dialog.Close asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Close>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isDirty}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
