

import { ChangeEvent, InputHTMLAttributes } from 'react'

import { useFileInput } from './Root'

export type ControlProps = InputHTMLAttributes<HTMLInputElement>

export function Control(props: ControlProps) {
  const { setFiles, multiple, id, fileInputRef } = useFileInput()

  function handleFilesSelected(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files?.length) {
      return
    }

    const files = Array.from(event.target.files)

    if (multiple) {
      setFiles((state) => [...state, ...files])
    } else {
      setFiles([files[0]])
    }
  }

  return (
    <input
      id={id}
      type="file"
      className="sr-only"
      onChange={handleFilesSelected}
      multiple={multiple}
      ref={fileInputRef}
      {...props}
    />
  )
}
