import { Slot } from '@radix-ui/react-slot'
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

const button = tv({
  base: [
    'rounded-lg px-3.5 py-2.5 text-sm font-semibold outline-none shadow-xs hover:transitions-colors disabled:cursor-not-allowed',
  ],
  variants: {
    variant: {
      primary:
        'border border-purple-600 hover:border-purple-700 bg-purple-600 text-white hover:bg-purple-700 focus:shadow-base disabled:bg-gray-80 disabled:border-gray-100 disabled:text-gray-400 dark:disabled:bg-gray-750 dark:disabled:border-gray-750 dark:disabled:text-gray-350',
      danger:
        'border border-red-600 text-white hover:bg-red-800 bg-red-600 shadow-danger',
      outline: [
        'border border-gray-200 text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-850 dark:border-gray-700 dark:text-gray-150 dark:hover:bg-gray-750 dark:hover:text-gray-60 focus:shadow-gray',
        'disabled:text-gray-400 disabled:border-gray-100 dark:disabled:text-gray-350 dark:disabled:border-gray-750 dark:disabled:hover:bg-gray-850 disabled:hover:bg-white',
      ],
      outlineDark:
        'border border-gray-200 text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-950 dark:border-gray-750 dark:text-gray-125 dark:hover:bg-gray-750 focus:shadow-gray disabled:text-gray-400 disabled:dark:text-gray-350 disabled:!bg-transparent',
      ghost:
        'text-gray-600 hover:bg-gray-50 transition-colors focus:shadow-gray dark:hover:bg-gray-750 dark:hover:text-gray-75 shadow-none',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {
  asChild?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild, variant, className, ...props }, ref) => {
    const Component = asChild ? Slot : 'button'

    return (
      <Component
        {...props}
        className={button({ variant, className })}
        ref={ref}
      />
    )
  },
)

Button.displayName = 'Button'

export { Button }
