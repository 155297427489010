import { statusAPI } from '../lib/axios'

export interface DeleteAlertBody {
  alertId: string
}

export async function deleteAlert({ alertId }: DeleteAlertBody) {
  await statusAPI.delete('/alerts', {
    data: {
      alertId,
    },
  })
}
