import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface RootProps {
  children: ReactNode
  className?: string
}

export function Table({ children, className }: RootProps) {
  return (
    <div className="w-full overflow-x-auto shadow-sm md:shadow-none">
      <table
        className={twMerge(
          'table w-full border-separate border-spacing-0 overflow-hidden',
          className,
        )}
      >
        {children}
      </table>
    </div>
  )
}
