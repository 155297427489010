import { api } from '../lib/axios'

export interface UpdateStatusBody {
  status: {
    id: string
  }
  name: string
  type: 'wait' | 'analysis' | 'finished'
  team?: {
    id: string
  }
}

export async function updateStatus({
  status,
  name,
  type,
  team,
}: UpdateStatusBody) {
  await api.patch('/status', {
    status,
    name,
    type,
    team,
  })
}
