import { api } from '../lib/axios'

export interface UpdateUserBody {
  user: {
    id: string
  }
  name: string
  active: boolean
  email: string
  firstAccess: boolean
  password?: string | undefined
}

export async function updateUser({
  user,
  name,
  email,
  active,
  firstAccess,
  password,
}: UpdateUserBody) {
  await api.patch('/users', {
    user,
    name,
    email,
    active,
    firstAccess,
    password,
  })
}
