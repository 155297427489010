import { ComboboxOptions, ComboboxOptionsProps } from '@headlessui/react'

export function Content({ ...props }: ComboboxOptionsProps) {
  return (
    <ComboboxOptions
      {...props}
      className="mt-2 max-h-36 w-[calc(var(--input-width)+4.375rem)] overflow-y-auto rounded-lg border border-gray-100 bg-white px-1.5 py-2 shadow-lg empty:hidden dark:border-gray-725 dark:bg-gray-950"
    />
  )
}
