import { Skeleton } from '../../../../components/Skeleton'
import { TableCell, TableRow } from '../../../../components/Table'

export function TicketTableSkeleton() {
  return (
    <TableRow>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-12 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-16 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-24 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-20 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-24 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-32 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-24 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-14 rounded-md" />
      </TableCell>
      <TableCell className="group-hover/tableRow:!bg-transparent">
        <Skeleton className="h-4 w-20 rounded-md" />
      </TableCell>
    </TableRow>
  )
}
