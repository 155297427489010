export function PPTX() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        strokeWidth="1.5"
        className="dark:stroke-gray-725"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        strokeWidth="1.5"
        className="dark:stroke-gray-725"
      />
      <rect x="1" y="18" width="33" height="16" rx="2" fill="#E62E05" />
      <path
        d="M4.74343 30V22.7273H7.61275C8.16436 22.7273 8.63429 22.8326 9.02255 23.0433C9.41081 23.2517 9.70674 23.5417 9.91033 23.9134C10.1163 24.2827 10.2193 24.7088 10.2193 25.1918C10.2193 25.6747 10.1151 26.1009 9.90678 26.4702C9.69845 26.8395 9.3966 27.1271 9.00124 27.3331C8.60825 27.5391 8.1324 27.642 7.57369 27.642H5.74485V26.4098H7.32511C7.62103 26.4098 7.86488 26.3589 8.05664 26.2571C8.25077 26.1529 8.39518 26.0097 8.48988 25.8274C8.58694 25.6428 8.63548 25.4309 8.63548 25.1918C8.63548 24.9503 8.58694 24.7396 8.48988 24.5597C8.39518 24.3774 8.25077 24.2365 8.05664 24.1371C7.86251 24.0353 7.6163 23.9844 7.318 23.9844H6.28107V30H4.74343ZM11.218 30V22.7273H14.0874C14.639 22.7273 15.1089 22.8326 15.4972 23.0433C15.8854 23.2517 16.1813 23.5417 16.3849 23.9134C16.5909 24.2827 16.6939 24.7088 16.6939 25.1918C16.6939 25.6747 16.5897 26.1009 16.3814 26.4702C16.1731 26.8395 15.8712 27.1271 15.4759 27.3331C15.0829 27.5391 14.607 27.642 14.0483 27.642H12.2195V26.4098H13.7997C14.0956 26.4098 14.3395 26.3589 14.5312 26.2571C14.7254 26.1529 14.8698 26.0097 14.9645 25.8274C15.0616 25.6428 15.1101 25.4309 15.1101 25.1918C15.1101 24.9503 15.0616 24.7396 14.9645 24.5597C14.8698 24.3774 14.7254 24.2365 14.5312 24.1371C14.3371 24.0353 14.0909 23.9844 13.7926 23.9844H12.7557V30H11.218ZM17.4157 23.995V22.7273H23.3887V23.995H21.1621V30H19.6422V23.995H17.4157ZM25.8141 22.7273L27.2807 25.206H27.3375L28.8113 22.7273H30.5478L28.3283 26.3636L30.5975 30H28.829L27.3375 27.5178H27.2807L25.7892 30H24.0279L26.3042 26.3636L24.0705 22.7273H25.8141Z"
        fill="white"
      />
    </svg>
  )
}
