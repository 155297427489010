import { api } from '../lib/axios'

export interface GetTeamQuery {
  id?: string | null
}

export interface GetTeamResponse {
  team: {
    id: string
    name: string
    active: boolean
    permissions: {
      name: string
    }[]
    users: {
      id: string
      name: string
      email: string
      isLeader: boolean
    }[]
  }
}

export async function getTeam({ id }: GetTeamQuery) {
  const response = await api.get<GetTeamResponse>(`/teams/${id}`)

  return response.data
}
