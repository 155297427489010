import { api } from '../lib/axios'

export interface GetDevicesQuery {
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetDevicesResponse {
  devices: {
    id: string
    name: string
    mac: string
    transmissor: string
    slot: number
    pon: number
    status?: {
      name: string
      type: 'progress' | 'error' | 'success'
    }
    created_at: Date
    updated_at: Date
    createdBy: {
      id: string
      name: string
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getDevices({ pageIndex, perPage }: GetDevicesQuery) {
  const response = await api.get<GetDevicesResponse>('/devices', {
    params: {
      perPage,
      pageIndex,
    },
  })

  return response.data
}
