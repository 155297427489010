import { api } from '../lib/axios'

export interface RegisterUserBody {
  name: string
  email: string
  password: string
  firstAccess: boolean
}

export async function registerUser({
  name,
  email,
  password,
  firstAccess,
}: RegisterUserBody) {
  await api.post('/users', {
    name,
    email,
    password,
    firstAccess,
  })
}
