import { api } from '../lib/axios'

interface DeleteStatusQuery {
  status: {
    id: string
  }
}

export async function deleteStatus({ status }: DeleteStatusQuery) {
  await api.delete('/status', {
    data: {
      status,
    },
  })
}
