import { api } from '../lib/axios'

export interface RegisterMessageBody {
  ticket: {
    id: string
  }
  text: string
}

export async function registerMessage({ ticket, text }: RegisterMessageBody) {
  await api.post('/message', {
    ticket,
    text,
  })
}
