import { api } from '../lib/axios'

export interface AddMemberToTeamQuery {
  id: string | null
  members: {
    id: string
  }[]
}

export async function addMemberToTeam({ id, members }: AddMemberToTeamQuery) {
  const response = await api.post(`/teams/${id}/add`, {
    members,
  })

  return response.data
}
