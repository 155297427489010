import {
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import { MapIcon, Search } from 'lucide-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import * as Input from '../components/Form/Input'
import { env } from '../env'
import { Button } from './Button'

const libraries: Libraries = ['places']

export interface LatLng {
  lat: number
  lng: number
}

interface MapProps {
  center?: LatLng
  setLocation: React.Dispatch<React.SetStateAction<LatLng | undefined>>
  setOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

export function Map({ center, setLocation, setOpen }: MapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: env.VITE_GOOGLE_MAPS_API || '',
    libraries,
  })

  const [marker, setMarker] = useState<LatLng | null>(null) // Store only one marker
  const [input, setInput] = useState<string>('')
  const mapRef = useRef<google.maps.Map | null>(null)
  const [places, setPlaces] = useState<google.maps.GeocoderResult[] | null>(
    null,
  )

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleSearch = () => {
    const geocoder = new window.google.maps.Geocoder()

    if (/^-?\d+\.\d+,\s*-?\d+\.\d+$/.test(input)) {
      // Input is a coordinate, split and pan to the coordinates
      const [lat, lng] = input.split(',').map(Number)
      setMarker({ lat, lng })
      mapRef.current?.panTo({ lat, lng })
    } else {
      // Input is a place, geocode the place
      geocoder.geocode({ address: input }, (results, status) => {
        if (status === 'OK' && results && results[0].geometry.location) {
          setPlaces(results)
          const { lat, lng } = results[0].geometry.location
          setMarker({ lat: lat(), lng: lng() })
          mapRef.current?.panTo({ lat: lat(), lng: lng() })
        } else {
          setPlaces(null)
        }
      })
    }
  }

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const newLat = e.latLng.lat()
      const newLng = e.latLng.lng()
      setMarker({ lat: newLat, lng: newLng }) // Set one marker
    }
  }

  const handleSelectLocation = () => {
    if (!marker) return
    setLocation(marker)
    setOpen(false)
  }

  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded) return <div>Loading Maps...</div>

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex w-full flex-col bg-white p-6 dark:bg-gray-950 md:max-w-lg">
        <div className="mb-6 flex items-center gap-3 text-gray-900 dark:text-gray-25">
          <MapIcon className="h-8 w-8" strokeWidth={2} />
          <h1 className="text-2xl font-medium">Maps</h1>
        </div>

        <div className="flex gap-3">
          <Input.Root className="w-full">
            <Input.Control
              value={input}
              onChange={handleInputChange}
              placeholder="Pesquise as coordenadas ou o lugar"
            />
          </Input.Root>
          <Button
            variant="outline"
            onClick={handleSearch}
            type="button"
            className="ml-auto flex w-14 items-center justify-center gap-1.5 p-0"
          >
            <Search className="h-5 w-5" />
          </Button>
        </div>
        <div className="mt-4 flex flex-col gap-3">
          {places &&
            places.map((place) => (
              <Place
                key={place.place_id}
                place={place}
                mapRef={mapRef}
                setMarker={setMarker}
              />
            ))}
        </div>
        <div className="mt-auto">
          <div className="mb-4">
            <Input.Root>
              <Input.Control
                placeholder="Selecione uma localização"
                value={marker ? `${marker.lat}, ${marker.lng}` : ''}
              />
            </Input.Root>
          </div>
          <Button
            onClick={handleSelectLocation}
            className="flex w-full items-center justify-center gap-1.5"
            disabled={!marker}
          >
            <MapIcon className="h-5 w-5" />
            Confirmar
          </Button>
        </div>
      </div>

      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100vh' }}
        zoom={10}
        center={center}
        options={{ zoomControl: true }}
        onClick={handleMapClick}
        onLoad={onMapLoad}
      >
        {marker && <Marker position={marker} />}
      </GoogleMap>
    </div>
  )
}

interface PlaceProps {
  place: google.maps.GeocoderResult
  mapRef: React.MutableRefObject<google.maps.Map | null>
  setMarker: React.Dispatch<React.SetStateAction<LatLng | null>>
}

function Place({ place, mapRef, setMarker }: PlaceProps) {
  const [photoUrl, setPhotoUrl] = useState<string | null>(null)

  useEffect(() => {
    if (!mapRef) return

    if (!mapRef.current) return

    const placesService = new window.google.maps.places.PlacesService(
      mapRef.current,
    )

    const request = {
      placeId: place.place_id,
      fields: ['photos', 'name'],
    }

    placesService.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (!place) return
        // Get the photo and place name
        if (place.photos) {
          const photoUrl = place.photos[0].getUrl({
            maxWidth: 400,
            maxHeight: 300,
          })
          setPhotoUrl(photoUrl)
        } else {
          setPhotoUrl(null) // No photo available
        }
      } else {
        setPhotoUrl(null)
      }
    })
  }, [mapRef])

  function handleSelectPlace() {
    if (!mapRef) return

    const { lat, lng } = place.geometry.location
    setMarker({ lat: lat(), lng: lng() })
    mapRef.current?.panTo({ lat: lat(), lng: lng() })
  }

  return (
    <div
      onClick={handleSelectPlace}
      className="flex cursor-pointer gap-5 rounded-xl border border-gray-100 bg-white p-4 dark:border-gray-750 dark:bg-gray-950"
    >
      <img
        src={photoUrl || ''}
        className="max-h-[144px] min-h-[144px] w-full max-w-[200px] rounded-lg object-cover"
        alt=""
      />
      <div className="flex flex-col">
        <p className="text-xs font-semibold text-purple-700 dark:text-purple-500">
          {place.geometry.location.toString().replace(')', '').replace('(', '')}
        </p>
        <h2 className="text-lg font-medium text-gray-900 dark:text-gray-25">
          {place.formatted_address}
        </h2>
      </div>
    </div>
  )
}
