import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../components/Button'

export function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="min-h-screen flex-col justify-center px-4 py-16 dark:bg-gray-950 md:flex md:p-20">
      <h2 className="font-semibold text-purple-700">404 error</h2>
      <h1 className="mt-3 text-4xl font-semibold text-gray-900 dark:text-gray-50">
        Ops! Parece que estamos com dificuldades para localizar essa página.
      </h1>
      <p className="mt-4 text-lg text-gray-600 dark:text-gray-125">
        Lamentamos, mas a página que procura não existe ou foi movida.
      </p>
      <div className="mt-8 flex flex-col gap-3 md:w-fit md:flex-row-reverse">
        <Button onClick={() => navigate('/')}>Ir para a Home</Button>
        <Button
          onClick={() => navigate(-1)}
          variant="outline"
          className="flex items-center justify-center gap-1.5"
        >
          <ArrowLeft className="h-5 w-5" />
          Voltar
        </Button>
      </div>
    </div>
  )
}
