

import * as Select from '@radix-ui/react-select'
import { ChevronDown } from 'lucide-react'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type TriggerProps = ComponentProps<typeof Select.Trigger>

export function Trigger({ children, ...props }: TriggerProps) {
  return (
    <Select.Trigger
      {...props}
      className={twMerge(
        'group flex w-full items-center justify-between gap-2 rounded-lg border border-gray-200 bg-white px-3.5 py-2.5 shadow-xs outline-none data-[placeholder]:px-3 data-[placeholder]:py-2 data-[placeholder]:text-gray-300 focus:border-purple-400 focus:shadow-base dark:border-gray-725 dark:bg-gray-950 dark:text-gray-25 dark:data-[placeholder]:text-gray-350 dark:focus:border-purple-400',
        props.className,
      )}
    >
      {children}

      <Select.Icon>
        <ChevronDown className="h-5 w-5 text-gray-150" />
      </Select.Icon>
    </Select.Trigger>
  )
}
