import { HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export function Root(props: RootProps) {
  return (
    <div
      {...props}
      className={twMerge(
        'flex items-center gap-2 rounded-lg border border-gray-200 px-3 py-2 shadow-xs focus-within:border-purple-400 focus-within:shadow-base dark:border-gray-725 dark:focus-within:border-purple-400',
        props.className,
      )}
    />
  )
}
