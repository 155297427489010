export function BackgroundPattern() {
  return (
    <svg
      width={768}
      height={769}
      viewBox="0 0 768 769"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute left-1/2 top-24 z-0 -translate-x-1/2"
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={768}
        height={769}
      >
        <path
          transform="translate(0 .5)"
          fill="url(#paint0_radial_21448_130)"
          d="M0 0H768V768H0z"
        />
      </mask>
      <g
        clipPath="url(#clip0_21448_130)"
        className="dark:stroke-[#1F242F]"
        stroke="#EAECF0"
        mask="url(#a)"
      >
        <g clipPath="url(#clip1_21448_130)">
          <path d="M0.5 0.5L0.5 768.5" />
          <path d="M48.5 0.5L48.5 768.5" />
          <path d="M96.5 0.5L96.5 768.5" />
          <path d="M144.5 0.5L144.5 768.5" />
          <path d="M192.5 0.5L192.5 768.5" />
          <path d="M240.5 0.5L240.5 768.5" />
          <path d="M288.5 0.5L288.5 768.5" />
          <path d="M336.5 0.5L336.5 768.5" />
          <path d="M384.5 0.5L384.5 768.5" />
          <path d="M432.5 0.5L432.5 768.5" />
          <path d="M480.5 0.5L480.5 768.5" />
          <path d="M528.5 0.5L528.5 768.5" />
          <path d="M576.5 0.5L576.5 768.5" />
          <path d="M624.5 0.5L624.5 768.5" />
          <path d="M672.5 0.5L672.5 768.5" />
          <path d="M720.5 0.5L720.5 768.5" />
        </g>
        <path d="M0.5 1H767.5V768H0.5z" />
        <g clipPath="url(#clip2_21448_130)">
          <path d="M0 48L768 48" />
          <path d="M0 96L768 96" />
          <path d="M0 144L768 144" />
          <path d="M0 192L768 192" />
          <path d="M0 240L768 240" />
          <path d="M0 288L768 288" />
          <path d="M0 336L768 336" />
          <path d="M0 384L768 384" />
          <path d="M0 432L768 432" />
          <path d="M0 480L768 480" />
          <path d="M0 528L768 528" />
          <path d="M0 576L768 576" />
          <path d="M0 624L768 624" />
          <path d="M0 672L768 672" />
          <path d="M0 720L768 720" />
          <path d="M0 768L768 768" />
        </g>
        <path d="M0.5 1H767.5V768H0.5z" />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_21448_130"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 0 384) scale(384)"
        >
          <stop />
          <stop offset={1} stopOpacity={0} />
        </radialGradient>
        <clipPath id="clip0_21448_130">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0H768V768H0z" />
        </clipPath>
        <clipPath id="clip1_21448_130">
          <path fill="#fff" d="M0 0.5H768V768.5H0z" />
        </clipPath>
        <clipPath id="clip2_21448_130">
          <path fill="#fff" d="M0 0.5H768V768.5H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
