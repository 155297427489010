import { api } from '../lib/axios'

interface DeleteCategoryQuery {
  category: {
    id: string
  }
}

export async function deleteCategory({ category }: DeleteCategoryQuery) {
  await api.delete('/categories', {
    data: {
      category,
    },
  })
}
