import { api } from '../lib/axios'

export interface GetTicketMessagesQuery {
  id: string
}

export interface GetTicketMessagesResponse {
  messages: Array<
    {
      id: string
      text: string
      created_at: string
      author: {
        id: string
        name: string
      }
    }[]
  >
}

export async function getTicketMessages({ id }: GetTicketMessagesQuery) {
  const response = await api.get<GetTicketMessagesResponse>(`/messages/${id}`)

  return response.data
}
