import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import _ from 'lodash'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

type ChartTooltipProps = TooltipProps<ValueType, NameType>

export function ChartTooltip({ ...props }: ChartTooltipProps) {
  if (!props.active) {
    return null
  }

  if (_.isEmpty(props.payload)) {
    return null
  }

  if (!props.payload) {
    return null
  }

  const { dataKey, payload } = props.payload[0]

  return (
    <div className="flex flex-col rounded-lg border border-gray-100 bg-white dark:border-gray-750 dark:bg-gray-950">
      <div className="border-b border-gray-100 px-2 pb-0.5 pt-1 dark:border-gray-750">
        <p className="text-xs font-semibold text-gray-900 dark:text-gray-25">
          {props.label
            ? payload.period
              ? format(parseISO(payload.period), "EEEE, dd 'de' LLLL", {
                  locale: ptBR,
                })
              : props.label
            : payload.level
              ? `Nível ${payload.level}`
              : null}
        </p>
      </div>
      <div className="flex items-center gap-1.5 bg-gray-50 bg-opacity-25 px-2 pb-1 pt-0.5 text-xs text-gray-600 dark:bg-gray-750 dark:text-gray-125">
        <div
          className="h-2 w-2 rounded-full bg-purple-500"
          style={{ background: payload.color }}
        />
        total:
        <span className="font-medium">
          {dataKey === 'value'
            ? Number(payload[dataKey as string]).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            : payload[dataKey as string]}
        </span>
      </div>
    </div>
  )
}
