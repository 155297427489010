import * as RadioGroup from '@radix-ui/react-radio-group'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { CheckCircle, Clock, Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getAuthenticatedUser } from '../../../api/get-authenticated-user'
import { getRoles } from '../../../api/get-roles'
import { getTicketStatus } from '../../../api/get-ticket-status'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { CreateStatusModal } from './create-status-modal'
import { DeleteStatusModal } from './delete-status-modal'
import { EditStatusModal } from './edit-status-modal'
import { StatusTableSkeleton } from './status-table-skeleton'

export function Status() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '8')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get('type') ?? undefined,
  )

  const { data: result, isLoading } = useQuery({
    queryKey: ['ticket-status', pageIndex, perPage, selectedStatus],
    queryFn: () =>
      getTicketStatus({
        pageIndex,
        perPage,
        type: selectedStatus || null,
      }),
  })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  const isLeaderOrAdmin = roles?.isAdmin || roles?.leaderOnTeams.length

  const status = [
    {
      name: 'wait',
      iconClass: 'bg-yellow-100 dark:bg-yellow-900',
      icon: <Clock className="h-4 w-4 text-yellow-600 dark:text-yellow-300" />,
      title: 'Aguardando',
      description:
        'Este tipo de status é utilizado quando se está aguardando a análise do chamado.',
      className:
        'data-[state=checked]:border-yellow-600 data-[state=checked]:bg-yellow-50 dark:data-[state=checked]:border-yellow-300 dark:data-[state=checked]:bg-yellow-900',
    },
    {
      name: 'analysis',
      iconClass: 'bg-purple-100 dark:bg-purple-900',
      icon: <Search className="h-4 w-4 text-purple-600 dark:text-purple-300" />,
      title: 'Em análise',
      description:
        'Este tipo de status é usado quando a solicitação está em análise.',
      className:
        'data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-50 dark:data-[state=checked]:border-purple-300 dark:data-[state=checked]:bg-purple-900',
    },
    {
      name: 'finished',
      iconClass: 'bg-green-100 dark:bg-green-900',
      icon: (
        <CheckCircle className="h-4 w-4 text-green-600 dark:text-green-300" />
      ),
      title: 'Finalizado',
      description:
        'Este tipo de status é utilizado quando o problema abordado no chamado foi resolvido.',
      className:
        'data-[state=checked]:border-green-600 data-[state=checked]:bg-green-50 dark:data-[state=checked]:border-green-300 dark:data-[state=checked]:bg-green-900',
    },
  ]

  useEffect(() => {
    if (!selectedStatus) return

    setSearchParams((state) => {
      state.set('type', selectedStatus)
      state.set('page', '1')

      return state
    })
  }, [selectedStatus])

  return (
    <>
      <div className="mx-4 mb-6 mt-8 border-b border-gray-100 pb-5 dark:border-gray-750 md:mx-0 md:flex">
        <div>
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Status
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie o status de chamados da sua equipe.
          </p>
        </div>
        {isLeaderOrAdmin && (
          <div className="ml-auto mt-4 flex gap-3 md:mt-0 md:self-start">
            <CreateStatusModal />
          </div>
        )}
      </div>
      <div className="mx-4 gap-8 md:mx-0 md:mb-6 md:grid md:grid-cols-2 md:border-b md:border-gray-100 md:pb-6 dark:md:border-gray-750">
        <div className="mt-3">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Tipos
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Existem três tipos de status: aguardando, em análise e finalizado.
          </p>
        </div>
        <div className="flex gap-3">
          <div className="flex w-full flex-col">
            <RadioGroup.Root
              value={selectedStatus}
              onValueChange={setSelectedStatus}
              className="mt-4 flex flex-col gap-3 focus:shadow-none"
            >
              {status.map(
                ({ name, iconClass, icon, title, description, className }) => (
                  <RadioGroup.Item
                    key={name}
                    value={name}
                    className={clsx(
                      'flex flex-1 cursor-pointer items-start gap-3 rounded-lg border border-gray-100 bg-gray-50 p-4 focus:shadow-none dark:border-gray-750 dark:bg-gray-850',
                      className,
                    )}
                    onClick={(e) => {
                      if (selectedStatus === e.currentTarget.value) {
                        setSearchParams((state) => {
                          state.delete('type')

                          return state
                        })
                        setSelectedStatus('')
                      }
                    }}
                  >
                    <div
                      className={clsx(
                        'flex items-center justify-center rounded-full p-2',
                        iconClass,
                      )}
                    >
                      {icon}
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                        {title}
                      </p>
                      <span className="text-left text-sm text-gray-600 dark:text-gray-150">
                        {description}
                      </span>
                    </div>
                  </RadioGroup.Item>
                ),
              )}
            </RadioGroup.Root>
          </div>
        </div>
      </div>
      <div className="mb-6 gap-16 pt-8 md:mx-0 md:grid md:grid-cols-status md:pt-0">
        <div className="px-4 md:px-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Status compartilhados
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie os status compartilhados com você.
          </p>
        </div>
        <div>
          <Table className="mt-4 md:mt-0">
            <TableHeader>
              <TableHead>Nome</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead>Equipe</TableHead>
              <TableHead />
            </TableHeader>
            <TableBody>
              {result?.status
                .sort((a, b) => {
                  const teamNameA = a.team ? a.team.name : ''
                  const teamNameB = b.team ? b.team.name : ''

                  if (teamNameA < teamNameB) {
                    return -1
                  }
                  if (teamNameA > teamNameB) {
                    return 1
                  }
                  return 0
                })
                .map((status) => {
                  const isLeader = status.team
                    ? roles?.leaderOnTeams.some(
                        (team) => team.id === status.team.id,
                      )
                    : false

                  const isCreator =
                    status.createdById === authenticatedUser?.user.id

                  const isLeaderOrCreator = isLeader || isCreator

                  return (
                    <TableRow key={status.id}>
                      <TableCell>{status.name}</TableCell>
                      <TableCell>
                        {
                          <Tag
                            variant={
                              status.type === 'wait'
                                ? 'yellow'
                                : status.type === 'finished'
                                  ? 'green'
                                  : 'purple'
                            }
                          >
                            {status.type === 'wait'
                              ? 'aguardando'
                              : status.type === 'finished'
                                ? 'finalizado'
                                : 'em análise'}
                          </Tag>
                        }
                      </TableCell>
                      <TableCell>
                        {status.team && (
                          <Tag variant={status.team.color}>
                            {status.team.name}
                          </Tag>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-end gap-3">
                          {isLeaderOrCreator && (
                            <>
                              <DeleteStatusModal status={status} />
                              <EditStatusModal status={status} />
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              {isLoading && <StatusTableSkeleton perPage={perPage} />}
            </TableBody>
          </Table>
          {result && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={result.meta.pageIndex}
              perPage={result.meta.perPage}
              totalCount={result.meta.totalCount}
              totalInPage={result.status.length}
            />
          )}
        </div>
      </div>
    </>
  )
}
