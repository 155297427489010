import { useQuery } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useParams } from 'react-router-dom'

import { getTicket } from '../../../../api/get-ticket'
import { Skeleton } from '../../../../components/Skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../components/Table'
import { Tag } from '../../../../components/Tag'
import { Activity } from './activity'
import { Map } from './map'
import { TicketTableSkeleton } from './ticket-table-skeleton'

export function Overview() {
  const { ticketId } = useParams()

  const {
    data: result,
    isLoading,
    isLoadingError,
  } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  const coordinates = result?.ticket.coordinates

  return (
    <div className="md:grid md:grid-cols-call md:gap-8">
      <div>
        <div>
          <Table>
            <TableHeader>
              <TableHead>ID</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Criado por</TableHead>
              <TableHead>Cidade</TableHead>
              <TableHead>Responsável</TableHead>
              <TableHead>Categoria</TableHead>
              <TableHead>Equipe</TableHead>
              <TableHead>Prioridade</TableHead>
              <TableHead>Status</TableHead>
            </TableHeader>
            <TableBody>
              {result && (
                <TableRow>
                  <TableCell>
                    <Tag>RMX-{result.ticket.id}</Tag>
                  </TableCell>
                  <TableCell>
                    {format(parseISO(result.ticket.created_at), "MMM d',' u", {
                      locale: ptBR,
                    })}
                  </TableCell>
                  <TableCell>{result.ticket.createdBy.name}</TableCell>
                  <TableCell>
                    {result.ticket.location === 'all' ? (
                      <Tag>Todas</Tag>
                    ) : result.ticket.location ? (
                      <Tag>{result.ticket.location}</Tag>
                    ) : (
                      <Tag>Nenhuma</Tag>
                    )}
                  </TableCell>
                  <TableCell>{result.ticket.responsable.name}</TableCell>
                  <TableCell>
                    <Tag>{result.ticket.category.name}</Tag>
                  </TableCell>
                  <TableCell>
                    <Tag variant={result.ticket.team.color}>
                      {result.ticket.team.name}
                    </Tag>
                  </TableCell>
                  <TableCell>
                    <Tag
                      variant={
                        result.ticket.priority === 'high'
                          ? 'red'
                          : result.ticket.priority === 'medium'
                            ? 'yellow'
                            : 'blue'
                      }
                    >
                      {result.ticket.priority === 'high'
                        ? 'Alta'
                        : result.ticket.priority === 'medium'
                          ? 'Média'
                          : 'Baixa'}
                    </Tag>
                  </TableCell>
                  <TableCell>
                    <Tag
                      variant={
                        result.ticket.status.type === 'wait'
                          ? 'yellow'
                          : result.ticket.status.type === 'analysis'
                            ? 'purple'
                            : 'green'
                      }
                    >
                      {result.ticket.status.name}
                    </Tag>
                  </TableCell>
                </TableRow>
              )}
              {(isLoading || isLoadingError) && <TicketTableSkeleton />}
            </TableBody>
          </Table>
        </div>
        {result && coordinates && (
          <Map
            position={{
              lat: Number(coordinates.split(', ')[0]),
              lng: Number(coordinates.split(', ')[1]),
            }}
          />
        )}
        <div className="mt-6 px-4 md:px-0">
          {result && (
            <div className="break-words rounded-lg bg-gray-50 p-4 font-medium text-gray-700 dark:bg-gray-850 dark:text-gray-125">
              {coordinates && (
                <p className="mb-4 text-gray-600 dark:text-gray-150">
                  {coordinates}
                </p>
              )}
              {result.customerIXC && (
                <p className="mb-4 text-gray-600 dark:text-gray-150">
                  {result.customerIXC}
                </p>
              )}

              {result.ticket?.description.split('\n').map((item) => {
                if (item.startsWith('http')) {
                  return (
                    <a
                      key={item}
                      href={item}
                      target="_blank"
                      className="text-purple-500 underline hover:text-purple-600 focus:shadow-none"
                      rel="noreferrer"
                    >
                      {item}
                    </a>
                  )
                }

                if (item === '') {
                  return <br key={item} />
                }

                return <p key={item}>{item}</p>
              })}
            </div>
          )}
          {(isLoading || isLoadingError) && (
            <Skeleton className="h-16 w-full rounded-lg" />
          )}
        </div>
      </div>
      <div className="mt-6 md:mt-0">
        <div className="border border-gray-100 p-6 dark:border-gray-750 md:rounded-2xl">
          <div className="border-b border-gray-100 pb-6 dark:border-gray-750">
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Atividade recente
            </h1>
          </div>

          <Activity />
        </div>
      </div>
    </div>
  )
}
