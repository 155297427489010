import { ComboboxOption, ComboboxOptionProps } from '@headlessui/react'

import { useCombobox } from './Root'

export function Option({ ...props }: ComboboxOptionProps<'input', unknown>) {
  const { query } = useCombobox()

  if (!String(props.children).toLowerCase().includes(query.toLowerCase())) {
    return null
  }

  return (
    <ComboboxOption
      {...props}
      className="cursor-pointer rounded-lg px-2 py-2.5 ui-active:bg-gray-50 hover:bg-gray-50 dark:text-gray-25 dark:ui-active:bg-gray-725 dark:hover:bg-gray-725"
    >
      {props.children}
    </ComboboxOption>
  )
}
