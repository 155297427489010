import * as Tabs from '@radix-ui/react-tabs'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'

import * as Select from '../../../components/Form/Select'
import { TabItem } from '../../../components/TabItem'
import { ThemeToggle } from '../../../components/ThemeToggle'

export function IXCLayout() {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  return (
    <>
      <div className="flex w-full flex-col justify-between px-4 md:flex-row md:px-0">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
          IXCSoft
        </h1>
        <div className="ml-auto">
          <ThemeToggle />
        </div>
      </div>
      <div className="px-4 md:px-0">
        {isMobile ? (
          <Select.Root defaultValue="slot">
            <Select.Trigger className="mb-8 mt-6">
              <Select.Value />
            </Select.Trigger>

            <Select.Content>
              <Select.Item value="slot">
                <Select.ItemText>SLOT/PON</Select.ItemText>
              </Select.Item>
            </Select.Content>
          </Select.Root>
        ) : (
          <Tabs.Root
            defaultValue="slot"
            className="hidden border-b border-gray-100 dark:border-gray-750 md:block"
          >
            <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
              <TabItem
                value="slot"
                title="SLOT/PON"
                isSelected
                layoutId="ixcsoft"
              />
            </Tabs.List>
          </Tabs.Root>
        )}
      </div>
      <Outlet />
    </>
  )
}
