/* eslint-disable react-hooks/exhaustive-deps */
import * as Dialog from '@radix-ui/react-dialog'
import { MapIcon } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Button } from '../../../components/Button'
import { LatLng, Map } from '../../../components/Map'

interface MapLocationProps {
  value: string | undefined
  onValueChange: (value: string | undefined) => void
  disabled?: boolean
}

export function MapLocationModal({
  value,
  onValueChange,
  disabled,
}: MapLocationProps) {
  const [open, setOpen] = useState<boolean>()

  const [location, setLocation] = useState<LatLng | undefined>(() => {
    if (value) {
      return {
        lat: Number(value.split(',')[0]),
        lng: Number(value.split(',')[1]),
      }
    }
  })

  const [userLocation, setUserLocation] = useState<{
    lat: number
    lng: number
  }>()

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setUserLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        })
      })
    }
  }, [navigator])

  const formattedLocation =
    location &&
    Object.entries(location)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value)
      .join(', ')

  useEffect(() => {
    onValueChange(formattedLocation)
  }, [location])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild disabled={disabled}>
        <Button
          variant="outlineDark"
          className="flex items-center justify-center gap-1.5"
        >
          {location ? (
            <>
              {location.lat}, {location.lng}
            </>
          ) : (
            <>
              <MapIcon />
              Selecionar no mapa
            </>
          )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 h-screen w-full -translate-x-1/2 -translate-y-1/2"
        >
          <Map
            center={userLocation}
            setLocation={setLocation}
            setOpen={setOpen}
          />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
