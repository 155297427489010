import { api } from '../lib/axios'

export interface registerModelBody {
  name: string
  description: string
}

export async function registerModel({ name, description }: registerModelBody) {
  await api.post('/models', {
    name,
    description,
  })
}
