import { api } from '../lib/axios'

export interface GetResetPasswordQuery {
  id?: string | null
}

export interface GetResetPasswordResponse {
  reset: {
    expires: string
    user: {
      email: string
    }
  }
}

export async function getResetPassword({ id }: GetResetPasswordQuery) {
  const response = await api.get<GetResetPasswordResponse>('/reset-password', {
    params: {
      id,
    },
  })

  return response.data
}
