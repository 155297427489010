import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Plus, UserPlus, X } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getUsers } from '../../../api/get-users'
import { registerUser } from '../../../api/register-user'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Form/Checkbox'
import * as Input from '../../../components/Form/Input'
import { Toast } from '../../../components/Toast'
import { getRandomNumber } from '../../../lib/randomNumber'
import { queryClient } from '../../../lib/react-query'

const createUserSchema = z.object({
  name: z.string().min(3),
  email: z.string().email(),
  password: z.string().min(8),
  firstAccess: z.boolean(),
})

type CreateUserProps = z.infer<typeof createUserSchema>

export function CreateUserModal() {
  const [searchParams] = useSearchParams()

  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CreateUserProps>({
    resolver: zodResolver(createUserSchema),
  })

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: result } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers({ perPage: 9999, pageIndex: 0 }),
  })

  const randomUser = useMemo(
    () =>
      result ? result.users[getRandomNumber(0, result.meta.totalCount)] : null,
    [result],
  )

  const placeholder = randomUser ? randomUser.name : 'João Pedro'

  const { mutateAsync: registerUserFn } = useMutation({
    mutationKey: ['register-user'],
    mutationFn: registerUser,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['users', pageIndex, perPage] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast t={t} title="Usuário criado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar criar um novo usuário, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateUser(data: CreateUserProps) {
    const { name, email, password, firstAccess } = data

    registerUserFn({
      name,
      email,
      password,
      firstAccess,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
        }
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button variant="primary" className="flex gap-1.5">
          <Plus className="h-5 w-5" /> Criar usuário
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <UserPlus className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Cancel className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Criar Usuário
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Por favor preencha as informações do usuário
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleCreateUser)}>
            <div className="flex flex-col gap-1.5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700 dark:text-gray-375"
              >
                Nome*
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      placeholder={`e.g. ${placeholder}`}
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700 dark:text-gray-375"
              >
                Email*
              </label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      type="email"
                      placeholder="email@raimax.com.br"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700 dark:text-gray-375"
              >
                Senha*
              </label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      type="password"
                      placeholder="••••••••"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex items-center gap-2">
              <Controller
                name="firstAccess"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    id={field.name}
                  />
                )}
              />
              <label
                htmlFor="firstAccess"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Mudar senha no primeiro acesso
              </label>
            </div>
            <div className="mt-8 flex gap-3">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Criar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
