import { ComboboxInput, ComboboxInputProps } from '@headlessui/react'
import { ChevronDown } from 'lucide-react'
import { ChangeEvent } from 'react'

import { useCombobox } from './Root'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Trigger({ ...props }: ComboboxInputProps<'input', any>) {
  const { setQuery, multiple } = useCombobox()

  return (
    <div className="flex w-full items-center justify-between gap-2 rounded-lg border border-gray-200 bg-white px-3.5 py-2 shadow-xs outline-none focus-within:border-purple-400 focus-within:shadow-base dark:border-gray-725 dark:bg-gray-950 dark:text-gray-25 dark:focus-within:border-purple-400">
      <ComboboxInput
        {...props}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setQuery(e.currentTarget.value)
        }
        displayValue={(value) => {
          if (multiple) {
            return ''
          } else {
            if (value) {
              return value.name
            }
          }
        }}
        className="w-full bg-transparent placeholder:text-gray-300 focus:shadow-none dark:placeholder:text-gray-350"
      />
      <ChevronDown className="h-5 w-5 text-gray-150" />
    </div>
  )
}
