import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Shield, User } from 'lucide-react'
import { useParams, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getTicket } from '../../../../api/get-ticket'
import { getTicketParticipants } from '../../../../api/get-ticket-participants'
import { Pagination } from '../../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../components/Table'
import { Tag } from '../../../../components/Tag'
import { Tooltip } from '../../../../components/Tooltip'
import { AddMemberModal } from './add-member-modal'
import { BecomeResponsableModal } from './become-responsable-modal'
import { ParticipantsTableSkeleton } from './participants-table-skeleton'
import { RemoveMemberModal } from './remove-member-modal'

export function Participants() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { ticketId } = useParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: result, isLoading } = useQuery({
    queryKey: ['ticket-participants', perPage, pageIndex, ticketId],
    queryFn: () =>
      getTicketParticipants({
        id: ticketId ?? '',
        pageIndex,
        perPage,
      }),
  })

  const { data: ticketResult } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }
  const allowed =
    ticketResult?.isResponsable ||
    ticketResult?.isCreator ||
    ticketResult?.isLeader

  return (
    <div>
      <div className="mx-4 mb-6 border-b border-gray-100 pb-5 dark:border-gray-750 md:mx-0 md:flex">
        <div>
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Participantes
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie quem pode visualizar este chamado
          </p>
        </div>
        {allowed && <AddMemberModal />}
      </div>

      <div className="mb-6 gap-16 md:mx-0 md:grid md:grid-cols-team">
        <div className="px-4 md:px-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Compartilhado com
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Pessoas que podem ver/comentar neste chamado.
          </p>
        </div>
        <div>
          <Table className="mt-6 md:mt-0">
            <TableHeader>
              <TableHead>Nome</TableHead>
              <TableHead>Equipe</TableHead>
              <TableHead>Última visualização</TableHead>
              <TableHead />
            </TableHeader>
            <TableBody>
              {result &&
                result.users.map((user) => {
                  const responsable =
                    ticketResult?.ticket.responsable.id === user.id
                  const creator = ticketResult?.ticket.createdBy.id === user.id

                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <div
                          className={clsx('flex items-center gap-2', {
                            'font-medium text-gray-900 dark:font-normal dark:text-gray-25':
                              responsable,
                          })}
                        >
                          {user.name}

                          {responsable && (
                            <Tooltip description="Responsável" asChild>
                              <Shield className="h-4 w-4 text-gray-700 dark:text-gray-125" />
                            </Tooltip>
                          )}
                          {creator && (
                            <Tooltip description="Quem criou" asChild>
                              <User className="h-4 w-4 text-gray-700 dark:text-gray-125" />
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-1.5">
                          {user.team.map((team) => (
                            <Tag key={team.id} variant={team.color}>
                              {team.name}
                            </Tag>
                          ))}
                        </div>
                      </TableCell>

                      <TableCell>
                        {user.viewed[0] && (
                          <Tooltip
                            description={format(
                              parseISO(user.viewed[0].updated_at),
                              'HH:mm:ss dd/MM/yyyy',
                            )}
                            asChild
                          >
                            <span>
                              {formatDistanceToNow(
                                parseISO(user.viewed[0].updated_at),
                                {
                                  addSuffix: true,
                                  locale: ptBR,
                                },
                              )}
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        {allowed && !responsable && (
                          <div className="flex justify-end gap-3">
                            <BecomeResponsableModal
                              user={user}
                              responsable={ticketResult?.ticket.responsable}
                              ticket={{
                                id: ticketId ?? '',
                                title: ticketResult?.ticket.title,
                              }}
                            />
                            <RemoveMemberModal
                              user={user}
                              ticket={{
                                id: ticketId ?? '',
                                title: ticketResult?.ticket.title,
                              }}
                            />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {isLoading && <ParticipantsTableSkeleton perPage={8} />}
            </TableBody>
          </Table>
          {result && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={result.meta.pageIndex}
              totalCount={result.meta.totalCount}
              totalInPage={result.users.length}
              perPage={result.meta.perPage}
            />
          )}
        </div>
      </div>
    </div>
  )
}
