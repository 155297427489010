import { api } from '../lib/axios'

export interface TransferResponsabilityTicketQuery {
  id: string
  member: {
    id: string
  }
  responsable: {
    id: string
  }
}

export async function transferResponsabilityTicket({
  id,
  member,
  responsable,
}: TransferResponsabilityTicketQuery) {
  const response = await api.patch(`/tickets/${id}/responsable`, {
    member,
    responsable,
  })

  return response.data
}
