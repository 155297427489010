import { api } from '../lib/axios'

export interface GetTicketFilesQuery {
  id: string
  pageIndex?: number | null
  perPage?: number | null
  type?: string | null
  filename?: string | null
}

export interface GetTicketFilesResponse {
  files: {
    id: string
    filename: string
    size: number
    created_at: string
    createdBy: {
      id: string
      name: string
      avatarUrl: string
      email: string
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getTicketFiles({
  id,
  pageIndex,
  perPage,
  type,
  filename,
}: GetTicketFilesQuery) {
  const response = await api.get<GetTicketFilesResponse>(
    `/tickets/${id}/files`,
    {
      params: {
        pageIndex,
        perPage,
        type,
        filename,
      },
    },
  )

  return response.data
}
