import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { format, isToday, isYesterday, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Send } from 'lucide-react'
import { FormEvent, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'

import { getAuthenticatedUser } from '../../../../api/get-authenticated-user'
import { getTicket } from '../../../../api/get-ticket'
import {
  getTicketMessages,
  GetTicketMessagesResponse,
} from '../../../../api/get-ticket-messages'
import { registerMessage } from '../../../../api/register-message'
import { Button } from '../../../../components/Button'
import { Textarea } from '../../../../components/Form/Textarea'
import { queryClient } from '../../../../lib/react-query'

const messageSchema = z.object({
  text: z.string(),
})

type MessageProps = z.infer<typeof messageSchema>

export function MobileMessages() {
  const { control, handleSubmit, reset } = useForm<MessageProps>({
    resolver: zodResolver(messageSchema),
  })

  const { ticketId } = useParams()

  const { data: result } = useQuery({
    queryKey: ['ticket-messages'],
    queryFn: () =>
      getTicketMessages({
        id: ticketId ?? '',
      }),
  })

  const { data: ticketResult } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  const isFinished = ticketResult?.ticket.status.type === 'finished'

  const { mutateAsync: registerMessageFn } = useMutation({
    mutationKey: ['register-message'],
    mutationFn: registerMessage,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['ticket-messages'] })
      queryClient.refetchQueries({ queryKey: ['ticket-activity', ticketId] })

      reset({
        text: '',
      })
    },
  })

  async function handleSendMessage(data: MessageProps) {
    const { text } = data

    registerMessageFn({
      ticket: {
        id: ticketId ?? '',
      },
      text,
    })
  }

  return (
    <div className="flex max-h-full min-h-screen flex-col">
      {result && <Messages messages={result.messages} />}

      <div className="flex-shrink-0">
        <form
          onSubmit={handleSubmit(handleSendMessage)}
          className="mt-auto flex gap-3 border-t border-gray-100 px-6 py-5 dark:border-gray-750 dark:bg-gray-950"
        >
          <Controller
            name="text"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Textarea
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className="!max-h-[166px]"
                placeholder="Mensagem"
                onInput={(event: FormEvent<HTMLTextAreaElement>) => {
                  event.currentTarget.style.height = 'auto'
                  event.currentTarget.style.height =
                    event.currentTarget.scrollHeight + 'px'
                }}
                disabled={isFinished}
              />
            )}
          />
          <Button
            disabled={isFinished}
            type="submit"
            className="ml-auto self-start p-3"
          >
            <Send className="h-5 w-5" />
          </Button>
        </form>
      </div>
    </div>
  )
}

function Messages({ messages }: GetTicketMessagesResponse) {
  const divRef = useRef<HTMLDivElement>(null)

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  useEffect(() => {
    const scrollToBottom = () => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight
      }
    }

    scrollToBottom()
  }, [])

  return (
    <div
      ref={divRef}
      className="flex-grow overflow-y-auto px-6 pb-8 scrollbar-none dark:bg-gray-950"
    >
      {messages.map((day, index) => {
        return (
          <div key={index}>
            <div className="my-8 flex w-full items-center gap-2">
              <div className="h-px flex-1 bg-gray-100 dark:bg-gray-750" />
              {isToday(parseISO(day[0].created_at)) ? (
                <span className="text-sm font-medium text-gray-600 dark:text-gray-150">
                  Hoje
                </span>
              ) : isYesterday(parseISO(day[0].created_at)) ? (
                <span className="text-sm font-medium text-gray-600 dark:text-gray-150">
                  Ontem
                </span>
              ) : (
                <span className="text-sm font-medium text-gray-600 dark:text-gray-150">
                  {format(
                    parseISO(day[0].created_at),
                    "dd 'de' MMMM 'de' yyyy",
                    {
                      locale: ptBR,
                    },
                  )}
                </span>
              )}
              <div className="h-px flex-1 bg-gray-100 dark:bg-gray-750" />
            </div>
            <div className="space-y-4">
              {day.map((message) => {
                const isAuthor =
                  message.author.id === authenticatedUser?.user.id

                if (isAuthor) {
                  return (
                    <div
                      key={message.id}
                      className="ml-auto w-full max-w-[17rem]"
                    >
                      <div className="flex items-center justify-between gap-2">
                        <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                          Você
                        </p>
                        <span className="text-xs text-gray-600 dark:text-gray-375">
                          {format(new Date(message.created_at), 'eeee HH:mm', {
                            locale: ptBR,
                          })}
                        </span>
                      </div>
                      <div
                        key={message.id}
                        className="mt-1.5 break-words rounded-lg rounded-tr-none bg-purple-600 px-3.5 py-2.5 text-white"
                      >
                        {message.text.split('\n').map((item) => {
                          if (item === '') {
                            return <br key={item} />
                          }

                          if (item.startsWith('http')) {
                            return (
                              <a
                                key={item}
                                href={item}
                                target="_blank"
                                className="text-white underline hover:text-gray-100 focus:shadow-none"
                                rel="noreferrer"
                              >
                                {item}
                              </a>
                            )
                          }

                          return <p key={item}>{item}</p>
                        })}
                      </div>
                    </div>
                  )
                }

                return (
                  <div key={message.id} className="w-full max-w-[17rem]">
                    <div className="flex items-center justify-between gap-2">
                      <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                        {message.author.name}
                      </p>
                      <span className="text-xs text-gray-600 dark:text-gray-375">
                        {format(new Date(message.created_at), 'eeee HH:mm', {
                          locale: ptBR,
                        })}
                      </span>
                    </div>
                    <div
                      key={message.id}
                      className="mt-1.5 break-words rounded-lg rounded-tl-none border border-gray-100 bg-gray-50 px-3.5 py-2.5 text-gray-900 dark:border-gray-750 dark:bg-gray-850 dark:text-white"
                    >
                      {message.text.split('\n').map((item) => {
                        if (item === '') {
                          return <br key={item} />
                        }

                        if (item.startsWith('http')) {
                          return (
                            <a
                              key={item}
                              href={item}
                              target="_blank"
                              className="text-purple-500 underline hover:text-purple-600 focus:shadow-none"
                              rel="noreferrer"
                            >
                              {item}
                            </a>
                          )
                        }

                        return <p key={item}>{item}</p>
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
