import { statusAPI } from '../lib/axios'

export interface UpdateAlertBody {
  title: string
  services: {
    id: string
    name: string
  }[]
  alertId: string
  serviceStatusId: string
  alertStatusId: string
}

export async function updateAlert({
  title,
  services,
  alertId,
  serviceStatusId,
  alertStatusId,
}: UpdateAlertBody) {
  await statusAPI.patch('/alerts', {
    title,
    services,
    alertId,
    serviceStatusId,
    alertStatusId,
  })
}
