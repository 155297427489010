import { statusAPI } from '../lib/axios'

export interface RegisterAlertBody {
  title: string
  services: {
    id: string
  }[]
  alertStatusId: string
  serviceStatusId: string
  message: string
  createdById: string
}

export async function registerAlert({
  title,
  services,
  serviceStatusId,
  alertStatusId,
  message,
  createdById,
}: RegisterAlertBody) {
  await statusAPI.post('/alerts', {
    title,
    services,
    serviceStatusId,
    alertStatusId,
    message,
    createdById,
  })
}
