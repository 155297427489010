import * as Dialog from '@radix-ui/react-dialog'
import * as Popover from '@radix-ui/react-popover'
import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

interface TriggerProps {
  children: ReactNode
}

export function Trigger({ children }: TriggerProps) {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  if (isMobile) {
    return <Dialog.Trigger asChild>{children}</Dialog.Trigger>
  }

  return <Popover.Trigger asChild>{children}</Popover.Trigger>
}
