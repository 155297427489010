import { api } from '../lib/axios'

export interface AddMemberToTicketQuery {
  id: string
  members: {
    id: string
  }[]
}

export async function addMemberToTicket({
  id,
  members,
}: AddMemberToTicketQuery) {
  const response = await api.post(`/tickets/${id}/add`, {
    members,
  })

  return response.data
}
