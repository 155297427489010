export function XLSX() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M7.75 4A3.25 3.25 0 0 1 11 .75h16c.121 0 .238.048.323.134l10.793 10.793a.457.457 0 0 1 .134.323v24A3.25 3.25 0 0 1 35 39.25H11A3.25 3.25 0 0 1 7.75 36V4Z"
        className="dark:stroke-gray-725"
      />
      <path
        stroke="#D0D5DD"
        strokeWidth="1.5"
        d="M27 .5V8a4 4 0 0 0 4 4h7.5"
        className="dark:stroke-gray-725"
      />
      <rect width="33" height="16" x="1" y="18" fill="#079455" rx="2" />
      <path
        fill="#fff"
        d="m6.312 22.727 1.467 2.479h.057l1.473-2.479h1.737l-2.22 3.637L11.096 30H9.326l-1.491-2.482h-.057L6.287 30H4.526l2.276-3.636-2.233-3.637h1.743ZM12.02 30v-7.273h1.537v6.005h3.118V30H12.02Zm9.619-5.181a.899.899 0 0 0-.366-.668c-.215-.158-.508-.238-.877-.238-.25 0-.463.036-.636.107a.894.894 0 0 0-.397.288.692.692 0 0 0-.135.419.599.599 0 0 0 .081.34.854.854 0 0 0 .253.253c.106.068.23.129.369.18.14.05.289.093.447.129l.654.156c.317.07.608.166.873.284.265.118.495.264.69.437.193.172.344.376.45.61.11.235.165.504.167.807-.002.445-.116.83-.34 1.157-.223.325-.545.577-.967.757-.419.177-.924.266-1.516.266-.587 0-1.099-.09-1.534-.27a2.247 2.247 0 0 1-1.016-.799c-.241-.355-.368-.794-.38-1.317h1.488c.017.244.087.447.21.61.125.161.292.283.5.366.211.08.45.12.714.12.26 0 .487-.037.678-.113a1.04 1.04 0 0 0 .452-.316.73.73 0 0 0 .16-.465c0-.163-.05-.3-.146-.412a1.103 1.103 0 0 0-.42-.284 4.273 4.273 0 0 0-.67-.213l-.792-.199c-.614-.15-1.098-.382-1.453-.7-.355-.317-.531-.744-.529-1.282-.002-.44.115-.825.352-1.154.239-.329.567-.585.983-.77.417-.185.89-.277 1.42-.277.54 0 1.012.092 1.414.277.405.184.72.441.945.77.225.33.34.71.348 1.144h-1.474Zm4.05-2.092 1.466 2.479h.057l1.473-2.479h1.737l-2.22 3.637L30.471 30h-1.769l-1.491-2.482h-.057L25.662 30h-1.761l2.276-3.636-2.233-3.637h1.743Z"
      />
    </svg>
  )
}
