import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import * as Tabs from '@radix-ui/react-tabs'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Layers2, X } from 'lucide-react'
import { ReactNode, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import {
  getEnabledTeamsTicketForm,
  GetEnabledTeamsTicketFormResponse,
} from '../../../api/get-enabled-teams-ticket-form'
import { getTeamCategories } from '../../../api/get-team-categories'
import { getUsersTicketForm } from '../../../api/get-users-ticket-form'
import { registerTicket } from '../../../api/register-ticket'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Textarea } from '../../../components/Form/Textarea'
import { Toast } from '../../../components/Toast'
import { Tooltip } from '../../../components/Tooltip'
import { abbreviateName } from '../../../lib/abbreviate'
import { queryClient } from '../../../lib/react-query'
import { MapLocationModal } from './map-location-modal'

interface CreteTicketModal {
  children: ReactNode
}

export const cities = [
  'Baependi',
  'Carmo de Minas',
  'Caxambu',
  'Conceição do Rio Verde',
  'Cruzeiro',
  'Cruzília',
  'Dom Viçoso',
  'Itamonte',
  'Itanhandu',
  'Passa Quatro',
  'Pouso Alto',
  'Soledade de Minas',
  'São Lourenço',
  'São Sebastião do Rio Verde',
  'Três Corações',
  'Virgínia',
]

export const priorities = [
  { value: 'high', name: 'Alta' },
  { value: 'medium', name: 'Média' },
  { value: 'low', name: 'Baixa' },
]

const firstStep = z.object({
  title: z.string().min(1, 'Título é obrigatório'),
  location: z.string().optional(),
  priority: z.enum(['low', 'medium', 'high'], {
    required_error: 'Prioridade é obrigatório',
  }),
})

const secondStep = z.object({
  team: z.object(
    {
      id: z.string().uuid(),
      name: z.string(),
    },
    {
      required_error: 'Equipe é obrigatório',
    },
  ),
  responsable: z.object(
    {
      id: z.string().uuid(),
      name: z.string(),
    },
    {
      required_error: 'Responsável é obrigatório',
    },
  ),
  members: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
    }),
    {
      required_error: 'Membros são obrigatórios',
    },
  ),
})

const thirdStep = z.object({
  category: z.object(
    {
      id: z.string().uuid(),
      description: z.string().nullable(),
      status: z.object({
        id: z.string().uuid(),
      }),
      permissions: z.array(
        z.object({
          name: z.string(),
        }),
      ),
    },
    {
      required_error: 'Categoria é obrigatório',
    },
  ),
  coordinates: z.string().optional(),
  description: z.string().min(1, 'Descrição é obrigatório'),
})

const ticketSchema = thirdStep.and(firstStep).and(secondStep)

type CreateTicketProps = z.infer<typeof ticketSchema>

export function CreateTicketModal({ children }: CreteTicketModal) {
  const [open, setOpen] = useState<boolean>()
  const [tab, setTab] = useState('tab1')

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    watch,
    setValue,
    formState: { isValid, isSubmitting },
  } = useForm<CreateTicketProps>({
    resolver: zodResolver(ticketSchema),
  })

  const [members, team, category, responsable, coordinates] = watch([
    'members',
    'team',
    'category',
    'responsable',
    'coordinates',
  ])

  const isFirstStepCompleted = firstStep.safeParse({
    title: watch('title'),
    location: watch('location'),
    priority: watch('priority'),
  }).success

  const isSecondStepCompleted = secondStep.safeParse({
    team: watch('team'),
    responsable: watch('responsable'),
    members: watch('members'),
  }).success

  function handleNextTab() {
    setTab((state) => {
      const currentTab = Number(state.split('tab')[1])

      return `tab${currentTab + 1}`
    })
  }

  function handlePreviousTab() {
    setTab((state) => {
      const currentTab = Number(state.split('tab')[1])

      return `tab${currentTab - 1}`
    })
  }

  const { data: teamsQuery } = useQuery({
    queryKey: ['teams-ticket-form'],
    queryFn: getEnabledTeamsTicketForm,
  })

  const { data: usersQuery } = useQuery({
    queryKey: ['users-ticket-form'],
    queryFn: getUsersTicketForm,
  })

  const { data: categoriesQuery } = useQuery({
    queryKey: ['categories', team],
    queryFn: () => getTeamCategories({ teamId: team.id }),
  })

  const { mutateAsync: registerTicketFn } = useMutation({
    mutationKey: ['register-ticket'],
    mutationFn: registerTicket,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['tickets'] })
      setOpen(false)
      setTab('tab1')
      toast.custom((t) => (
        <Toast
          t={t}
          title="Chamado registrado com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar registrar o chamado, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateTicketSubmit(data: CreateTicketProps) {
    const {
      title,
      location,
      priority,
      team,
      responsable,
      members,
      category,
      description,
      coordinates,
    } = data

    registerTicketFn({
      title,
      location,
      priority,
      team,
      responsable,
      members,
      category,
      description,
      coordinates,
    })
  }

  const isCoordinatesRequired =
    category &&
    category.permissions.some(
      (permission) => permission.name === 'fillLocation',
    )

  useEffect(() => {
    if (!category) return

    if (category.description) {
      setValue('description', category.description)
    } else {
      setValue('description', '')
    }
  }, [category, setValue])

  useEffect(() => {
    if (!responsable) return

    if (!members) {
      setValue('members', [responsable])
      return
    }

    const hadResponsable = members.some(
      (member) => member.id === responsable.id,
    )

    if (!hadResponsable) {
      setValue('members', [...members, responsable])
    }
  }, [responsable])

  useEffect(() => {
    if (!team) return

    const teamWithPermissions = teamsQuery!.teams.find(
      (item) => item.id === team.id,
    ) as GetEnabledTeamsTicketFormResponse['teams'][number]

    if (
      teamWithPermissions.permissions.some(
        (permission) => permission.name === 'autoFillMembersOnTicketForm',
      )
    ) {
      setValue('members', teamWithPermissions.users)
    }
  }, [team])

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
          setTab('tab1')
        }
        reset()
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <Layers2 className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Criar Chamado
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Descreva as informações do chamado abaixo
          </Dialog.Description>
          <Tabs.Root
            tabIndex={-1}
            value={tab}
            onValueChange={setTab}
            className="focus:shadow-none"
          >
            <form
              className="mt-5"
              onSubmit={handleSubmit(handleCreateTicketSubmit)}
            >
              <Tabs.Content
                tabIndex={-1}
                value="tab1"
                className="flex flex-col gap-4 focus:shadow-none"
              >
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Título*
                  </label>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input.Root>
                        <Input.Control
                          {...field}
                          placeholder="Título do chamado"
                        />
                      </Input.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Cidade
                  </label>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <Select.Trigger>
                          <Select.Value placeholder="Procure pela cidade" />
                        </Select.Trigger>

                        <Select.Content>
                          <Select.Item value="all">
                            <Select.ItemText>Todas</Select.ItemText>
                          </Select.Item>
                          {cities.map((city) => (
                            <Select.Item key={city} value={city}>
                              <Select.ItemText>{city}</Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Prioridade*
                  </label>
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <Select.Trigger>
                          <Select.Value placeholder="Selecione a prioridade" />
                        </Select.Trigger>

                        <Select.Content>
                          {priorities.map(({ value, name }) => (
                            <Select.Item key={value} value={value}>
                              <Select.ItemText>{name}</Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </div>
              </Tabs.Content>
              <Tabs.Content
                tabIndex={-1}
                value="tab2"
                className="flex flex-col gap-4 focus:shadow-none"
              >
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Equipe*
                  </label>
                  <Controller
                    name="team"
                    control={control}
                    render={({ field }) => (
                      <Combobox.Root {...field}>
                        <Combobox.Trigger placeholder="Selecione a equipe" />

                        <Combobox.Content>
                          {teamsQuery &&
                            teamsQuery.teams.map((team) => (
                              <Combobox.Option key={team.id} value={team}>
                                {team.name}
                              </Combobox.Option>
                            ))}
                        </Combobox.Content>
                      </Combobox.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Responsável*
                  </label>
                  <Controller
                    name="responsable"
                    control={control}
                    render={({ field }) => (
                      <Combobox.Root {...field}>
                        <Combobox.Trigger placeholder="Selecione o responsável" />

                        <Combobox.Content>
                          {usersQuery &&
                            usersQuery.users.map((user) => (
                              <Combobox.Option key={user.id} value={user}>
                                {user.name}
                              </Combobox.Option>
                            ))}
                        </Combobox.Content>
                      </Combobox.Root>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Participantes*
                  </label>
                  <Controller
                    name="members"
                    control={control}
                    render={({ field }) => (
                      <Combobox.Root {...field} multiple>
                        <Combobox.Trigger placeholder="Selecione os usuários" />

                        <Combobox.Content>
                          {usersQuery &&
                            usersQuery.users.map((user) => (
                              <Combobox.Option key={user.id} value={user}>
                                {user.name}
                              </Combobox.Option>
                            ))}
                        </Combobox.Content>
                      </Combobox.Root>
                    )}
                  />
                  <div className="mt-2 flex flex-wrap">
                    {members
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((member) => (
                        <Tooltip
                          key={member.id}
                          description={member.name}
                          asChild
                        >
                          <div
                            onClick={() => {
                              const array = members.filter(
                                (user) => user.id !== member.id,
                              )

                              if (array.length === 0) {
                                resetField('members')
                              } else {
                                setValue('members', array)
                              }
                            }}
                            className="!first:ml-0 -ml-1 flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border border-white bg-purple-600 text-sm font-semibold text-white transition-colors hover:bg-purple-700 dark:border-gray-950"
                          >
                            {abbreviateName(member.name)}
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
              </Tabs.Content>
              <Tabs.Content
                tabIndex={-1}
                value="tab3"
                className="flex flex-col gap-4 focus:shadow-none"
              >
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Categoria*
                  </label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <Combobox.Root {...field}>
                        <Combobox.Trigger placeholder="Selecione a categoria" />

                        <Combobox.Content>
                          {categoriesQuery &&
                            categoriesQuery.categories.map((category) => (
                              <Combobox.Option
                                key={category.id}
                                value={category}
                              >
                                {category.name}
                              </Combobox.Option>
                            ))}
                        </Combobox.Content>
                      </Combobox.Root>
                    )}
                  />
                </div>
                {isCoordinatesRequired && (
                  <div className="flex flex-col gap-1.5">
                    <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                      Localização*
                    </label>
                    <Controller
                      name="coordinates"
                      control={control}
                      render={({ field }) => (
                        <MapLocationModal
                          value={field.value}
                          onValueChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                )}
                <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                    Descrição*
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <Textarea
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="Descrição do chamado"
                      />
                    )}
                  />
                </div>
              </Tabs.Content>
              <Tabs.List className="mt-5 flex items-center justify-center gap-4 focus:shadow-none">
                <Tabs.Trigger asChild value="tab1">
                  <div className="h-2.5 w-2.5 cursor-pointer rounded-full bg-gray-100 data-[state=active]:bg-purple-600 dark:bg-gray-725 dark:data-[state=active]:bg-gray-125" />
                </Tabs.Trigger>
                <Tabs.Trigger
                  asChild
                  value="tab2"
                  disabled={!isFirstStepCompleted}
                >
                  <div className="h-2.5 w-2.5 cursor-pointer rounded-full bg-gray-100 data-[state=active]:bg-purple-600 dark:bg-gray-725 dark:data-[state=active]:bg-gray-125" />
                </Tabs.Trigger>
                <Tabs.Trigger
                  asChild
                  value="tab3"
                  disabled={!isSecondStepCompleted}
                >
                  <div className="h-2.5 w-2.5 cursor-pointer rounded-full bg-gray-100 data-[state=active]:bg-purple-600 dark:bg-gray-725 dark:data-[state=active]:bg-gray-125" />
                </Tabs.Trigger>
              </Tabs.List>
              <Tabs.Content
                value="tab1"
                tabIndex={-1}
                className="focus:shadow-none"
              >
                <div className="mt-6 flex flex-col-reverse gap-3 md:flex-row">
                  <Dialog.Cancel asChild>
                    <Button variant="outline" className="flex-1" type="button">
                      Cancelar
                    </Button>
                  </Dialog.Cancel>
                  <Button
                    className="flex-1"
                    variant="primary"
                    onClick={handleNextTab}
                    type="button"
                    disabled={!isFirstStepCompleted}
                  >
                    Próximo
                  </Button>
                </div>
              </Tabs.Content>
              <Tabs.Content
                value="tab2"
                tabIndex={-1}
                className="focus:shadow-none"
              >
                <div className="mt-6 flex flex-col-reverse gap-3 md:flex-row">
                  <Button
                    variant="outline"
                    className="flex-1"
                    onClick={handlePreviousTab}
                    type="button"
                  >
                    Voltar
                  </Button>
                  <Button
                    className="flex-1"
                    variant="primary"
                    onClick={handleNextTab}
                    type="button"
                    disabled={!isSecondStepCompleted}
                  >
                    Próximo
                  </Button>
                </div>
              </Tabs.Content>
              <Tabs.Content
                value="tab3"
                tabIndex={-1}
                className="focus:shadow-none"
              >
                <div className="mt-6 flex flex-col-reverse gap-3 md:flex-row">
                  <Button
                    variant="outline"
                    className="flex-1"
                    onClick={() => setTab('tab2')}
                    type="button"
                  >
                    Voltar
                  </Button>
                  <Button
                    type="submit"
                    className="flex-1"
                    variant="primary"
                    disabled={
                      isCoordinatesRequired
                        ? !isValid || !coordinates || isSubmitting
                        : !isValid || isSubmitting
                    }
                  >
                    Criar
                  </Button>
                </div>
              </Tabs.Content>
            </form>
          </Tabs.Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
