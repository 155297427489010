import * as RadioGroup from '@radix-ui/react-radio-group'
import { useQuery } from '@tanstack/react-query'
import { Flag } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getAuthenticatedUser } from '../../../api/get-authenticated-user'
import { getCategories } from '../../../api/get-categories'
import { getRoles } from '../../../api/get-roles'
import { getTeamsMostCategories } from '../../../api/get-teams-most-categories'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { CategoriesTableSkeleton } from './categories-table-skeleton'
import { CreateCategoryModal } from './create-category-modal'
import { DeleteCategoryModal } from './delete-category-modal'
import { EditCategoryModal } from './edit-category-modal'
import { TopThreeTeamsSkeleton } from './top-three-teams-skeleton'

export function Categories() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTeam, setSelectedTeam] = useState(
    searchParams.get('teamId') ?? undefined,
  )

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '8')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: topThreeTeams, isLoading: isLoadingTopThreeTeams } = useQuery({
    queryKey: ['teams-most-categories'],
    queryFn: getTeamsMostCategories,
  })

  const { data: result, isLoading: isLoadingCategories } = useQuery({
    queryKey: ['categories', pageIndex, perPage, selectedTeam],
    queryFn: () =>
      getCategories({
        type: 'tickets',
        pageIndex,
        perPage,
        teamId: selectedTeam || null,
      }),
  })

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  const isLeaderOrAdmin = roles?.isAdmin || roles?.leaderOnTeams.length

  useEffect(() => {
    if (!selectedTeam) return

    setSearchParams((state) => {
      state.set('teamId', selectedTeam)
      state.set('page', '1')

      return state
    })
  }, [selectedTeam])

  return (
    <>
      <div className="mx-4 mb-6 mt-8 border-b border-gray-100 pb-5 dark:border-gray-750 md:mx-0 md:flex">
        <div>
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Categorias
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie as categorias de chamados da sua equipe.
          </p>
        </div>

        {isLeaderOrAdmin && (
          <div className="ml-auto mt-4 flex gap-3 md:mt-0 md:self-start">
            <CreateCategoryModal />
          </div>
        )}
      </div>
      <div className="mx-4 md:mx-0 md:mb-6 md:grid md:grid-cols-2 md:border-b md:border-gray-100 md:pb-6 dark:md:border-gray-750">
        <div className="pt-3">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Equipes
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Suas equipes que mais utilizam categorias.
          </p>
        </div>
        <RadioGroup.Root
          value={selectedTeam}
          onValueChange={setSelectedTeam}
          className="mt-5 flex flex-col gap-3 focus:shadow-none"
        >
          {topThreeTeams &&
            topThreeTeams.teams?.map((team) => (
              <RadioGroup.Item
                key={team.id}
                value={team.id}
                className="flex flex-1 cursor-pointer items-center gap-3 rounded-lg border border-gray-100 bg-gray-50 p-4 data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-50 focus:shadow-none dark:border-gray-750 dark:bg-gray-850 dark:data-[state=checked]:border-purple-300 dark:data-[state=checked]:bg-purple-900"
                onClick={(e) => {
                  if (selectedTeam === e.currentTarget.value) {
                    setSearchParams((state) => {
                      state.delete('teamId')

                      return state
                    })
                    setSelectedTeam('')
                  }
                }}
              >
                <div className="flex items-center justify-center rounded-full bg-purple-100 p-2 dark:bg-purple-900">
                  <Flag className="h-4 w-4 text-purple-600 dark:text-purple-300" />
                </div>
                <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  {team.name}
                </p>
              </RadioGroup.Item>
            ))}
          {isLoadingTopThreeTeams && <TopThreeTeamsSkeleton />}
        </RadioGroup.Root>
      </div>
      <div className="mb-6 pt-8 md:mx-0 md:grid md:grid-cols-team md:gap-16 md:pt-0">
        <div className="px-4 md:px-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Categorias compartilhadas
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie as categorias compartilhadas com você.
          </p>
        </div>
        <div>
          <Table className="mt-6 md:mt-0">
            <TableHeader>
              <TableHead>Nome</TableHead>
              <TableHead>SLA</TableHead>
              <TableHead>Chamados</TableHead>
              <TableHead>Equipe</TableHead>
              <TableHead />
            </TableHeader>
            <TableBody>
              {result &&
                result.categories.map((category) => {
                  const isLeader = roles?.leaderOnTeams.some(
                    (team) => team.id === category.team.id,
                  )

                  const isCreator =
                    category.createdById === authenticatedUser?.user.id

                  const isLeaderOrCreator = isLeader || isCreator

                  return (
                    <TableRow key={category.id}>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>
                        <Tag>{category.sla}</Tag>
                      </TableCell>
                      <TableCell>
                        <Tag>{category.tickets}</Tag>
                      </TableCell>
                      <TableCell>
                        {category.team ? (
                          <Tag variant={category.team.color}>
                            {category.team.name}
                          </Tag>
                        ) : (
                          <Tag>none</Tag>
                        )}
                      </TableCell>
                      <TableCell>
                        {isLeaderOrCreator && (
                          <div className="flex justify-end gap-3">
                            <EditCategoryModal category={category} />
                            <DeleteCategoryModal category={category} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {isLoadingCategories && (
                <CategoriesTableSkeleton perPage={perPage} />
              )}
            </TableBody>
          </Table>
          {result && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={result.meta.pageIndex}
              perPage={result.meta.perPage}
              totalCount={result.meta.totalCount}
              totalInPage={result.categories.length}
            />
          )}
        </div>
      </div>
    </>
  )
}
