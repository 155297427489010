import { api } from '../lib/axios'

interface GetCategoriesQuery {
  pageIndex: number
  perPage: number
  type: 'tickets' | 'incidents'
  teamId?: string | null
}

export interface GetCategoriesResponse {
  categories: {
    id: string
    name: string
    description?: string
    sla: number
    createdById: string
    permissions: {
      name: string
    }[]
    status: {
      id: string
      name: string
    }
    team: {
      id: string
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }
    tickets: number
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getCategories({
  type,
  pageIndex,
  perPage,
  teamId,
}: GetCategoriesQuery) {
  const response = await api.get<GetCategoriesResponse>('/categories', {
    params: {
      type,
      pageIndex,
      perPage,
      teamId,
    },
  })

  return response.data
}
