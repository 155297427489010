/* eslint-disable camelcase */
import { useQuery } from '@tanstack/react-query'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useParams } from 'react-router-dom'

import { getTicket } from '../../../../api/get-ticket'
import { getTicketActivities } from '../../../../api/get-ticket-activities'
import { Avatar } from '../../../../components/Avatar'
import { IconFile } from '../../../../components/IconFile'
import { Tag } from '../../../../components/Tag'
import { Tooltip } from '../../../../components/Tooltip'
import { convertBytes } from '../../../../lib/convertBytes'
import { ActivitySkeleton } from './activity-skeleton'

export function Activity() {
  const { ticketId } = useParams()

  const { data: ticketResult } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  const {
    data: result,
    isLoading,
    isLoadingError,
  } = useQuery({
    queryKey: ['ticket-activity', ticketId],
    queryFn: () => getTicketActivities({ id: ticketId ?? '' }),
  })

  return (
    <div className="mt-2 max-h-[70vh] space-y-4 divide-y divide-gray-100 overflow-y-auto scroll-smooth dark:divide-gray-750">
      {result &&
        result.activities
          .sort((a, b) => b.created_at.localeCompare(a.created_at))
          .map((activity) => {
            const {
              id,
              createdBy,
              created_at,
              action,
              type,
              file,
              title,
              oldTitle,
              description,
              oldLocation,
              location,
              oldPriority,
              priority,
              status,
              oldStatus,
              category,
              oldCategory,
              user,
              oldUser,
              team,
              oldTeam,
              coordinates,
            } = activity

            const addFile = action === 'ADD' && type === 'FILE'
            const addComment = action === 'ADD' && type === 'MESSAGE'
            const createdTicket = action === 'CREATE' && type === 'TICKET'
            const editTicketTitle =
              action === 'EDIT' && type === 'TICKET' && title
            const editTicketTeam =
              action === 'EDIT' && type === 'TICKET' && team
            const editTicketDescription =
              action === 'EDIT' && type === 'TICKET' && description
            const editTicketLocation =
              action === 'EDIT' &&
              type === 'TICKET' &&
              (location || oldLocation)
            const editTicketPriority =
              action === 'EDIT' && type === 'TICKET' && priority
            const editTicketStatus =
              action === 'EDIT' && type === 'TICKET' && status
            const editTicketCategory =
              action === 'EDIT' && type === 'TICKET' && category
            const editTicketCoordinates =
              action === 'EDIT' && type === 'TICKET' && coordinates
            const addMember = action === 'ADD' && type === 'MEMBER'
            const removeMember = action === 'DELETE' && type === 'MEMBER'
            const editResponsable = action === 'EDIT' && type === 'RESPONSABLE'

            return (
              <div key={id} className="flex items-start gap-3 pt-4">
                <div>
                  <Avatar
                    name={createdBy.name}
                    avatarUrl={createdBy.avatarUrl}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-1.5">
                    <h1 className="text-sm font-medium text-gray-700 dark:text-gray-125">
                      {createdBy.name}
                    </h1>
                    <Tooltip
                      description={format(
                        parseISO(created_at),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                      asChild
                    >
                      <span className="text-xs text-gray-600 dark:text-gray-150">
                        {formatDistanceToNow(new Date(created_at), {
                          addSuffix: true,
                          locale: ptBR,
                        })}
                      </span>
                    </Tooltip>
                  </div>
                  {addFile ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Adicionou um arquivo ao{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {ticketResult?.ticket.title}
                      </span>
                      {file && (
                        <div className="mt-3 flex gap-3">
                          <IconFile filename={file.filename} />
                          <div>
                            <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                              {file.filename}
                            </p>
                            <span className="text-sm text-gray-600 dark:text-gray-150">
                              {convertBytes(file.size)}
                            </span>
                          </div>
                        </div>
                      )}
                    </p>
                  ) : createdTicket ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Criou{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {title || ticketResult?.ticket.title}
                      </span>
                    </p>
                  ) : addComment ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Comentou no{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {ticketResult?.ticket.title}
                      </span>
                    </p>
                  ) : editTicketTitle ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Alterou o título de{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {oldTitle}
                      </span>{' '}
                      para{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {title}
                      </span>
                    </p>
                  ) : editTicketDescription ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Editou a descrição de{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {ticketResult?.ticket.title}
                      </span>
                    </p>
                  ) : editTicketCoordinates ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Alterou a localização de{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {ticketResult?.ticket.title}
                      </span>
                    </p>
                  ) : editTicketLocation ? (
                    <div className="flex flex-wrap gap-1.5 truncate overflow-ellipsis text-sm text-gray-600 dark:text-gray-150">
                      Alterou a localização de{' '}
                      <Tag>{oldLocation ?? 'Nenhuma'}</Tag> para{' '}
                      <Tag>{location ?? 'Nenhuma'}</Tag>
                    </div>
                  ) : editTicketPriority ? (
                    <div className="flex flex-wrap gap-1.5 truncate overflow-ellipsis text-sm text-gray-600 dark:text-gray-150">
                      Alterou a prioridade de{' '}
                      <Tag
                        variant={
                          oldPriority === 'high'
                            ? 'red'
                            : oldPriority === 'medium'
                              ? 'yellow'
                              : 'blue'
                        }
                      >
                        {oldPriority === 'high'
                          ? 'Alta'
                          : oldPriority === 'medium'
                            ? 'Média'
                            : 'Baixa'}
                      </Tag>
                      para{' '}
                      <Tag
                        variant={
                          priority === 'high'
                            ? 'red'
                            : priority === 'medium'
                              ? 'yellow'
                              : 'blue'
                        }
                      >
                        {priority === 'high'
                          ? 'Alta'
                          : priority === 'medium'
                            ? 'Média'
                            : 'Baixa'}
                      </Tag>
                    </div>
                  ) : editTicketStatus ? (
                    <p className="flex flex-wrap gap-1.5 truncate overflow-ellipsis text-sm text-gray-600 dark:text-gray-150">
                      Alterou o status de{' '}
                      <Tag
                        variant={
                          oldStatus.type === 'wait'
                            ? 'yellow'
                            : oldStatus.type === 'analysis'
                              ? 'purple'
                              : 'green'
                        }
                      >
                        {oldStatus.name}
                      </Tag>
                      para{' '}
                      <Tag
                        variant={
                          status.type === 'wait'
                            ? 'yellow'
                            : status.type === 'analysis'
                              ? 'purple'
                              : 'green'
                        }
                      >
                        {status.name}
                      </Tag>
                    </p>
                  ) : editTicketCategory ? (
                    <div className="flex flex-wrap gap-1.5 truncate overflow-ellipsis text-sm text-gray-600 dark:text-gray-150">
                      Alterou a categoria de <Tag>{oldCategory.name}</Tag>
                      para <Tag>{category.name}</Tag>
                    </div>
                  ) : addMember ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Adicionou{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {user.name}
                      </span>
                    </p>
                  ) : removeMember ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Removeu{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {user.name}
                      </span>
                    </p>
                  ) : editResponsable ? (
                    <p className="text-sm text-gray-600 dark:text-gray-150">
                      Alterou o responsável de{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {oldUser.name}
                      </span>{' '}
                      para{' '}
                      <span className="font-medium text-purple-700 dark:text-gray-125">
                        {user.name}
                      </span>
                    </p>
                  ) : editTicketTeam ? (
                    <div className="flex flex-wrap gap-1.5 truncate overflow-ellipsis text-sm text-gray-600 dark:text-gray-150">
                      Alterou a equipe de{' '}
                      <Tag variant={oldTeam.color || 'purple'}>
                        {oldTeam.name}
                      </Tag>
                      para{' '}
                      <Tag variant={team.color || 'purple'}>{team.name}</Tag>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          })}
      {(isLoading || isLoadingError) && <ActivitySkeleton />}
    </div>
  )
}
