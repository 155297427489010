import * as Dialog from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Cog,
  DoorOpen,
  Layers,
  Moon,
  Search,
  ServerOff,
  Sun,
  TriangleAlert,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { getAuthenticatedUser } from '../../api/get-authenticated-user'
import { getRoles } from '../../api/get-roles'
import { signOut } from '../../api/sign-out'
import { Raimax } from '../../assets/raimax'
import { Avatar } from '../../components/Avatar'
import * as Input from '../../components/Form/Input'
import { Skeleton } from '../../components/Skeleton'
import { useTheme } from '../../components/ThemeProvider'
import { queryClient } from '../../lib/react-query'
import { Item, ItemProps } from './sidebar-item'

export function MobileSideBar() {
  const navigate = useNavigate()

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  const { mutateAsync: signOutFn } = useMutation({
    mutationKey: ['sign-out'],
    mutationFn: signOut,
    onSuccess: () => {
      navigate('/', {
        replace: true,
      })
      queryClient.resetQueries()
    },
  })

  const { theme, setTheme } = useTheme()

  function handleToggleTheme() {
    if (theme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }

  function handleSignOut() {
    signOutFn()
  }

  const navDisplay: {
    icon: JSX.Element
    title: string
    path: string[]
    subpath?: string
    isDropdown?: boolean
    children?: ItemProps[]
    target?: string
  }[] = [
    // {
    //   icon: <BarChart3 />,
    //   title: 'Dashboard',
    //   path: ['/dashboard'],
    //   isDropdown: true,
    //   children: [],
    // },
    {
      icon: <ServerOff />,
      title: 'Incidentes',
      path: ['/incidents'],
      children: [],
    },
    {
      icon: <Layers />,
      title: 'Chamados',
      path: ['/tickets', '/my'],
    },
    // {
    //   icon: <PieChart />,
    //   title: 'Relatórios',
    //   path: ['/reports'],
    //   isDropdown: true,
    //   children: [
    //     {
    //       icon: <Layers />,
    //       title: 'Chamados',
    //       path: ['/reports', '/tickets'],
    //       isDropdown: false,
    //     },
    //   ],
    // },
  ]

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const editModels = roles?.teams.some((team) =>
    team.permissions.some(
      (permission) => permission.name === 'editIncidentModels',
    ),
  )

  if (editModels) {
    navDisplay[0].isDropdown = true
    navDisplay[0].children?.push(
      {
        icon: <ServerOff />,
        title: 'Geral',
        path: ['/incidents'],
      },
      {
        icon: <Cog />,
        title: 'Configurações',
        path: ['/incidents/settings'],
      },
    )
  }

  const statusPage = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'statusPage'),
  )

  if (statusPage) {
    navDisplay.splice(2, 0, {
      icon: <TriangleAlert />,
      title: 'Status',
      path: ['/services', '/alerts'],
    })
  }

  // const sales = roles?.teams.some((team) =>
  //   team.permissions.some((permission) => permission.name === 'sales'),
  // )

  // const dashboard = navDisplay.find((item) => item.title === 'Dashboard')
  // const reports = navDisplay.find((item) => item.title === 'Relatórios')

  // if (sales) {
  //   navDisplay.splice(3, 0, {
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/sales', '/list'],
  //   })
  //   dashboard?.children?.push({
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/dashboard', '/sales', '/my'],
  //     isDropdown: false,
  //   })
  //   reports?.children?.push({
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/reports', '/sales'],
  //     isDropdown: false,
  //   })
  // }

  return (
    <Dialog.Root>
      <header className="flex items-center border-b border-gray-100 py-3 pl-4 pr-2 dark:border-gray-750 dark:bg-gray-950 md:hidden">
        <Raimax />

        <Dialog.Trigger asChild>
          <button className="ml-auto p-2 focus:shadow-none">
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12h12M3 6h18M3 18h18"
                className="stroke-gray-125"
              />
            </svg>
          </button>
        </Dialog.Trigger>
      </header>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed bottom-0 left-0 top-0 flex h-auto w-full max-w-xs flex-col gap-5 overflow-y-auto border-gray-100 bg-white py-6 dark:border-gray-750 dark:bg-gray-950 md:relative md:w-auto md:border-r"
        >
          <div className="px-5">
            <Raimax />
          </div>
          <Input.Root className="mx-4">
            <Input.Prefix>
              <Search className="h-5 w-5 text-gray-500 dark:text-gray-375" />
            </Input.Prefix>
            <Input.Control placeholder="Procurar" />
          </Input.Root>
          <nav className="flex flex-col gap-1 px-4">
            {navDisplay.map((item) => (
              <Item
                key={item.path.join('')}
                icon={item.icon}
                title={item.title}
                path={item.path}
                subpath={item.subpath}
                isDropdown={item.isDropdown}
                sub={item.children || []}
              />
            ))}
          </nav>
          <footer className="mx-4 mt-auto flex flex-col">
            <div className="flex flex-col gap-1">
              <button
                onClick={handleToggleTheme}
                className="group flex w-full items-center gap-2 rounded-md px-3 py-2 font-semibold text-gray-700 transition-colors hover:bg-gray-50 hover:text-gray-900 dark:text-gray-150 dark:hover:bg-gray-750 dark:hover:text-gray-75"
              >
                {theme === 'dark' ? (
                  <Moon className="text-gray-300 transition-colors group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75" />
                ) : (
                  <Sun className="text-gray-300 transition-colors group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75" />
                )}
                {`Tema ${theme === 'dark' ? 'Escuro' : 'Claro'}`}
              </button>
              <Item
                icon={<Cog />}
                title="Configurações"
                path={['/settings', '/teams']}
              />
              <button
                onClick={handleSignOut}
                className="group flex items-center gap-2 rounded-md px-3 py-2 font-semibold text-gray-700 transition-colors hover:bg-gray-50 hover:text-gray-900 dark:text-gray-150 dark:hover:bg-gray-750 dark:hover:text-gray-75"
              >
                <DoorOpen className="text-gray-300 transition-colors group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75" />
                Sair
              </button>
            </div>

            <div className="mt-6 flex items-start border-t border-gray-100 pr-8 pt-6 dark:border-gray-750">
              <div className="flex items-center gap-3 truncate">
                <Avatar
                  name={authenticatedUser?.user.name || ''}
                  avatarUrl={authenticatedUser?.user.avatarUrl}
                />
                {authenticatedUser ? (
                  <div className="flex flex-col">
                    <p
                      className="truncate text-sm font-semibold text-gray-700 dark:text-gray-125"
                      title={authenticatedUser?.user.name}
                    >
                      {authenticatedUser?.user.name}
                    </p>
                    <span
                      className="truncate text-sm text-gray-600 dark:text-gray-375"
                      title={authenticatedUser?.user.email}
                    >
                      {authenticatedUser?.user.email}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <Skeleton className="h-3.5 w-24 rounded-md" />
                    <Skeleton className="h-3 w-40 rounded-md" />
                  </div>
                )}
              </div>
            </div>
          </footer>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
