import { api } from '../lib/axios'

export interface registerIncidentBody {
  title: string
  city: string
  category: {
    id: string
  }
  term?: string
  script: string
  level: string
  modelId?: string
}

export async function registerIncident({
  title,
  city,
  category,
  term,
  script,
  level,
  modelId,
}: registerIncidentBody) {
  await api.post('/incidents', {
    title,
    city,
    category,
    term,
    script,
    level,
    modelId,
  })
}
