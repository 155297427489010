import { statusAPI } from '../lib/axios'

interface GetGroupsResponse {
  groups: {
    id: string
    name: string
  }[]
}

export async function getGroups() {
  const response = await statusAPI.get<GetGroupsResponse>('/groups')

  return response.data
}
