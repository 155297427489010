import { statusAPI } from '../lib/axios'

export interface GetAlertsQuery {
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetAlertsResponse {
  alerts: {
    id: string
    title: string
    created_at: string
    services: {
      id: string
      name: string
      statusId: string
    }[]
    status: {
      id: string
      name: string
      type: string
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getAlerts({ pageIndex, perPage }: GetAlertsQuery) {
  const response = await statusAPI.get<GetAlertsResponse>('/alerts', {
    params: {
      pageIndex,
      perPage,
    },
  })

  return response.data
}
