import { api } from '../lib/axios'

export interface GetIncidentsPerCityQuery {
  from?: Date | null
  to?: Date | null
}

export interface GetIncidentsPerCityMetricResponse {
  incidentsPerCity: {
    city: string
    count: number
  }[]
}

export async function getIncidentsPerCityMetric({
  from,
  to,
}: GetIncidentsPerCityQuery) {
  const response = await api.get<GetIncidentsPerCityMetricResponse>(
    '/metrics/incidents-per-city',
    {
      params: {
        from,
        to,
      },
    },
  )

  return response.data
}
