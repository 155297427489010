import { Moon, Sun } from 'lucide-react'
import { useMediaQuery } from 'react-responsive'

import { Button } from './Button'
import { useTheme } from './ThemeProvider'
import { Tooltip } from './Tooltip'

export function ThemeToggle() {
  const { theme, setTheme } = useTheme()

  function handleToggleTheme() {
    if (theme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }

  const isMobile = useMediaQuery({ maxWidth: 768 })

  if (isMobile) {
    return null
  }

  return (
    <Tooltip
      side="left"
      description={`Tema ${theme === 'dark' ? 'Escuro' : 'Claro'}`}
      asChild
    >
      <Button
        onClick={handleToggleTheme}
        variant="ghost"
        className="flex h-12 w-12 items-center justify-center p-0"
      >
        {theme === 'dark' ? (
          <Moon className="h-5 w-5" />
        ) : (
          <Sun className="h-5 w-5" />
        )}
      </Button>
    </Tooltip>
  )
}
