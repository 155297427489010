import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation } from '@tanstack/react-query'
import { AlertCircle, Plus, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { registerModel } from '../../../api/register-model'
import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import { Textarea } from '../../../components/Form/Textarea'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

const CreateModelSchema = z.object({
  name: z.string(),
  description: z.string(),
})

export type CreateModelProps = z.infer<typeof CreateModelSchema>

export function CreateModelModal() {
  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CreateModelProps>({
    resolver: zodResolver(CreateModelSchema),
  })

  const { mutateAsync: registerModelFn } = useMutation({
    mutationKey: ['register-model'],
    mutationFn: registerModel,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['incident-models'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast t={t} title="Modelo criado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar criar esse modelo, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateModel(data: CreateModelProps) {
    const { name, description } = data

    registerModelFn({
      name,
      description,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
        }

        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="flex items-center gap-1.5">
          <Plus className="h-5 w-5" />
          Criar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <AlertCircle className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Criar modelo
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Insira o nome e script para o modelo
          </Dialog.Description>
          <form
            className="mt-5 flex flex-col gap-4"
            onSubmit={handleSubmit(handleCreateModel)}
          >
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Nome*
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      placeholder="e.g. Manutenção Preventiva"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Script*
              </label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    // eslint-disable-next-line no-template-curly-in-string
                    placeholder="e.g. utilize ${prazo} para incluir o prazo do incidente."
                  />
                )}
              />
            </div>
            <div className="mt-4 flex flex-col-reverse gap-3 md:flex-row">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1" type="button">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Criar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
