import { z } from 'zod'

const envSchema = z.object({
  VITE_NODE_ENV: z.enum(['production', 'development']),
  VITE_API_BASE_URL: z.string(),
  VITE_STATUS_API_BASE_URL: z.string(),
  VITE_WEBSOCKET_URL: z.string(),
  VITE_ENABLE_API_DELAY: z.string().transform((value) => value === 'true'),
  VITE_GOOGLE_MAPS_API: z.string(),
  VITE_IXC_BASE_URL: z.string(),
  VITE_IXC_API_TOKEN: z.string(),
})

export const env = envSchema.parse(import.meta.env)
