import { Skeleton } from '../../../components/Skeleton'
import { TableCell, TableRow } from '../../../components/Table'

interface ServicesTableSkeletonProps {
  perPage: number
}

export function ServicesTableSkeleton({ perPage }: ServicesTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[124px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[182px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[80px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent" />
        </TableRow>
      ))}
    </>
  )
}
