import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { LoaderCircle } from 'lucide-react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getRoles } from '../../api/get-roles'
import { signIn } from '../../api/sign-in'
import { Mockup } from '../../assets/mockup'
import { Raimax } from '../../assets/raimax'
import { Button } from '../../components/Button'
import { Checkbox } from '../../components/Form/Checkbox'
import * as Input from '../../components/Form/Input/index'
import { Toast } from '../../components/Toast'

const signInForm = z.object({
  email: z.string().min(1, 'Email é obrigatório').email('Email inválido'),
  password: z
    .string()
    .min(1, 'Senha é obrigatória')
    .min(8, 'A senha deve ter pelo menos 8 caracteres'),
  keepLoggedIn: z.boolean(),
})

type SignInForm = z.infer<typeof signInForm>

export function SignIn() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInForm>({
    resolver: zodResolver(signInForm),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const { mutateAsync: authenticate } = useMutation({
    mutationFn: signIn,
  })

  const { isSuccess } = useQuery({
    queryKey: ['login'],
    queryFn: getRoles,
    gcTime: 1,
    retry: false,
  })

  const navigate = useNavigate()

  async function handleLoginSubmit(data: SignInForm) {
    try {
      await authenticate({
        email: data.email,
        password: data.password,
        keepLoggedIn: data.keepLoggedIn,
      })

      navigate('/tickets/my')
      toast.dismiss()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.message === 'Network Error') {
        toast.custom((t) => (
          <Toast title="Servidor offline" t={t} variant="error" />
        ))
      }
      switch (err.response.data.message) {
        case 'Invalid Email or password':
          toast.custom((t) => (
            <Toast title="Email ou senha incorretos" t={t} variant="error" />
          ))
          break
        case 'User not registered with this email':
          toast.custom((t) => (
            <Toast
              title="Usuário não encontrado com este e-mail"
              t={t}
              variant="error"
            />
          ))
      }
    }
  }

  useEffect(() => {
    if (_.isEmpty(errors)) return

    toast.custom((t) => (
      <Toast
        t={t}
        title="Campos inválidos no formulário"
        variant="error"
        errors={errors}
      />
    ))
  }, [errors])

  useEffect(() => {
    if (isSuccess) {
      navigate('/tickets/my')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <div className="flex min-h-screen w-full dark:bg-gray-950">
      <Helmet>
        <title>HUB | Login</title>
      </Helmet>
      <div className="flex flex-1 flex-col justify-center px-4 md:w-2/5 md:flex-none">
        <div className="absolute inset-8 hidden h-fit w-fit md:inline-flex">
          <Raimax />
        </div>
        <div className="md:mx-auto md:w-full md:max-w-md">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
            Log In
          </h1>
          <p className="mt-2 text-gray-600 dark:text-gray-150">
            Seja bem-vindo de volta! Por favor preencha suas informações.
          </p>
          <form
            className="mt-8 flex w-full flex-col gap-5"
            onSubmit={handleSubmit(handleLoginSubmit)}
          >
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Email
              </label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      placeholder="email@raimax.com.br"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Senha
              </label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      type="password"
                      placeholder="••••••••"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-1 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Controller
                  name="keepLoggedIn"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id={field.name}
                    />
                  )}
                />
                <label
                  htmlFor="keepLoggedIn"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Lembrar por 7 dias
                </label>
              </div>
              <a
                href="/forgot-password"
                className="rounded-lg text-sm font-semibold text-purple-700 transition-colors hover:text-purple-800 dark:text-gray-125 dark:hover:text-gray-60"
              >
                Esqueceu a senha?
              </a>
            </div>
            <Button
              className="mt-1 flex items-center justify-center gap-1.5"
              variant="primary"
              disabled={isSubmitting}
            >
              Entrar
              {isSubmitting && (
                <LoaderCircle className="h-5 w-5 animate-spin" />
              )}
            </Button>
            <div className="flex gap-1 self-center">
              <span className="text-sm text-gray-600 dark:text-gray-150">
                Sem acesso?
              </span>
              <span className="text-sm font-semibold text-purple-700 dark:text-gray-125">
                Consulte seu gestor
              </span>
            </div>
          </form>
        </div>
        <span className="hidden text-sm text-gray-600 dark:text-gray-150 md:absolute md:bottom-8 md:left-8 md:block">
          © Raimax Internet LTDA
        </span>
      </div>
      <div className="hidden md:flex md:flex-1 md:items-center md:justify-center md:bg-purple-800 dark:md:bg-gray-750">
        <div className="flex flex-col items-center text-center">
          <Mockup />
          <div className="mt-12 flex flex-col gap-2">
            <h1 className="text-xl font-medium text-white dark:text-gray-25">
              Seja bem-vindo ao seu novo Dashboard
            </h1>
            <p className="font-medium text-purple-200 dark:text-gray-150">
              Entre para explorar as novas funcionalidades
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
