import { zodResolver } from '@hookform/resolvers/zod'
import * as Tabs from '@radix-ui/react-tabs'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { SquareArrowOutUpRight } from 'lucide-react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { getRoles } from '../../api/get-roles'
import { getServicesCount } from '../../api/get-services-count'
import * as Select from '../../components/Form/Select'
import { ThemeToggle } from '../../components/ThemeToggle'

const servicesTabSchema = z.object({
  tab: z.enum(['general', 'alerts', 'status']),
})

type ServicesTabSchema = z.infer<typeof servicesTabSchema>

export function ServicesLayout() {
  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const location = useLocation()
  const navigate = useNavigate()

  const page = servicesTabSchema.parse({
    tab: location.pathname.split('/services/')[1],
  }).tab

  const { control, watch } = useForm<ServicesTabSchema>({
    defaultValues: {
      tab: page,
    },
    resolver: zodResolver(servicesTabSchema),
  })

  const { data: result } = useQuery({
    queryKey: ['layout-services-count'],
    queryFn: getServicesCount,
  })

  const tab = watch('tab')

  const tabs = [
    {
      value: 'alerts',
      title: 'Incidentes',
      isSelected: tab === 'alerts',
      layoutId: 'services',
      count: result?.alerts,
    },
    {
      value: 'general',
      title: 'Serviços',
      isSelected: tab === 'general',
      layoutId: 'services',
      count: result?.services,
    },
    {
      value: 'status',
      title: 'Status',
      isSelected: tab === 'status',
      layoutId: 'services',
      count: result?.status,
    },
  ]

  useEffect(() => {
    if (!isNaN(Number(location.pathname.split('/')[2]))) return

    if (tab === page) return

    navigate(`/services/${tab}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  const isMobile = useMediaQuery({ maxWidth: 768 })

  if (roles) {
    const hasPermissions = roles.teams.some((team) =>
      team.permissions.some((permission) => permission.name === 'statusPage'),
    )

    if (!hasPermissions) {
      navigate('/')
      return null
    }
  }

  return (
    <>
      <Helmet>
        <title>HUB | Status</title>
      </Helmet>
      <div className="flex w-full flex-col justify-between px-4 dark:bg-gray-950 md:flex-row md:px-0">
        <div className="flex flex-col gap-1.5">
          <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
            Status Raimax
          </h1>
          <a
            href="https://status.raimax.com.br"
            target="_blank"
            className="inline-flex w-fit items-center gap-1 text-sm font-semibold text-purple-600 hover:text-purple-700 dark:font-normal dark:text-gray-125 dark:hover:text-gray-75"
            rel="noreferrer"
          >
            (https://status.raimax.com.br){' '}
            <SquareArrowOutUpRight className="h-3 w-3 text-current" />
          </a>
        </div>
        <div className="ml-auto">
          <ThemeToggle />
        </div>
      </div>
      <div className="mt-4 px-4 md:mt-6 md:px-0">
        {isMobile ? (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Select.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
              >
                <Select.Trigger>
                  <Select.Value />
                </Select.Trigger>

                <Select.Content>
                  {tabs.map((item) => (
                    <Select.Item key={item.value} value={item.value}>
                      <Select.ItemText>{item.title}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            )}
          />
        ) : (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Tabs.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
                className="mb-8 hidden border-b border-gray-100 dark:border-gray-750 md:block"
              >
                <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
                  {tabs.map(({ value, title, count, isSelected, layoutId }) => (
                    <Tabs.Trigger
                      key={value}
                      value={value}
                      className="group relative flex h-8 items-center px-1 pb-5 text-sm font-medium leading-5 text-gray-300 outline-none transition-colors data-[state=active]:text-purple-700 hover:text-purple-700 focus:shadow-none dark:text-gray-375 dark:data-[state=active]:text-gray-125 dark:hover:text-gray-125"
                    >
                      <span className="whitespace-nowrap rounded text-sm font-semibold">
                        {title}
                      </span>
                      {!!count && (
                        <div className="ml-2 rounded-full border border-gray-100 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 transition-colors group-hover:bg-purple-50 group-hover:text-purple-700 group-data-[state=active]:bg-purple-50 group-data-[state=active]:text-purple-700 dark:border-gray-725 dark:bg-gray-850 dark:text-gray-375 dark:group-hover:border-purple-800 dark:group-hover:bg-purple-900 dark:group-hover:text-purple-300 dark:group-data-[state=active]:border-purple-800 dark:group-data-[state=active]:bg-purple-900 dark:group-data-[state=active]:text-purple-300">
                          {count}
                        </div>
                      )}

                      {isSelected && (
                        <motion.div
                          layoutId={layoutId}
                          className="absolute -bottom-px left-0 right-0 h-0.5 bg-purple-700 dark:bg-gray-150"
                        />
                      )}
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>
              </Tabs.Root>
            )}
          />
        )}
      </div>
      <Outlet />
    </>
  )
}
