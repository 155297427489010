import { api } from '../lib/axios'

export interface GetTicketParticipantsQuery {
  id: string
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetTicketParticipantsResponse {
  users: {
    id: string
    name: string
    team: {
      id: string
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }[]
    viewed: {
      updated_at: string
    }[]
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getTicketParticipants({
  id,
  pageIndex,
  perPage,
}: GetTicketParticipantsQuery) {
  const response = await api.get<GetTicketParticipantsResponse>(
    `/tickets/${id}/participants`,
    {
      params: {
        pageIndex,
        perPage,
      },
    },
  )

  return response.data
}
