import { zodResolver } from '@hookform/resolvers/zod'
import * as Accordion from '@radix-ui/react-accordion'
import * as Dialog from '@radix-ui/react-dialog'
import { useQuery } from '@tanstack/react-query'
import {
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Clock,
  ListFilter,
  Search,
  Trash,
} from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getCategories } from '../../../api/get-categories'
import { getEnabledTeamsTicketForm } from '../../../api/get-enabled-teams-ticket-form'
import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { cities } from './create-ticket-modal'

const ticketFiltersSchema = z.object({
  ticketId: z.string().optional(),
  status: z.string(),
  priority: z.string(),
  category: z.string(),
  title: z.string().optional(),
  location: z.string(),
  responsable: z.string().optional(),
  team: z.string(),
})

type TicketFiltersSchema = z.infer<typeof ticketFiltersSchema>

export function TicketsTableFilters() {
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: result } = useQuery({
    queryKey: ['categories'],
    queryFn: () =>
      getCategories({ type: 'tickets', pageIndex: 0, perPage: 9999 }),
  })

  const { data: teamsQuery } = useQuery({
    queryKey: ['teams-ticket-form'],
    queryFn: getEnabledTeamsTicketForm,
  })

  const { control, handleSubmit, reset } = useForm<TicketFiltersSchema>({
    resolver: zodResolver(ticketFiltersSchema),
    defaultValues: {
      ticketId: '',
      status: searchParams.get('status') ?? 'all',
      priority: searchParams.get('priority') ?? 'all',
      category: searchParams.get('category') ?? 'all',
      title: searchParams.get('title') ?? '',
      location: searchParams.get('location') ?? 'all',
      responsable: searchParams.get('responsable') ?? '',
      team: searchParams.get('teamId') ?? 'all',
    },
  })

  function handleFilter({
    ticketId,
    status,
    priority,
    category,
    title,
    location,
    responsable,
    team,
  }: TicketFiltersSchema) {
    setSearchParams((state) => {
      if (status) {
        state.set('status', status)
      } else {
        state.delete('status')
      }

      if (ticketId) {
        state.set('ticketId', ticketId)
      } else {
        state.delete('ticketId')
      }

      if (category) {
        state.set('category', category)
      } else {
        state.delete('category')
      }

      if (priority) {
        state.set('priority', priority)
      } else {
        state.delete('priority')
      }

      if (title) {
        state.set('title', title)
      } else {
        state.delete('title')
      }

      if (location) {
        state.set('location', location)
      } else {
        state.delete('location')
      }

      if (responsable) {
        state.set('responsable', responsable)
      } else {
        state.delete('responsable')
      }

      if (team) {
        state.set('teamId', team)
      } else {
        state.delete('teamId')
      }

      state.set('page', '1')

      return state
    })

    setIsModalOpen(false)
  }

  function handleClearFilters() {
    setSearchParams((state) => {
      state.delete('status')
      state.delete('ticketId')
      state.delete('priority')
      state.delete('category')
      state.delete('title')
      state.delete('location')
      state.delete('responsable')
      state.delete('teamId')
      state.set('page', '1')
      state.set('amount', '10')

      return state
    })

    reset({
      status: 'all',
      priority: 'all',
      category: 'all',
      ticketId: '',
      title: '',
      location: 'all',
      responsable: '',
      team: 'all',
    })

    setIsModalOpen(false)
  }

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const [isModalOpen, setIsModalOpen] = useState<boolean>()

  if (isMobile) {
    return (
      <Dialog.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
        <Dialog.Trigger asChild>
          <div className="mt-4 px-4">
            <Button
              variant="outline"
              className="flex w-full items-center justify-center gap-1.5"
            >
              <ListFilter className="h-5 w-5" /> Filtros
            </Button>
          </div>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
          <Dialog.Content
            tabIndex={-1}
            className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 py-8 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
          >
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(handleFilter)}
            >
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="dark:text-gray-125">ID do chamado</label>
                <Controller
                  name="ticketId"
                  control={control}
                  render={({ field }) => (
                    <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                      <Input.Prefix>
                        <Search className="h-5 w-5 text-gray-500" />
                      </Input.Prefix>
                      <Input.Control {...field} placeholder="Buscar" />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="dark:text-gray-125">Status</label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione o Status" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        <Select.Item value="wait">
                          <Select.ItemText>
                            <Clock className="ml-auto h-4 w-4" />
                            Aguardando
                          </Select.ItemText>
                        </Select.Item>
                        <Select.Item value="analysis">
                          <Select.ItemText>
                            <Search className="ml-auto h-4 w-4" />
                            Em análise
                          </Select.ItemText>
                        </Select.Item>
                        <Select.Item value="finished">
                          <Select.ItemText>
                            <CheckCircle className="ml-auto h-4 w-4" />
                            Finalizado
                          </Select.ItemText>
                        </Select.Item>
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="dark:text-gray-125">Categoria</label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione a categoria" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        {result &&
                          result.categories.map((category) => (
                            <Select.Item key={category.id} value={category.id}>
                              <Select.ItemText>{category.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="dark:text-gray-125">Prioridade</label>
                <Controller
                  name="priority"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger
                        id="priority"
                        className="flex-1 dark:bg-gray-950"
                      >
                        <Select.Value placeholder="Selecione a prioridade" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        <Select.Item value="low">
                          <Select.ItemText>Baixa</Select.ItemText>
                        </Select.Item>
                        <Select.Item value="medium">
                          <Select.ItemText>Média</Select.ItemText>
                        </Select.Item>
                        <Select.Item value="high">
                          <Select.ItemText>Alta</Select.ItemText>
                        </Select.Item>
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Título</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                      <Input.Prefix>
                        <Search className="h-5 w-5 text-gray-500" />
                      </Input.Prefix>
                      <Input.Control {...field} placeholder="Buscar" />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Cidade</label>
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione a cidade" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        {cities.map((city) => (
                          <Select.Item key={city} value={city}>
                            <Select.ItemText>{city}</Select.ItemText>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">
                  Responsável
                </label>
                <Controller
                  name="responsable"
                  control={control}
                  render={({ field }) => (
                    <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                      <Input.Prefix>
                        <Search className="h-5 w-5 text-gray-500" />
                      </Input.Prefix>
                      <Input.Control {...field} placeholder="Buscar" />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Equipe</label>
                <Controller
                  name="team"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione a categoria" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        {teamsQuery &&
                          teamsQuery.teams.map((team) => (
                            <Select.Item key={team.id} value={team.id}>
                              <Select.ItemText>{team.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="mt-4 flex gap-1.5">
                <Button
                  type="button"
                  onClick={handleClearFilters}
                  className="flex w-full items-center justify-center gap-1.5 self-end"
                  variant="outline"
                >
                  <Trash className="h-4 w-4" /> Limpar
                </Button>
                <Button
                  type="submit"
                  className="flex w-full items-center justify-center gap-1.5 self-end"
                  variant="outline"
                >
                  <Search className="h-4 w-4" /> Buscar
                </Button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  }

  return (
    <Accordion.Root type="single" collapsible>
      <form
        className="my-8 hidden rounded-xl bg-gray-50 p-5 pb-0 dark:bg-gray-850 md:flex md:flex-col"
        onSubmit={handleSubmit(handleFilter)}
      >
        <Accordion.Item value="1" className="group">
          <div className="flex gap-3">
            <div className="flex flex-1 flex-col gap-1.5">
              <label className="text-sm dark:text-gray-125">
                ID do chamado
              </label>
              <Controller
                name="ticketId"
                control={control}
                render={({ field }) => (
                  <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                    <Input.Prefix>
                      <Search className="h-5 w-5 text-gray-500" />
                    </Input.Prefix>
                    <Input.Control {...field} placeholder="Buscar" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="flex flex-1 flex-col gap-1.5">
              <label className="text-sm dark:text-gray-125">Status</label>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger className="flex-1 dark:bg-gray-950">
                      <Select.Value placeholder="Selecione o Status" />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Item value="all">
                        <Select.ItemText>Todas</Select.ItemText>
                      </Select.Item>
                      <Select.Item value="wait">
                        <Select.ItemText>
                          <Clock className="ml-auto h-4 w-4" />
                          Aguardando
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="analysis">
                        <Select.ItemText>
                          <Search className="ml-auto h-4 w-4" />
                          Em análise
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="finished">
                        <Select.ItemText>
                          <CheckCircle className="ml-auto h-4 w-4" />
                          Finalizado
                        </Select.ItemText>
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex flex-1 flex-col gap-1.5">
              <label className="text-sm dark:text-gray-125">Categoria</label>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger className="flex-1 dark:bg-gray-950">
                      <Select.Value placeholder="Selecione a categoria" />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Item value="all">
                        <Select.ItemText>Todas</Select.ItemText>
                      </Select.Item>
                      {result &&
                        result.categories.map((category) => (
                          <Select.Item key={category.id} value={category.id}>
                            <Select.ItemText>{category.name}</Select.ItemText>
                          </Select.Item>
                        ))}
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex flex-1 flex-col gap-1.5">
              <label className="text-sm dark:text-gray-125">Prioridade</label>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger
                      id="priority"
                      className="flex-1 dark:bg-gray-950"
                    >
                      <Select.Value placeholder="Selecione a prioridade" />
                    </Select.Trigger>
                    <Select.Content>
                      <Select.Item value="all">
                        <Select.ItemText>Todas</Select.ItemText>
                      </Select.Item>
                      <Select.Item value="low">
                        <Select.ItemText>Baixa</Select.ItemText>
                      </Select.Item>
                      <Select.Item value="medium">
                        <Select.ItemText>Média</Select.ItemText>
                      </Select.Item>
                      <Select.Item value="high">
                        <Select.ItemText>Alta</Select.ItemText>
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex gap-1.5 group-data-[state=open]:invisible">
              <Button
                type="button"
                onClick={handleClearFilters}
                className="flex h-12 items-center gap-1.5 self-end"
                variant="outline"
              >
                <Trash className="h-4 w-4" />
              </Button>
              <Button
                type="submit"
                className="flex h-12 items-center gap-1.5 self-end"
                variant="outline"
              >
                <Search className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <Accordion.Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-clip">
            <div className="mt-4 flex gap-3">
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Título</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                      <Input.Prefix>
                        <Search className="h-5 w-5 text-gray-500" />
                      </Input.Prefix>
                      <Input.Control {...field} placeholder="Buscar" />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Cidade</label>
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione a cidade" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        {cities.map((city) => (
                          <Select.Item key={city} value={city}>
                            <Select.ItemText>{city}</Select.ItemText>
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">
                  Responsável
                </label>
                <Controller
                  name="responsable"
                  control={control}
                  render={({ field }) => (
                    <Input.Root className="bg-white px-3.5 py-2.5 dark:bg-gray-950">
                      <Input.Prefix>
                        <Search className="h-5 w-5 text-gray-500" />
                      </Input.Prefix>
                      <Input.Control {...field} placeholder="Buscar" />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-1 flex-col gap-1.5">
                <label className="text-sm dark:text-gray-125">Equipe</label>
                <Controller
                  name="team"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger className="flex-1 dark:bg-gray-950">
                        <Select.Value placeholder="Selecione a categoria" />
                      </Select.Trigger>
                      <Select.Content>
                        <Select.Item value="all">
                          <Select.ItemText>Todas</Select.ItemText>
                        </Select.Item>
                        {teamsQuery &&
                          teamsQuery.teams.map((team) => (
                            <Select.Item key={team.id} value={team.id}>
                              <Select.ItemText>{team.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex gap-1.5">
                <Button
                  type="button"
                  onClick={handleClearFilters}
                  className="flex h-12 items-center gap-1.5 self-end"
                  variant="outline"
                >
                  <Trash className="h-4 w-4" />
                </Button>
                <Button
                  type="submit"
                  className="flex h-12 items-center gap-1.5 self-end"
                  variant="outline"
                >
                  <Search className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </Accordion.Content>

          <div className="group/footer py-2">
            <Accordion.Trigger className="hidden w-full justify-center group-hover/footer:flex group-data-[state=open]:flex focus:shadow-none">
              <ChevronDown className="h-5 w-5 text-gray-150 group-data-[state=open]:hidden" />
              <ChevronUp className="h-5 w-5 text-gray-150 group-data-[state=closed]:hidden" />
            </Accordion.Trigger>
          </div>
        </Accordion.Item>
      </form>
    </Accordion.Root>
  )
}
