import { RadpopRadio } from 'ixcsoft'

import { api } from '../lib/axios'

export interface GetTransmissorsResponse {
  transmissors: RadpopRadio[]
}

export async function getTransmissors() {
  const response = await api.get<GetTransmissorsResponse>('/transmissors')

  return response.data
}
