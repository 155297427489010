/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import { AlertCircle, CheckCircle, X } from 'lucide-react'
import { useMemo } from 'react'
import { FieldErrors } from 'react-hook-form'
import { toast } from 'sonner'

interface ToastProps {
  t: string | number
  title: string
  errors?: FieldErrors<any>
  variant?: 'error' | 'success'
}

export function Toast({ t, title, variant, errors }: ToastProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const error = useMemo(() => errors, [])

  switch (variant) {
    case 'error':
      return (
        <div className="rounded-xl border border-gray-100 bg-white dark:border-gray-750 dark:bg-gray-950">
          <div
            className={clsx('flex gap-2 p-4', {
              'items-center': !errors,
              'items-start': errors,
            })}
          >
            <div className="h-fit w-fit rounded-full border-2 border-red-500 border-opacity-10 p-0.5">
              <div className="h-fit w-fit rounded-full border-2 border-red-500 border-opacity-30 p-0.5">
                <AlertCircle className="h-5 w-5 text-red-500" />
              </div>
            </div>
            <div className="flex flex-col justify-center px-1">
              <div className="text-sm font-semibold text-gray-700 dark:text-gray-125">
                {title}
              </div>
              {error &&
                Object.entries(error).map(([key, value]) => {
                  if (!value) return null

                  return (
                    <span
                      key={key}
                      className="text-sm font-semibold text-gray-700 first:mt-1 dark:text-gray-300 md:font-normal md:text-gray-600"
                    >
                      {String(value.message)}{' '}
                    </span>
                  )
                })}
            </div>
            <button
              onClick={() => toast.dismiss(t)}
              className="group ml-auto cursor-pointer rounded-lg p-2 focus:shadow-none"
            >
              <X className="h-5 w-5 text-gray-400 transition-colors group-hover:text-gray-600 dark:text-gray-150 dark:group-hover:text-gray-25" />
            </button>
          </div>
        </div>
      )
    case 'success':
      return (
        <div className="relative z-10 w-full max-w-xs rounded-xl border border-gray-100 bg-white dark:border-gray-750 dark:bg-gray-950 md:max-w-lg">
          <div className="flex flex-col gap-2 p-4 md:flex-row">
            <div className="h-fit w-fit rounded-full border-2 border-green-500 border-opacity-10 p-0.5">
              <div className="h-fit w-fit rounded-full border-2 border-green-500 border-opacity-30 p-0.5">
                <CheckCircle className="h-5 w-5 text-green-500" />
              </div>
            </div>
            <div className="flex flex-col justify-center px-1">
              <div className="text-sm font-semibold text-gray-700 dark:text-gray-125">
                {title}
              </div>
            </div>
            <button
              onClick={() => toast.dismiss(t)}
              className="group ml-auto cursor-pointer rounded-lg p-2 focus:shadow-none"
            >
              <X className="h-5 w-5 text-gray-400 transition-colors group-hover:text-gray-600 dark:text-gray-150 dark:group-hover:text-gray-25" />
            </button>
          </div>
        </div>
      )
  }
}
