import { api } from '../lib/axios'

export interface RemoveMemberOfTicketQuery {
  id: string
  member: {
    id: string
  }
}

export async function removeMemberOfTicket({
  id,
  member,
}: RemoveMemberOfTicketQuery) {
  const response = await api.delete(`/tickets/${id}/remove`, {
    data: {
      member,
    },
  })

  return response.data
}
