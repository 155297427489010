export function Raimax() {
  return (
    <svg
      width="129"
      height="37"
      viewBox="0 0 129 37"
      className="fill-gray-900 dark:fill-gray-25"
    >
      <path d="M8.92795 29.7202C8.71033 29.7202 8.50515 29.6518 8.30619 29.515L8.29375 29.5026L8.28132 29.4901C7.88339 29.1482 7.90204 28.6259 7.97666 28.3585L11.4212 14.2197L9.67406 14.288H9.66163C9.5808 14.288 9.3694 14.2694 9.21396 14.0891C9.12691 13.9896 9.03365 13.8155 9.07096 13.5357C9.1207 13.1689 10.271 3.84245 10.3269 3.43208C10.3456 3.1212 10.6067 2.54296 11.4958 2.4497C12.329 2.36265 28.93 0.366797 29.0979 0.348145H29.1166C29.1166 0.348145 29.1352 0.348145 29.1725 0.348145C29.328 0.348145 29.8627 0.38545 30.1114 0.845554C30.3352 1.26835 30.1673 1.6725 30.0243 1.84659L22.3829 12.2735L24.0119 12.2611C24.2668 12.2611 24.615 12.3792 24.7767 12.715C24.9383 13.0507 24.814 13.3865 24.6274 13.5606L9.71137 29.3844C9.71137 29.3844 9.39427 29.7202 8.94039 29.7202H8.92795Z" />
      <path d="M29.1666 0.690155C29.2785 0.690155 29.6454 0.715025 29.8008 1.00725C29.9811 1.35544 29.7448 1.64145 29.7448 1.64145L21.693 12.628L24.006 12.6093C24.0433 12.6093 24.3355 12.6155 24.4536 12.8705C24.578 13.1378 24.3728 13.3181 24.3728 13.3181L9.45054 29.1482C9.45054 29.1482 9.23914 29.3782 8.92826 29.3782C8.8039 29.3782 8.6609 29.3409 8.50546 29.2352C8.16971 28.943 8.31271 28.4518 8.31271 28.4518L11.8692 13.8653L9.67437 13.9523C9.67437 13.9523 9.67437 13.9523 9.66815 13.9523C9.62463 13.9523 9.37592 13.9399 9.41945 13.5855C9.46919 13.2062 10.6754 3.46321 10.6754 3.46321C10.6754 3.46321 10.6878 2.88497 11.5334 2.79793C12.379 2.70466 29.1355 0.690155 29.1355 0.690155C29.1355 0.690155 29.1479 0.690155 29.1666 0.690155ZM29.1666 0C29.1293 0 29.1044 0 29.092 0H29.0733H29.0547C28.8868 0.0186529 12.292 2.01451 11.4588 2.10155C10.3583 2.21969 10.0163 2.97202 9.98525 3.3886C9.92307 3.89845 8.77903 13.1192 8.73551 13.486C8.67955 13.8964 8.82877 14.1637 8.95934 14.313C9.13344 14.5119 9.39458 14.6301 9.66815 14.6301C9.6868 14.6301 9.69924 14.6301 9.71168 14.6301L10.9863 14.5803L7.65364 28.2715C7.56038 28.6197 7.54173 29.3036 8.06401 29.7513L8.08888 29.7762L8.11997 29.7948C8.37489 29.9689 8.64846 30.0622 8.93447 30.0622C9.50649 30.0622 9.87333 29.7016 9.96038 29.6145L24.864 13.8031C25.1376 13.542 25.3117 13.057 25.0816 12.5658C24.8516 12.087 24.3604 11.913 24.0122 11.913H23.9998L23.0609 11.9192L30.292 2.05181C30.4847 1.80311 30.7086 1.25596 30.4101 0.683937C30.1863 0.254921 29.72 0 29.1666 0Z" />
      <path d="M19.032 22.023C18.9325 21.8738 18.833 21.7246 18.7273 21.5816L18.3418 21.9608C18.4226 22.079 18.5035 22.1971 18.5843 22.3152C19.9397 24.4044 20.3501 26.8106 19.7408 29.0862C19.1315 31.3619 17.5833 33.2458 15.3698 34.3836C14.8289 34.6634 14.2631 34.8873 13.6848 35.0613C12.0682 35.5401 10.3211 35.6085 8.59881 35.2479C8.39984 35.2044 8.20088 35.1608 8.00191 35.1049C7.85269 35.0613 7.69725 35.024 7.55424 34.9743C6.13663 34.5329 4.8558 33.8178 3.78015 32.8914C3.07756 32.2883 2.46823 31.5857 1.9646 30.8085C0.609163 28.7194 0.1988 26.3132 0.808127 24.0375C1.80916 20.2759 5.37186 17.795 9.41953 17.6582L9.5501 17.1173C5.2102 17.2106 1.36771 19.8593 0.292064 23.9007C-0.354568 26.3256 0.080665 28.881 1.51694 31.1007C2.94699 33.308 5.20398 34.9183 7.86513 35.6271C8.83507 35.8883 9.82368 36.0126 10.7936 36.0126C12.4848 36.0126 14.1387 35.6209 15.6247 34.8562C17.975 33.6499 19.6226 31.6479 20.2693 29.223C20.9159 26.7981 20.4807 24.2427 19.0444 22.023H19.032Z" />
      <path d="M18.2733 22.2652C18.2422 22.2154 18.2111 22.1657 18.18 22.1222L17.7883 22.5077C17.7883 22.5077 17.8069 22.5325 17.8131 22.5512C19.0069 24.5035 19.3427 26.773 18.7707 28.9305C18.1924 31.088 16.7748 32.8849 14.7665 33.9916C14.5427 34.116 14.3126 34.2279 14.0826 34.3274C12.2359 35.1419 10.153 35.3035 8.13854 34.7688C5.91885 34.1782 4.04113 32.8103 2.84734 30.9139C2.82247 30.8766 2.7976 30.8393 2.77273 30.7958C1.57895 28.8434 1.2432 26.574 1.81522 24.4165C2.39346 22.259 3.81107 20.4621 5.81936 19.3553C6.09916 19.1999 6.38517 19.0631 6.67118 18.945C7.49812 18.603 8.36237 18.3854 9.25149 18.3046L9.30123 18.1056L9.38828 17.7574C8.05149 17.8569 6.75201 18.2362 5.56444 18.889C3.4318 20.0642 1.9147 21.9792 1.30537 24.2797C0.68983 26.5802 1.05045 28.9989 2.31885 31.0756C2.72921 31.7471 3.2204 32.3626 3.77377 32.9035C4.93025 34.0351 6.37895 34.8559 8.00175 35.2911C8.83491 35.5149 9.67429 35.6206 10.5137 35.6206C11.5955 35.6206 12.665 35.4341 13.6847 35.0735C14.1448 34.9056 14.5924 34.7067 15.0277 34.4704C17.1603 33.2953 18.6774 31.3802 19.2867 29.0797C19.9023 26.7792 19.5416 24.3605 18.2733 22.2838V22.2652Z" />
      <path d="M17.6889 22.6012L17.2847 22.9992C17.8008 24.0997 18.0619 25.2873 18.0619 26.4997C18.0619 27.2334 17.9624 27.9795 17.7697 28.7132C17.2101 30.8272 15.892 32.5992 14.0702 33.7121C12.2547 34.8189 10.1469 35.136 8.1324 34.6013C6.11789 34.0665 4.45157 32.7422 3.42566 30.8769C2.39976 29.0054 2.14483 26.8106 2.70442 24.7028C3.56867 21.4696 6.17385 19.1753 9.1583 18.6717L9.24535 18.3111L9.29509 18.1121C8.37488 18.2427 7.49198 18.5287 6.66504 18.9515C4.55105 20.0396 2.85986 22.0292 2.18836 24.5722C1.61012 26.736 1.84639 28.9743 2.84121 30.9204C2.87851 30.995 2.92204 31.0696 2.95934 31.1443C3.97903 32.9971 5.58939 34.3525 7.53551 34.993C7.68473 35.0427 7.84017 35.0862 7.99561 35.1297C8.18836 35.1795 8.38732 35.2292 8.58628 35.2665C9.07747 35.3598 9.57488 35.4033 10.0723 35.4033C11.4588 35.4033 12.8267 35.0427 14.0827 34.3401C14.1759 34.2904 14.263 34.2344 14.35 34.1847C16.2899 32.9971 17.6889 31.1132 18.2858 28.8686C18.8516 26.7484 18.6402 24.5411 17.6889 22.6261V22.6012Z" />
      <path d="M70.2809 27.0714H73.1348L73.9245 18.0372H73.1659L73.1597 9.15845L70.2809 9.1771V18.031L71.0333 18.0372L70.2809 27.0714Z" />
      <path d="M61.7503 8.99072L54.0591 27.0653H56.8633L58.2871 23.6146L64.8094 23.627L66.1648 27.0716H69.4415L61.7566 8.99072H61.7503ZM59.3006 21.1524L61.626 15.5006L63.8332 21.1524H59.3068H59.3006Z" />
      <path d="M103.558 8.99072L95.8663 27.0653H98.6705L100.094 23.6146L106.617 23.627L107.972 27.0716H111.249L103.564 8.99072H103.558ZM101.108 21.1524L103.433 15.5006L105.64 21.1524H101.114H101.108Z" />
      <path d="M75.9203 27.0717V9.10282L84.8737 21.7619L94.0011 9.0593V27.0717H91.141V17.3536L84.8177 26.2759L78.7058 17.764V27.0717H75.9203Z" />
      <path d="M112.99 27.0716L119.021 17.8758L113.307 9.28922H117.23L120.992 15.1338L124.735 9.28922H128.521L122.876 17.8758L129 27.0716H125.077L120.93 20.5929L116.782 27.0716H112.996H112.99Z" />
      <path d="M47.7917 20.1663C49.4953 19.1528 50.6394 17.2875 50.6394 15.1673V14.8254C50.6394 13.271 50.0363 11.8098 48.9357 10.7093C47.8352 9.60878 46.3741 9.00568 44.8197 9.00568H39V27.0679H41.9036V20.9684H44.8632L48.8176 27.0616H52.1503C52.1503 27.0616 48.1896 20.7756 47.8041 20.1663H47.7917ZM41.9036 11.9093H44.8135C45.6155 11.9093 46.343 12.2326 46.8715 12.7611C47.4 13.2896 47.7233 14.0171 47.7233 14.8192V15.1611C47.7233 16.759 46.4363 18.0585 44.8383 18.071H41.8974V11.9031L41.9036 11.9093Z" />
    </svg>
  )
}
