import { Skeleton } from '../../../../components/Skeleton'

export function ActivitySkeleton() {
  return (
    <>
      <div className="flex items-start gap-3 pt-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="mt-1 flex flex-col gap-2">
          <Skeleton className="h-3 w-48 rounded-md" />
          <Skeleton className="h-3 w-28 rounded-md" />
        </div>
      </div>
      <div className="flex items-start gap-3 pt-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="mt-1 flex flex-col gap-2">
          <Skeleton className="h-3 w-48 rounded-md" />
          <Skeleton className="h-3 w-28 rounded-md" />
        </div>
      </div>
      <div className="flex items-start gap-3 pt-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="mt-1 flex flex-col gap-2">
          <Skeleton className="h-3 w-48 rounded-md" />
          <Skeleton className="h-3 w-28 rounded-md" />
        </div>
      </div>
      <div className="flex items-start gap-3 pt-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="mt-1 flex flex-col gap-2">
          <Skeleton className="h-3 w-48 rounded-md" />
          <Skeleton className="h-3 w-28 rounded-md" />
        </div>
      </div>
      <div className="flex items-start gap-3 pt-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="mt-1 flex flex-col gap-2">
          <Skeleton className="h-3 w-48 rounded-md" />
          <Skeleton className="h-3 w-28 rounded-md" />
        </div>
      </div>
    </>
  )
}
