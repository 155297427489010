import { Skeleton } from '../../../components/Skeleton'
import { TableCell, TableRow } from '../../../components/Table'

interface TeamsMembersTableSkeletonProps {
  perPage: number
}

export function TeamsMembersTableSkeleton({
  perPage,
}: TeamsMembersTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[124px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[180px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <div className="flex justify-end">
              <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
            </div>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
