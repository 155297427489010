import { statusAPI } from '../lib/axios'

export interface GetServiceStatusResponse {
  status: {
    id: string
    name: string
    type: 'pending' | 'finished'
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getServiceStatus() {
  const response =
    await statusAPI.get<GetServiceStatusResponse>('/services/status')

  return response.data
}
