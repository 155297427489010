import { api } from '../lib/axios'

export interface GetIncidentStatusQuery {
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetIncidentStatusResponse {
  status: {
    id: string
    name: string
    type: 'maintenance' | 'onSite' | 'finished' | 'displacement' | 'incident'
    createdById: string
    team: {
      id: string
      name: string
      color: 'yellow' | 'green' | 'purple' | 'blue' | 'red' | 'gray'
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getIncidentStatus({
  pageIndex,
  perPage,
}: GetIncidentStatusQuery) {
  const response = await api.get<GetIncidentStatusResponse>(
    '/incidents/status',
    {
      params: {
        pageIndex,
        perPage,
      },
    },
  )

  return response.data
}
