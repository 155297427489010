import { api } from '../lib/axios'

export interface GetUsersNotOnTeamQuery {
  id?: string | null
}

export interface GetUsersNotOnTeamResponse {
  users: {
    id: string
    name: string
    email: string
  }[]
}

export async function getUsersNotOnTeam({ id }: GetUsersNotOnTeamQuery) {
  const response = await api.get<GetUsersNotOnTeamResponse>(
    `/teams/${id}/users-not-on-team`,
  )

  return response.data
}
