import { useQuery } from '@tanstack/react-query'
import { MapPin } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getGroups } from '../../../api/get-groups'
import { getServices } from '../../../api/get-services'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { CreateGroupModal } from './create-group-modal'
import { CreateServiceModal } from './create-service-modal'
import { GroupsSkeleton } from './groups-skeleton'
import { ServicesTableSkeleton } from './services-table-skeleton'

export function Services() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: result, isLoading } = useQuery({
    queryKey: ['services', pageIndex, perPage],
    queryFn: () =>
      getServices({
        pageIndex,
        perPage,
      }),
  })

  const { data: groupsResult, isLoading: isLoadingGroups } = useQuery({
    queryKey: ['groups'],
    queryFn: getGroups,
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <>
      <div className="mx-4 mb-6 mt-8 border-b border-gray-100 pb-5 dark:border-gray-750 md:mx-0 md:flex">
        <div>
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Serviços
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie os serviços e grupos ativos.
          </p>
        </div>
        <div className="mt-4 flex items-start gap-3 md:ml-auto md:mt-0">
          <CreateGroupModal />
          <CreateServiceModal />
        </div>
      </div>
      <div className="mx-4 gap-8 md:mx-0 md:mb-6 md:grid md:grid-cols-2 md:border-b md:border-gray-100 md:pb-6 dark:md:border-gray-750">
        <div className="mt-3 md:mt-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Grupos
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Os serviços estão separados pelos seguintes grupos:
          </p>
        </div>
        <div className="mt-4 flex flex-col gap-3 md:mt-0">
          {groupsResult &&
            groupsResult.groups.map((group) => (
              <div
                key={group.id}
                className="flex flex-1 items-center gap-3 rounded-lg border border-gray-100 bg-gray-50 p-4 data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-50 focus:shadow-none dark:border-gray-750 dark:bg-gray-850 dark:data-[state=checked]:border-purple-300 dark:data-[state=checked]:bg-purple-900"
              >
                <div className="flex items-center justify-center rounded-full bg-purple-100 p-2 dark:bg-purple-900">
                  <MapPin className="h-4 w-4 text-purple-600 dark:text-purple-300" />
                </div>
                <p className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  {group.name}
                </p>
              </div>
            ))}
          {isLoadingGroups && <GroupsSkeleton />}
        </div>
      </div>
      <div className="mb-6 gap-16 pt-8 md:mx-0 md:grid md:grid-cols-status md:pt-0">
        <div className="px-4 md:px-0">
          <h1 className="pb-1 text-lg font-semibold text-gray-900 dark:text-gray-125">
            Serviços
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Gerencie os serviços disponíveis na tela inicial da página de
            Status.
          </p>
        </div>
        <div>
          <Table className="mt-4 md:mt-0">
            <TableHeader>
              <TableHead>Nome</TableHead>
              <TableHead>Grupo</TableHead>
              <TableHead>Status</TableHead>
              <TableHead />
            </TableHeader>
            <TableBody>
              {result?.services.map((service) => {
                return (
                  <TableRow key={service.id}>
                    <TableCell className="font-medium text-gray-900 dark:font-normal dark:text-white">
                      {service.name}
                    </TableCell>
                    <TableCell>{service.group.name}</TableCell>
                    <TableCell>
                      <Tag variant={service.status.color}>
                        {service.status.name}
                      </Tag>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )
              })}
              {isLoading && <ServicesTableSkeleton perPage={perPage} />}
            </TableBody>
          </Table>
          {result && (
            <Pagination
              onPageChange={handlePaginate}
              pageIndex={result.meta.pageIndex}
              perPage={result.meta.perPage}
              totalCount={result.meta.totalCount}
              totalInPage={result.services.length}
            />
          )}
        </div>
      </div>
    </>
  )
}
