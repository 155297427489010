import { useMutation } from '@tanstack/react-query'
import { AlertCircle, ArrowLeft } from 'lucide-react'
import { useEffect } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { registerError } from '../api/register-error'
import { BackgroundPattern } from '../assets/background-pattern'
import { Button } from '../components/Button'
import { env } from '../env'

export function Error() {
  const navigate = useNavigate()
  const error = useRouteError() as Error
  const errorId = uuidv4()

  const { mutateAsync: registerErrorFn } = useMutation({
    mutationKey: ['register-error'],
    mutationFn: registerError,
  })

  useEffect(() => {
    registerErrorFn({
      id: errorId,
      message: error.stack || String(error),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BackgroundPattern />
      <div className="fixed z-10 flex min-h-screen w-full flex-col items-center justify-center">
        <div className="flex flex-col gap-6 text-center">
          <div className="mx-auto flex h-14 w-14 items-center justify-center rounded-xl border border-gray-100 bg-white dark:border-gray-725 dark:bg-gray-950">
            <AlertCircle className="h-6 w-6 dark:text-gray-125" />
          </div>
          <h1 className="text-6xl font-semibold text-gray-900 dark:text-gray-25">
            Um erro inesperado ocorreu
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-150">
            Tente novamente, caso o erro persista entre em contato com o suporte
            e informe o código abaixo: <br />
            <span className="font-mono text-purple-600 dark:text-purple-500">
              {errorId}
            </span>
          </p>
          {env.VITE_NODE_ENV === 'development' && (
            <div className="overflow-x-auto rounded-lg border border-gray-100 bg-white p-4 text-gray-600 dark:border-gray-725 dark:bg-gray-950 dark:text-gray-150">
              <pre className="max-w-md text-left">{error.stack}</pre>
            </div>
          )}
        </div>
        <div className="mt-12 flex gap-3">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            className="flex items-center gap-1.5"
          >
            <ArrowLeft className="h-5 w-5" /> Voltar
          </Button>
          <Button onClick={() => navigate('/')}>Voltar para Home</Button>
        </div>
      </div>
    </>
  )
}
