import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { ArrowLeft, KeyRound, Mail } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { sendResetLink } from '../../api/send-reset-link'
import { Button } from '../../components/Button'
import * as Input from '../../components/Form/Input'

export const resetPasswordSchema = z.object({
  email: z.string().email(),
})

export type ResetPasswordProps = z.infer<typeof resetPasswordSchema>

export function ForgotPassword() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<ResetPasswordProps>({
    resolver: zodResolver(resetPasswordSchema),
  })

  const email = watch('email')

  const { mutateAsync: sendResetLinkFn, isSuccess } = useMutation({
    mutationKey: ['send-reset-link'],
    mutationFn: sendResetLink,
  })

  async function handleSendResetLink(data: ResetPasswordProps) {
    const { email } = data

    sendResetLinkFn({
      email,
    })
  }

  if (isSuccess) {
    return (
      <div className="min-h-screen w-full dark:bg-gray-950">
        <div className="mx-auto w-full max-w-md p-4 pt-12">
          <div className="mx-auto w-fit rounded-xl border border-gray-100 p-3.5 shadow-xs dark:border-gray-725">
            <Mail className="h-6 w-6 text-gray-700 dark:text-gray-125" />
          </div>
          <div className="mt-6 text-center">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
              Verifique seu email
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-150">
              Enviamos um link de redefinição de senha para{' '}
              <span className="font-medium">{email}</span>
            </p>
          </div>
          <form className="mt-8 flex flex-col gap-8">
            <Button
              type="button"
              className="w-full text-base"
              onClick={() =>
                email.includes('@raimax.com.br')
                  ? window.open('https://webmail.raimax.com.br/', '_blank')
                  : (window.location.href = `mailto:${email}`)
              }
            >
              Abrir aplicativo de email
            </Button>
            <span className="flex items-center gap-1.5 self-center rounded-lg text-sm text-gray-600 dark:text-gray-150">
              Não recebeu o e-mail?{' '}
              <a
                href="/forgot-password"
                className="rounded-lg text-sm font-semibold text-purple-700 hover:text-purple-800 disabled:cursor-not-allowed dark:text-gray-125 hover:dark:text-gray-60"
              >
                Clique para reenviar
              </a>
            </span>
            <a
              href="/sign-in"
              className="flex items-center gap-1.5 self-center rounded-lg text-sm font-semibold text-gray-600 hover:text-gray-700 focus:shadow-none dark:text-gray-150 hover:dark:text-gray-75"
            >
              <ArrowLeft className="h-5 w-5" /> Voltar para o Login
            </a>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen w-full dark:bg-gray-950">
      <div className="mx-auto w-full max-w-md p-4 pt-12">
        <div className="mx-auto w-fit rounded-xl border border-gray-100 p-3.5 shadow-xs dark:border-gray-725">
          <KeyRound className="h-6 w-6 text-gray-700 dark:text-gray-125" />
        </div>
        <div className="mt-6 text-center">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
            Esqueceu a senha?
          </h1>
          <p className="mt-2 text-gray-600 dark:text-gray-150">
            Não se preocupe, enviaremos instruções de redefinição.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(handleSendResetLink)}
          className="mt-8 flex flex-1 flex-col gap-6"
        >
          <div className="flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
              Email
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input.Root>
                  <Input.Control
                    {...field}
                    type="email"
                    placeholder="Preencha seu email"
                  />
                </Input.Root>
              )}
            />
          </div>
          <Button className="w-full text-base" disabled={!isValid}>
            Resetar senha
          </Button>
          <a
            href="/sign-in"
            className="mt-3 flex items-center gap-1.5 self-center rounded-lg text-sm font-semibold text-gray-600 hover:text-gray-700 focus:shadow-none dark:text-gray-150 hover:dark:text-gray-75"
          >
            <ArrowLeft className="h-5 w-5" /> Voltar para o Login
          </a>
        </form>
      </div>
    </div>
  )
}
