import { api } from '../lib/axios'

export interface userFirstAccessBody {
  user: {
    id: string
  }
  password: string
}

export async function userFirstAccess({ user, password }: userFirstAccessBody) {
  await api.patch('/users/first-access', {
    user,
    password,
  })
}
