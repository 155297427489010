import axios from 'axios'

import { env } from '../env'

export const api = axios.create({
  baseURL: env.VITE_API_BASE_URL,
  withCredentials: true,
})

export const statusAPI = axios.create({
  baseURL: env.VITE_STATUS_API_BASE_URL,
})

if (env.VITE_ENABLE_API_DELAY) {
  api.interceptors.request.use(async (config) => {
    await new Promise((resolve) => setTimeout(resolve, 5000))

    return config
  })

  statusAPI.interceptors.request.use(async (config) => {
    await new Promise((resolve) => setTimeout(resolve, 5000))

    return config
  })
}
