import { api } from '../lib/axios'

export interface GetEnabledTeamsTicketFormResponse {
  teams: {
    id: string
    name: string
    permissions: {
      name: string
    }[]
    users: {
      id: string
      name: string
    }[]
  }[]
}

export async function getEnabledTeamsTicketForm() {
  const response = await api.get<GetEnabledTeamsTicketFormResponse>(
    '/teams/enabled-ticket-form',
  )

  return response.data
}
