/* eslint-disable prettier/prettier */
import { Slot } from '@radix-ui/react-slot'
import { HTMLAttributes } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

const skeleton = tv({
  base: [
    'animate-pulse bg-gray-100 dark:bg-gray-750 select-none text-transparent',
  ],
})

export interface SkeletonProps
  extends HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof skeleton> {
  asChild?: boolean
}

export function Skeleton({ asChild, className, ...props }: SkeletonProps) {
  const Component = asChild ? Slot : 'div'

  return <Component {...props} className={skeleton({ className })} />
}
