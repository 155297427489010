import { statusAPI } from '../lib/axios'

export interface GetAlertStatusQuery {
  pageIndex?: number | null
  perPage?: number | null
  type?: string | null
}

export interface GetAlertStatusResponse {
  status: {
    id: string
    name: string
    type: 'pending' | 'finished'
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getAlertStatus({
  pageIndex,
  perPage,
}: GetAlertStatusQuery) {
  const response = await statusAPI.get<GetAlertStatusResponse>(
    '/alerts/status',
    {
      params: {
        pageIndex,
        perPage,
      },
    },
  )

  return response.data
}
