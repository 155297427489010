import { LatLng } from '../../../../components/Map'

interface MapProps {
  position: LatLng
}

export function Map({ position }: MapProps) {
  let map
  async function initMap(): Promise<void> {
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary

    map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 16,
      center: position,
      zoomControl: true,
      mapId: 'ticket_overview',
    })

    // eslint-disable-next-line no-new
    new AdvancedMarkerElement({
      map,
      position,
    })
  }

  initMap()

  return <div id="map" className="mx-4 mt-6 h-56 rounded-lg md:mx-0"></div>
}
