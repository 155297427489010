import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type TextareaProps = ComponentProps<'textarea'>

export function Textarea(props: TextareaProps) {
  return (
    <textarea
      {...props}
      className={twMerge(
        'min-h-[114px] rounded-lg border border-zinc-300 px-3 py-2 text-gray-900 placeholder-gray-300 shadow-xs outline-none focus:border-purple-400 focus:shadow-base disabled:text-gray-300 dark:border-gray-725 dark:bg-gray-950 dark:text-gray-25 dark:placeholder-gray-350 focus:dark:border-purple-400 dark:disabled:text-gray-350',
        'box-border w-full resize-y overflow-y-auto p-2.5',
        props.className,
      )}
    />
  )
}
