import clsx from 'clsx'
import { HTMLAttributes, ReactNode } from 'react'

export interface PrefixProps extends HTMLAttributes<HTMLDivElement> {
  value?: string
  children: ReactNode
}

export function Prefix(props: PrefixProps) {
  const { value } = props

  return (
    <div
      {...props}
      className={clsx('text-gray-300 dark:text-gray-150', {
        'text-gray-900 dark:text-gray-25': value,
      })}
    />
  )
}
