/* eslint-disable @typescript-eslint/no-explicit-any */
import * as SwitchPrimitive from '@radix-ui/react-switch'
import { Controller } from 'react-hook-form'

export function Switch(props: any) {
  return (
    <Controller
      defaultValue={false}
      {...props}
      render={({ field }) => (
        <SwitchPrimitive.Root
          className="h-6 w-11 rounded-full bg-gray-80 p-0.5 data-[state=checked]:bg-purple-600 data-[state-checked]:shadow-base focus:shadow-gray dark:bg-gray-750 dark:data-[state=checked]:bg-purple-600"
          {...field}
          id={props.id}
          value={undefined}
          checked={field.value}
          onCheckedChange={field.onChange}
        >
          <SwitchPrimitive.Thumb className="block h-5 w-5 translate-x-0.5 rounded-full bg-white shadow-sm transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-5" />
        </SwitchPrimitive.Root>
      )}
    />
  )
}
