import * as Tabs from '@radix-ui/react-tabs'
import clsx from 'clsx'
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'

import * as Select from '../components/Form/Select'
import { Button } from './Button'

interface PaginationProps {
  onPageChange: (pageIndex: number) => void
  pageIndex: number
  totalCount: number
  totalInPage: number
  perPage: number
}

export function Pagination({
  onPageChange,
  pageIndex,
  perPage,
  totalCount,
  totalInPage,
}: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const isMobile = useMediaQuery({ maxWidth: 473 })

  const isMobileBp = useMediaQuery({ maxWidth: 768 })

  const array = Array.from(
    { length: Math.ceil(totalCount / perPage) },
    (_, index) => index + 1,
  )

  let endPage
  let initialPage
  let inEnd
  const size = array.length

  if (isMobile) {
    endPage = size <= 4 ? array : [array[size - 2], array[size - 1]]
    initialPage =
      size <= 4
        ? []
        : [
            pageIndex + 3 >= endPage[0] ? endPage[0] - 2 : array[0 + pageIndex],
            pageIndex + 3 >= endPage[0] ? endPage[0] - 1 : array[1 + pageIndex],
          ]
    inEnd = initialPage[1] >= endPage[0] - 1
  } else {
    endPage =
      size <= 6 ? array : [array[size - 3], array[size - 2], array[size - 1]]
    initialPage =
      size <= 6
        ? []
        : [
            pageIndex + 3 >= endPage[0] ? endPage[0] - 3 : array[0 + pageIndex],
            pageIndex + 3 >= endPage[0] ? endPage[0] - 2 : array[1 + pageIndex],
            pageIndex + 3 >= endPage[0] ? endPage[0] - 1 : array[2 + pageIndex],
          ]
    inEnd = initialPage[1] >= endPage[0] - 2
  }

  const threeDots = isMobile ? size > 4 && !inEnd : size > 6 && !inEnd

  function handleNextPage() {
    if (pageIndex === size - 1) return

    onPageChange(pageIndex + 1)
  }

  function handlePreviousPage() {
    if (pageIndex === 0) return

    onPageChange(pageIndex - 1)
  }

  function handleFirstPage() {
    if (pageIndex === 0) return

    onPageChange(0)
  }

  function handleLastPage() {
    if (pageIndex === size - 1) return

    onPageChange(size - 1)
  }

  function handlePageAmount(value: string) {
    setSearchParams((state) => {
      state.set('page', '1')
      state.set('amount', value)

      return state
    })
  }

  const amount = searchParams.get('amount')

  const amountValues = amount
    ? ['10', '25', '50', '100', amount]
        .filter(
          (item, index) =>
            ['10', '25', '50', '100', amount].indexOf(item) === index,
        )
        .sort((a, b) => Number(a) - Number(b))
    : ['10', '25', '50', '100']

  return (
    <div className="px-4 md:px-0">
      <div className="mt-6 flex w-full items-center gap-3 border-t border-gray-100 pt-4 dark:border-gray-750 md:mt-2 md:rounded-b-xl md:border-t-0 md:pb-4 md:pt-3">
        {size > 1 && (
          <div className="flex">
            <Button
              onClick={handleFirstPage}
              className="flex w-10 items-center justify-center rounded-r-none border-r-0 p-2 focus:shadow-none"
              variant="outlineDark"
              disabled={pageIndex === 0}
            >
              <ChevronsLeft className="h-5 w-5" />
            </Button>
            <Button
              onClick={handlePreviousPage}
              className="flex w-10 items-center justify-center rounded-none p-2 focus:shadow-none"
              variant="outlineDark"
              disabled={pageIndex === 0}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Tabs.Root
              value={String(pageIndex)}
              onValueChange={(value) => onPageChange(Number(value))}
            >
              <Tabs.List>
                {initialPage.map((item) => (
                  <Tabs.Trigger
                    value={String(item - 1)}
                    key={item - 1}
                    className={clsx(
                      'w-10 rounded-none border border-r-0 border-gray-200 p-2 text-sm font-semibold first-of-type:border-l-0 focus:shadow-none dark:border-gray-750',
                      {
                        'bg-gray-50 text-gray-800 dark:bg-gray-725 dark:text-gray-75':
                          pageIndex === item - 1,
                      },
                    )}
                    asChild
                  >
                    <Button variant="outlineDark">{item}</Button>
                  </Tabs.Trigger>
                ))}
                {threeDots && (
                  <Button
                    variant="outlineDark"
                    className={clsx(
                      'w-10 cursor-default rounded-none border border-r-0 border-gray-200 p-2 text-sm font-semibold first-of-type:border-l-0 focus:shadow-none dark:border-gray-750',
                    )}
                    disabled
                  >
                    ...
                  </Button>
                )}
                {endPage.map((item) => (
                  <Tabs.Trigger
                    value={String(item - 1)}
                    key={item - 1}
                    className={clsx(
                      'w-10 rounded-none border border-r-0 border-gray-200 p-2 text-sm font-semibold first-of-type:border-l-0 focus:shadow-none dark:border-gray-750',
                      {
                        'bg-gray-50 text-gray-800 dark:bg-gray-725 dark:text-gray-75':
                          pageIndex === item - 1,
                      },
                    )}
                    asChild
                  >
                    <Button variant="outlineDark">{item}</Button>
                  </Tabs.Trigger>
                ))}
              </Tabs.List>
            </Tabs.Root>

            <Button
              onClick={handleNextPage}
              className="flex w-10 items-center justify-center rounded-none p-2 focus:shadow-none"
              variant="outlineDark"
              disabled={pageIndex === size - 1}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              onClick={handleLastPage}
              className="flex w-10 items-center justify-center rounded-l-none border-l-0 p-2 focus:shadow-none"
              variant="outlineDark"
              disabled={pageIndex === size - 1}
            >
              <ChevronsRight className="h-5 w-5" />
            </Button>
          </div>
        )}

        {!isMobileBp && (
          <>
            <span className="ml-auto hidden text-sm text-gray-700 dark:text-gray-125 sm:block">
              {totalInPage} de {totalCount} resultados
            </span>
            <Select.Root
              onValueChange={(value) => handlePageAmount(value)}
              defaultValue={amount || '10'}
            >
              <Select.Trigger className="w-20 p-2 text-sm">
                <Select.Value />
              </Select.Trigger>

              <Select.Content>
                {amountValues.map((value) => (
                  <Select.Item key={value} value={value}>
                    <Select.ItemText className="text-sm">
                      {value}
                    </Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </>
        )}
      </div>
    </div>
  )
}
