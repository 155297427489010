import * as Dialog from '@radix-ui/react-dialog'
import { useQuery } from '@tanstack/react-query'
import { ReactNode, useState } from 'react'

import { downloadFile } from '../../../../api/download-file'

interface ViewFileModalProps {
  file: {
    id: string
    filename: string
  }
  children: ReactNode
}

export function ViewFileModal({ file, children }: ViewFileModalProps) {
  const [open, setOpen] = useState(false)

  const { data } = useQuery({
    queryKey: ['get-file-data'],
    queryFn: () =>
      downloadFile({
        file,
      }),
    enabled: open,
  })

  const blob = new Blob([data], { type: 'image/jpeg' })

  const dataUrl = URL.createObjectURL(blob)

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-725" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 data-[state=open]:animate-contentShow focus:shadow-none">
          <Dialog.Title className="sr-only">{file.filename}</Dialog.Title>
          <img src={dataUrl} className="mx-auto" alt="" />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
