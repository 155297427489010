import { api } from '../lib/axios'

export interface GetTicketsLayoutResponse {
  tickets: number
  status: number
  categories: number
}

export async function getTicketsLayout() {
  const response = await api.get<GetTicketsLayoutResponse>('/layout/tickets')

  return response.data
}
