import { api } from '../lib/axios'

export interface GetTicketsQuery {
  pageIndex?: number | null
  perPage?: number | null
  ticketId?: string | null
  status?: string | null
  category?: string | null
  priority?: string | null
  title?: string | null
  location?: string | null
  responsable?: string | null
  teamId?: string | null
  sort?: string | null
  key?: string | null
}

export interface GetTicketsResponse {
  tickets: {
    id: number
    title: string
    location: string
    priority: 'high' | 'medium' | 'low'
    created_at: string
    updated_at: string
    viewedBy: {
      userId: string
      updated_at: string
    }[]
    responsable: {
      name: string
    }
    team: {
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }
    category: {
      name: string
    }
    status: {
      name: string
      type: string
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getTickets({
  pageIndex,
  perPage,
  ticketId,
  status,
  category,
  priority,
  title,
  location,
  responsable,
  teamId,
  sort,
  key,
}: GetTicketsQuery) {
  const response = await api.get<GetTicketsResponse>('/tickets', {
    params: {
      pageIndex,
      perPage,
      ticketId,
      status,
      category,
      priority,
      title,
      location,
      responsable,
      teamId,
      sort,
      key,
    },
  })

  return response.data
}
