import { api } from '../lib/axios'

export interface GetIncidentsQuery {
  pageIndex?: number | null
  perPage?: number | null
  from?: Date | null
  to?: Date | null
  title?: string | null
}

export interface GetIncidentsResponse {
  incidents: {
    id: number
    title: string
    level: number
    city: string
    term: string
    createdBy: {
      name: string
    }
    created_at: string
    category: {
      id: string
      name: string
    }
    status: {
      id: string
      name: string
      type: string
    }
    model: {
      id: string
    } | null
    script: string
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getIncidents({
  pageIndex,
  perPage,
  from,
  to,
  title,
}: GetIncidentsQuery) {
  const response = await api.get<GetIncidentsResponse>('/incidents', {
    params: {
      pageIndex,
      perPage,
      from,
      to,
      title,
    },
  })

  return response.data
}
