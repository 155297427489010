import { api } from '../lib/axios'

export interface ExcludeDeviceBody {
  deviceId: string
}

export async function excludeDevice({ deviceId }: ExcludeDeviceBody) {
  await api.post('/devices/exclude', {
    deviceId,
  })
}
