import { api } from '../lib/axios'

export interface ProvideDeviceBody {
  deviceId: string
}

export async function provideDevice({ deviceId }: ProvideDeviceBody) {
  const response = await api.post('/devices/provide', {
    deviceId,
  })

  return response.data
}
