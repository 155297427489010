import { Skeleton } from '../../components/Skeleton'
import { TableCell, TableRow } from '../../components/Table'

interface IncidentsTableSkeletonProps {
  perPage: number
}

export function IncidentsTableSkeleton({
  perPage,
}: IncidentsTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[204px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <div className="my-1 flex items-center gap-2">
              <Skeleton className="h-2 w-2 rounded-full" />
              <Skeleton className="h-2 w-2 rounded-full" />
              <Skeleton className="h-2 w-2 rounded-full" />
              <Skeleton className="h-2 w-2 rounded-full" />
              <Skeleton className="h-2 w-2 rounded-full" />
            </div>
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[96px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[104px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[120px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[96px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[104px] rounded-md" />
          </TableCell>
          <TableCell className="flex justify-end gap-3 group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
            <Skeleton className="my-1 h-4 w-[44px] rounded-md" />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
