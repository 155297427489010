import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Clock, Tags, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getTeams } from '../../../api/get-teams'
import { getTicketStatus } from '../../../api/get-ticket-status'
import { updateCategory } from '../../../api/update-category'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import { Textarea } from '../../../components/Form/Textarea'
import { Switch } from '../../../components/Switch'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

export const editCategorySchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  sla: z.string().min(1, 'SLA é obrigatório'),
  team: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  status: z.object({
    id: z.string().uuid(),
    name: z.string(),
  }),
  description: z.string().optional(),
  fillLocation: z.boolean(),
})

export type EditCategoryProps = z.infer<typeof editCategorySchema>

interface EditCategoryModalProps {
  category: {
    id: string
    name: string
    description?: string
    permissions: {
      name: string
    }[]
    sla: number
    status: {
      id: string
      name: string
    }
    team: {
      id: string
      name: string
    }
  }
}

export function EditCategoryModal({ category }: EditCategoryModalProps) {
  const [open, setOpen] = useState<boolean>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<EditCategoryProps>({
    resolver: zodResolver(editCategorySchema),
    defaultValues: {
      name: category.name,
      sla: String(category.sla),
      ...(category.team && { team: category.team }),
      status: category.status,
      description: category.description || undefined,
      fillLocation: category.permissions.some(
        (permission) => permission.name === 'fillLocation',
      ),
    },
  })

  const { data: teamsResult } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
  })

  const { data: statusResult } = useQuery({
    queryKey: ['ticket-status'],
    queryFn: () =>
      getTicketStatus({
        pageIndex: 0,
        perPage: 9999,
        type: 'wait',
      }),
  })

  const { mutateAsync: updateCategoryFn } = useMutation({
    mutationKey: ['update-category'],
    mutationFn: updateCategory,
    onSuccess: (_, variables) => {
      queryClient.refetchQueries({ queryKey: ['categories'] })

      reset(variables)
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Categoria atualizada com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar a categoria, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleEditCategory(data: EditCategoryProps) {
    const { name, sla, team, status, description, fillLocation } = data

    updateCategoryFn({
      category: {
        id: category.id,
      },
      name,
      sla,
      team,
      status,
      description,
      fillLocation,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        reset()
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg p-0 font-semibold text-purple-700 dark:text-gray-125"
        >
          Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950 md:max-w-2xl"
        >
          <div className="flex items-start border-b border-gray-100 px-4 py-5 dark:border-gray-750 md:p-6">
            <div className="hidden rounded-md border border-gray-100 p-3 text-lg dark:border-gray-750 md:block">
              <Tags className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <div className="md:ml-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Editar {category.name}
              </Dialog.Title>
              <Dialog.Description className="text-sm text-gray-600 dark:text-gray-375">
                Atualize as informações da categoria
              </Dialog.Description>
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="ml-auto rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <form className="mt-8" onSubmit={handleSubmit(handleEditCategory)}>
            <div className="flex flex-col gap-4 px-6">
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Nome*
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input.Root>
                      <Input.Control {...field} />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  SLA*
                </label>
                <Controller
                  name="sla"
                  control={control}
                  render={({ field }) => (
                    <Input.Root>
                      <Input.Prefix>
                        <Clock className="h-5 w-5 text-gray-300 dark:text-gray-150" />
                      </Input.Prefix>
                      <Input.Control
                        {...field}
                        type="number"
                        placeholder="Horas para expirar"
                      />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Equipe*
                </label>
                <Controller
                  name="team"
                  control={control}
                  render={({ field }) => (
                    <Combobox.Root {...field}>
                      <Combobox.Trigger placeholder="Selecione a equipe" />

                      <Combobox.Content>
                        {teamsResult &&
                          teamsResult.teams.map((team) => (
                            <Combobox.Option key={team.id} value={team}>
                              {team.name}
                            </Combobox.Option>
                          ))}
                      </Combobox.Content>
                    </Combobox.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                  Status*
                </label>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Combobox.Root {...field}>
                      <Combobox.Trigger placeholder="Selecione o status" />

                      <Combobox.Content>
                        {statusResult &&
                          statusResult.status.map((status) => (
                            <Combobox.Option key={status.id} value={status}>
                              {status.name}
                            </Combobox.Option>
                          ))}
                      </Combobox.Content>
                    </Combobox.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="flex-wrap text-sm font-medium text-gray-700 dark:text-gray-375">
                  Mensagem padrão <br className="hidden md:block" /> de abertura
                </label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Textarea {...field} placeholder="e.g. mpda" />
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit md:items-center">
                <label className="flex-wrap text-sm font-medium text-gray-700 dark:text-gray-375">
                  Obrigatório preencher localização*
                </label>
                <Switch
                  id="fillLocation"
                  name="fillLocation"
                  control={control}
                />
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse gap-3 border-t border-gray-100 p-4 dark:border-gray-750 md:flex-row">
              <Dialog.Cancel asChild>
                <Button variant="outline" type="button" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button type="submit" className="flex-1" disabled={!isDirty}>
                Editar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
