import { statusAPI } from '../lib/axios'

export interface GetServicesQuery {
  pageIndex?: number | null
  perPage?: number | null
}

interface GetServicesResponse {
  services: {
    id: string
    name: string
    group: {
      id: string
      name: string
    }
    updated_at: string
    status: {
      name: string
      color: 'green' | 'yellow' | 'red'
    }
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
  isOperational: boolean
  isParcial: boolean
  isUnavailable: boolean
}

export async function getServices({ pageIndex, perPage }: GetServicesQuery) {
  const response = await statusAPI.get<GetServicesResponse>('/services', {
    params: {
      pageIndex,
      perPage,
    },
  })

  return response.data
}
