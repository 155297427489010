import { Skeleton } from '../../../components/Skeleton'

export function TeamsSkeleton() {
  return (
    <>
      <div className="flex items-center gap-3 border-b border-gray-100 py-4 first:border-t dark:border-gray-750 md:first:border-t-0">
        <Skeleton className="h-10 w-10 rounded-full" />
        <Skeleton className="h-3 w-32 rounded-md" />
      </div>
      <div className="flex items-center gap-3 border-b border-gray-100 py-4 first:border-t dark:border-gray-750 md:first:border-t-0">
        <Skeleton className="h-10 w-10 rounded-full" />
        <Skeleton className="h-3 w-32 rounded-md" />
      </div>
      <div className="flex items-center gap-3 border-b border-gray-100 py-4 first:border-t dark:border-gray-750 md:first:border-t-0">
        <Skeleton className="h-10 w-10 rounded-full" />
        <Skeleton className="h-3 w-32 rounded-md" />
      </div>
    </>
  )
}
